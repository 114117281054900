import React from 'react';
import { Route } from 'react-router-dom';
import Main from '../containers/main';
import App from '../containers/app';
import Splash from '../components/general/splash';
import { Component } from 'react';

export default class Routes extends Component {
    render() {
        return (
            <Main>
                <Route path="" component={App}/>
                <Route exact path="/" component={Splash} />
            </Main>
        );
    }
}