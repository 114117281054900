import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { assembly } from './assembly'
import { results } from './results'
import { images } from './images'
import { app } from './app'
import { parts } from './parts'
import { calculators } from './calculators'

const rootReducer = (history) => combineReducers({
	router: connectRouter(history),
	results,
	assembly,
	images,
	app,
	parts,
	calculators
})
export default rootReducer
