import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {fetchBearingResult, competitorSearch} from '../../actions';
import NoResults from '../global/no-result';
import { push } from 'react-router-redux';

class BearingDetail extends React.Component {
    constructor(props) {
        super(props);
        this.handleFilterClick = this.handleFilterClick.bind(this);
    }
    details = false;
    cross = null;

    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber) {
            dispatch(fetchBearingResult(match.params.partNumber));
        }
    }

    componentWillReceiveProps(newProps) {
        const {dispatch, match, results} = newProps;
        const bearingDetail = results.FilterIdDetail;
        if (results && !results.isFetchingBearing && bearingDetail && bearingDetail.length === 0 && !this.details) {
            dispatch(competitorSearch(match.params.partNumber)).then(() => {
                this.details = true;
                const {results} = this.props;
                const sealDetail = results.partNumber;
                const partNum = sealDetail && sealDetail[0] && sealDetail[0].ConMetPartNumber;
                this.cross = partNum;
                dispatch(fetchBearingResult(partNum));
            });
        }
        { bearingDetail && bearingDetail.map((result, index) => {
            if (match.params.partNumber != 10041905) {
                dispatch(
                    push(
                        '/hub/bearing-filter-detail/' + (this.cross ?? match.params.partNumber)
                        +
                        '/' +
                        result.Id
                    )
                );
            }
        })}
    }

    handleFilterClick(partNumber, filterId) {
        const { dispatch } = this.props;
        if(partNumber && filterId){
            dispatch(
                push(
                    '/hub/bearing-filter-detail/' + partNumber
                    +
                    '/' +
                    filterId
                )
            );
        }
    }

    render() {
        const { results, match } = this.props;
        const bearingDetail = results.FilterIdDetail;

        if (results.isFetchingBearing) {
            return <Waiting />;
        }

        if (bearingDetail && bearingDetail.length === 0) {
            if (!this.details) {
                return <Waiting />;
            }else{
                return <NoResults />;
            }
        }

        return (
            <div className="grid-container main-content replacement-drum">
                <h2>Choose the Bearing by Axle Type or Bearing Number:</h2>
                <div className="grid-content">
                    { bearingDetail && bearingDetail.map((result, index) => {
                        return (
                            <div key={index} className="small-12">
                                <div className="conmet-button">
                                    <button className="yes-no-button" onClick={() => this.handleFilterClick(match.params.partNumber, result.Id)}>
                                        <strong>{result.Name}</strong>
                                        <br />
                                        <span>
                                            {'Inner Bearing -'}{' '}
                                            {result.BearingDescriptionInboard}
                                            <br />
                                            {'Outer Bearing -'}{' '}
                                            {result.BearingDescriptionOutboard}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        );
                    }) }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(BearingDetail);
