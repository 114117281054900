import React, { Component } from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch), push: bindActionCreators(push, dispatch) };

}

class Confirmation extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        push: PropTypes.func.isRequired,
        session: PropTypes.object.isRequired,

    };

    constructor(props, context) {
        super(props, context);
    }

    handleClick() {
        const { session: { id }, actions: {expireSession}, push} = this.props;
        push('/replace/choose-calculator');
        expireSession(id);

    }

    render() {
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>Thank You</h1>
                    <p>An email has been sent</p>
                    <a className="general-button"
                       onClick={this.handleClick.bind(this)}>
                        Start Again</a>

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);

