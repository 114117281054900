import React, { Component } from 'react';

export default class extends Component {
	render() {
		return (
			<div>
			<h2 className="text-center">
				About
			</h2>
		</div>
		)
	}
};
