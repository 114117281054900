import React, { Component } from 'react'
import {connect} from 'react-redux'


class Footer extends Component {

	render() {
		return (
			<div style={{textAlign:'center'}}>
                <p className="terms-and-conditions">By using the ConMet App, you acknowledge that you have read and understand ConMet’s <a href={`${window.location.origin}/ConMet-Terms-And-Conditions.pdf`} target='_blank' rel="noopener noreferrer">terms and conditions</a> and
				<a href={`${window.location.origin}/disclaimer`}> wheel end disclaimer</a> </p>
			</div>

		);
	}
}
export default connect()(Footer)
