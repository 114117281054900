import React, { Component } from "react";
import { connect } from "react-redux";
import {  push } from 'react-router-redux'

class Splash extends Component {
  constructor(props) {
    super(props);
    this.state = { history: props.history };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(push("/hub"));
  }

  render() {
    return <div>splash</div>;
  }
}
export default connect()(Splash);
