import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  fetchRotorCastingFilterValues,
  resetDrumFilter,
  fetchRotorCastingFilterCategories,
} from '../../actions';
import Waiting from '../global/waiting';

import RotorResult from '../rotor/rotorResult';

class RotorCastingFilter extends Component {
  constructor(props) {
    super(props);
    this.getFilterValue = this.getFilterValue.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);

    this.state = {
      filters: [],
      urlParams: '',
      isResult: false,
      filterClicked: false,
    };
  }

  componentWillMount() {
    const { dispatch, results } = this.props;
    const { rotorCastingFilters } = results;
    if (!rotorCastingFilters.length) {
      dispatch(fetchRotorCastingFilterCategories());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetDrumFilter());
  }

  componentDidMount() {
    this.getFilterValue();
  }

  componentDidUpdate() {
    this.getFilterValue();
  }

  isNil(value) {
    return value !== null && value !== undefined;
  }

  getValue(item) {
    if (this.isNil(item.Id)) {
      return item.Id;
    }
     else if (this.isNil(item.Inch)) {
      return item.Inch;
    } else if (this.isNil(item.Inches)) {
      return item.Inches;
    }
    if (item !== undefined) {
      return item;
    }
  }



  handleFilterClick(item) {
    const id = this.getValue(item);
    const { dispatch, results } = this.props;
    const { currentFilter } = results;
    const { filters, urlParams, skipFilter } = this.state;
    const index =
      currentFilter === 'aaxna' ? 1 : filters.indexOf(currentFilter);
    this.state.filterClicked = true;
    this.state.urlParams +=
      urlParams === ''
        ? currentFilter + '=' + id
        : '&' + currentFilter + '=' + id;

    let url = this.state.urlParams;


    var parturl = (filters.length === index + 1 ? filters[0] : filters[index + 1]);
    dispatch(
      push(
        '/hub/rotor-casting/filter/' + parturl
        +
        '/' +
        url
      )
    );
  }

  getFilterValue() {
    const { results, match, dispatch } = this.props;
    const { rotorCastingFilters, rotorCastingFilterValue } = results;

    const isResult =
      rotorCastingFilterValue &&
      rotorCastingFilterValue[0] &&
      rotorCastingFilterValue[0].BrakeRotorNumber;

    const { filters } = this.state;

    this.state.urlParams = match.params.filters ? match.params.filters : '';

    if (rotorCastingFilters.length) {
      this.state.filters = rotorCastingFilters.map(
        (item) => item.QueryParameterName
      );
    }

    let filterParams = match.params.filters
      ? '?inclv=2&' + match.params.filters
      : '?inclv=2';
    this.state.url = filterParams;

    if (match.params.currentFilter !== this.state.currentFilter) {      
      dispatch(fetchRotorCastingFilterValues(match.params.currentFilter, filterParams));
      this.state.currentFilter = match.params.currentFilter;
    }
    // else if (
    //   rotorCastingFilters.length &&
    //   rotorCastingFilterValue &&
    //    !isResult
    // ) {
        
    // }

    else if (
      rotorCastingFilters.length &&
      rotorCastingFilterValue &&
      rotorCastingFilterValue.length === 1
      && !isResult
      &&
      results.isFilterValueSingle
    ) {

      let index = filters.indexOf(results.currentFilter);

      const id =
        rotorCastingFilterValue[0].Id !== undefined
          ? rotorCastingFilterValue[0].Id
          : rotorCastingFilterValue[0];

      if (this.state.url === '') {
        this.state.url = filterParams;
      }
      else {
        this.state.url += '&' + filters[index] + '=' + this.getValue(id);

        dispatch(
          fetchRotorCastingFilterValues(filters[0], this.state.url)
        );

      }
    }
  }


  render() {
    const { results } = this.props;
    const { rotorCastingFilterValue, currentFilter } = results;


    if (results.isFetching) {
      return <Waiting />;
    }

    if (currentFilter === 'trtyp' && rotorCastingFilterValue !== undefined) {
      let message = 'Truck Type';
      return (
        <div className="grid-container main-content replacement-drum">
          <h2>{message}</h2>
          <div className="grid-content">
            {rotorCastingFilterValue.map((item) => {
              return (
                <div className="small-12" key={item.Id}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item.Id)}
                    >
                      {item.Name}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

   
    if (currentFilter === 'brnum') {
      return <RotorResult rotorResult={rotorCastingFilterValue} />;
    }
 
    return <div />;
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(RotorCastingFilter);
