import React from 'react';
import { Link } from 'react-router-dom';

class ReplacementRotor extends React.Component {
  render() {
    return (
      <div className="grid-container main-content replacement-drum">
        <h2>Do you know your rotor number?</h2>
        <div className="conmet-button">
          <Link to="/hub/replacement-rotor/search" className="yes-no-button">
            <em>Yes</em>I know the rotor number
          </Link>
        </div>
        <div className="conmet-button">
          <Link to="/hub/replacement-rotor/filter" className="yes-no-button">
            <em>No</em>Proceed without the number
          </Link>
        </div>
      </div>
    );
  }
}

export default ReplacementRotor;
