import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import configureStore,{ history } from './config/store';
import Routes from './config/routes'
import './styles/app.scss';

export const GA_TRACKING_ID = 'UA-117509246-1';
const store = configureStore();

export const historyChangeHandler = function (history) {
    history.listen(function(location) {
        window.gtag('config', GA_TRACKING_ID, {
            'page_path': location.pathname
        });
    });

    let url = new URL(window.location.href);
    if (url.pathname === '/') {
        let version = url.searchParams.get('version');
        if(version){
            localStorage.setItem('version', version);
        }
    }
};

historyChangeHandler(history);

if (Function('/*@cc_on return document.documentMode===10@*/')()){
    document.documentElement.className+=' ie10';
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Routes />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('app'));