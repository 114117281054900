import {
  RESULTS,
  SHOW_PREVIOUS_RESULT,
  SHOW_NEXT_RESULT,
  SHOW_RESULT_AT_IDX,
  REQUEST_ASSEMBLIES,
  RECEIVE_ASSEMBLIES,
  ASSEMBLY_RESULT,
  REQUEST_HUBS,
  RECEIVE_HUBS,
  SEARCH_ASSEMBLY_RESULT,
  INVALIDATE_HUBS,
  INVALIDATE_ASSEMBLIES,
  AFTERMARKET_DETAILS,
  ASSEMBLY_NUMBER_DATA,
  PART_NUMBER_DATA,
  HUB_ASSEMBLY_FILTERS,
  HUB_ASSEMBLY_FILTER_VALUE,
  RECEIVE_HUBS_CROSS_API,
  OPTIONAL_SPINDLE_NUT,
  DETAILS_TPL,
  DRUM_NUMBER_DATA,
  DRUM_RESULT,
  DRUM_DETAILS,
  DRUM_FILTER_VALUES,
  REQUEST_DRUMS,
  RESET_DRUM_FILTER,
  ROTOR_NUMBER_DATA,
  DRUM_FILTER_CATEGORIES,
  SELECTED_ROTOR_NUMBER,
  FETCH_HUB_STUDS,
  NOTIFICATIONS_RESULT,
  INVALIDATE_HUB_STUDS,
  REQUEST_OPTIONAL_SPINDLE_NUT,
  STUD_FILTER_VALUES,
  CASTING_FILTER_VALUES,
  CASTING_FILTER_CATEGORIES,
  ROTOR_CASTING_FILTER_VALUES,
  ROTOR_CASTING_FILTER_CATEGORIES,
  DRUM_CASTING_FILTER_CATEGORIES,
  DRUM_CASTING_FILTER_VALUES,
  RESET_CASTING_FILTER,
  REQUEST_CASTING,
  STUD_SUMMARIES,
  REQUEST_KITS,
  RECEIVE_KITS_RESULT,
  REQUEST_BEARING_KITS,
  RECEIVE_BEARING_KITS_RESULT
} from '../config/constants'
import _ from 'lodash'
import {ROTOR_DETAILS, ROTOR_FILTER_CATEGORIES, ROTOR_FILTER_VALUES, ROTOR_RESULT} from '../config/constants';

const mergeInDetails = (assemblies = []) => {

  return assemblies.map(assembly => {
    var itemDetails = _.find(AFTERMARKET_DETAILS, detail => {
      return assembly.HubAssemblyNumber === detail.id.toString();
    })
    if (!itemDetails) {
      itemDetails = {id: parseInt(assembly.PartNumber, 10)}
    }

    return Object.assign(assembly, DETAILS_TPL, itemDetails);
  })

}

export function results(state = RESULTS, action) {
  switch (action.type) {
    case SHOW_PREVIOUS_RESULT:
      var newIdx = state.selectedIdx - 1;
      if (newIdx < 0) {
        return state;
      }
      var newSelected = state.items[newIdx];
      return Object.assign({}, state, {selectedIdx: newIdx, selected: newSelected})

    case SHOW_NEXT_RESULT:
      var newIdx = state.selectedIdx + 1;
      if (newIdx > (state.total - 1)) {
        return state;
      }
      var newSelected = state.items[newIdx];
      return Object.assign({}, state, {selectedIdx: newIdx, selected: newSelected})

    case SHOW_RESULT_AT_IDX:
      if (action.idx < 0 || action.idx >= state.items.length) {
        return state;
      }
      var newSelected = state.items[action.idx];
      return Object.assign({}, state, {selectedIdx: action.idx, selected: newSelected});

    case REQUEST_ASSEMBLIES:
      return Object.assign({}, state, {isFetching: true})

    case RECEIVE_ASSEMBLIES:

      // let assemblies = mergeInDetails(action.assemblies);
      let assemblies = action.assemblies;
      return Object.assign({}, state, {
        selected: assemblies[0],
        selectedIdx: 0,
        total: assemblies.length,
        items: assemblies,
        type: ASSEMBLY_RESULT,
        receivedAt: action.receivedAt,
        isFetching: false
      })

    case REQUEST_HUBS:
      return Object.assign({}, state, {isFetching: true})
    case REQUEST_OPTIONAL_SPINDLE_NUT:
      return Object.assign({}, state, {isSpindleNutFetching: true});
    case RECEIVE_HUBS:
      let hubs = action.hubs;
      return Object.assign({}, state, {
        selected: hubs[0],
        selectedIdx: 0,
        total: hubs.length,
        items: hubs,
        type: SEARCH_ASSEMBLY_RESULT,
        receivedAt: action.receivedAt,
        isFetching: false
      })
    case ASSEMBLY_NUMBER_DATA:
      let assemblyNumber = action.assemblyNumber;
      return Object.assign({}, state, {
        assemblyNumber: assemblyNumber,
        isFetching: false
      });
      // case HUBASSEMBLY_NUMBER_DATA:
      //   let assemblyNumbers = action.assemblyNumber;
      //   return Object.assign({}, state, {
      //     assemblyNumber: assemblyNumbers,
      //     isFetching: false
      //   });

      case NOTIFICATIONS_RESULT:
          let lsData = JSON.parse(localStorage.getItem('notifications'));
          // let lsData = null;
          let apiData = action.data;
          if(lsData){
              apiData.map(item => {
                  if(lsData.filter(a => a.Id === item.Id).length === 0){
                      lsData.push(item);
                  }
              });
              lsData.map(item => {
                  if(apiData.filter(a => a.Id === item.Id).length === 0){
                      let index = lsData.indexOf(item);
                      if(index > -1){
                          lsData.splice(index, 1);
                      }
                  }
              });
              localStorage.setItem('notifications', JSON.stringify(lsData));
          }else {
              localStorage.setItem('notifications', JSON.stringify(apiData));
              localStorage.setItem('unseenNotificationsCount', apiData.length);
          }

          return Object.assign({}, state, {
              // notifications: action.data,
              isFetching: false
          });
    case PART_NUMBER_DATA:
      let partNumber = action.partNumber;
      return Object.assign({}, state, {
        partNumber: partNumber,
        isFetching: false
      });
    case DRUM_RESULT: {
      if (action.data.Status === 'ZERO_RESULTS') {
        return Object.assign({}, state, {
          isFetching: false,
          isZeroResults: true,
        });
      }

      return Object.assign({}, state, {
        drumResult: action.data.Results,
        isFetching: false,
        isZeroResults: false,
      });
    }
    case DRUM_NUMBER_DATA:
      let drumNumber = action.drumNumber;
      return Object.assign({}, state, {
        drumNumber: drumNumber,
        isFetching: false
      });
    case ROTOR_NUMBER_DATA:
      return Object.assign({}, state, {
        rotorNumber: action.rotorNumber,
        isFetching: false
      });
    case ROTOR_RESULT: {
      if (action.data.Status === 'ZERO_RESULTS') {
        return Object.assign({}, state, {
          rotorResult: [],
          isFetching: false,
          isZeroResults: true,
        });
      }

      return Object.assign({}, state, {
        rotorResult: action.data.Results,
        isFetching: false,
        isZeroResults: false,
      });
    }
    case DRUM_DETAILS:
      return Object.assign({}, state, {
        drumDetail: action.data.Results,
        isFetching: false
      });
    case ROTOR_DETAILS:
      return Object.assign({}, state, {
        rotorDetail: action.data.Results,
        isFetching: false
      });
    case DRUM_FILTER_CATEGORIES:
      return Object.assign({}, state, {
        drumFilters: action.data,
        isFetching: false
      });
    case ROTOR_FILTER_CATEGORIES:
      return Object.assign({}, state, {
        rotorFilters: action.data,
        isFetching: false
      });
    case DRUM_FILTER_VALUES:
      return Object.assign({}, state, {
        drumFilterValue: action.data.Results,
        isFetching: false,
        isFilterValueSingle: true
      });

    case CASTING_FILTER_VALUES:
        return Object.assign({}, state, {
        castingFilterValue: action.data.Results,
        isFetching: false,
        isFilterValueSingle: true
      });

      case ROTOR_CASTING_FILTER_VALUES:
        return Object.assign({}, state, {
        rotorCastingFilterValue: action.data.Results,
        isFetching: false,
        isFilterValueSingle: true
      });

      case ROTOR_CASTING_FILTER_CATEGORIES:
      return Object.assign({}, state, {
        rotorCastingFilters: action.data,
        isFetching: false
      });

      case DRUM_CASTING_FILTER_VALUES:
        return Object.assign({}, state, {
        drumCastingFilterValue: action.data.Results,
        isFetching: false,
        isFilterValueSingle: true
      });

      case DRUM_CASTING_FILTER_CATEGORIES:
      return Object.assign({}, state, {
         drumCastingFilters: action.data,
        isFetching: false
      });


    case ROTOR_FILTER_VALUES:
      return Object.assign({}, state, {
        rotorFilterValue: action.data.Results,
        isFetching: false,
        isFilterValueSingle: true
      });
    case SELECTED_ROTOR_NUMBER:
      return Object.assign({}, state, {
        selectedRotorNumber: action.rotorNumber
      });
    case REQUEST_DRUMS:
      return Object.assign({}, state, {
        isFilterValueSingle: action.isFilterValueSingle,
        isFetching: true,
        lastFilter: state.currentFilter,
        currentFilter: action.currentFilter
      });
    case RESET_DRUM_FILTER:
      return Object.assign({}, state, {
        currentFilter: '',
        drumFilterValue: []
      });
    case HUB_ASSEMBLY_FILTERS:
      return Object.assign({}, state, {
        filters: action.filters,
        isFetching: false
      });
    case FETCH_HUB_STUDS:
      const {result} = action;

      if (result && result.Status && result.Status.toLowerCase() === 'ok' && result.Results && result.Results.length) {
        return Object.assign({}, state, {
          short_studs: result.Results,
          isFetching: false
        });
      }

      return Object.assign({}, state, {
        isFetching: false,
        short_studs: [],
      });
    case INVALIDATE_HUB_STUDS: {
      return Object.assign({}, state, {short_studs: []});
    }
    case HUB_ASSEMBLY_FILTER_VALUE:
      return Object.assign({}, state, {
        filter_value: action.filters,
        isFetching: false
      });
    case RECEIVE_HUBS_CROSS_API:
      return Object.assign({}, state, {
        items: action.filters,
        selected: action.filters.Results[0],
        selectedIdx: 0,
        total: action.filters.Results.length,
        isFetching: false
      });
    case OPTIONAL_SPINDLE_NUT:
      return Object.assign({}, state, {
        spindleNut: action.assemblyNumber.Results,
        isSpindleNutFetching: false,
        isFetching: false,
      });
    case STUD_SUMMARIES: {
      return Object.assign({}, state, {
        studSummaries: action.data.Results
      });
    }
    case STUD_FILTER_VALUES:
      return Object.assign({}, state, {
        studFilterValue: action.data.Results,
        isFetching: false
      });

      case RESET_CASTING_FILTER:
      return Object.assign({}, state, {
        castingFilters: '',
        castingFilterValue: []
      });

      case CASTING_FILTER_CATEGORIES:
      return Object.assign({}, state, {
        castingFilters: action.data,
        isFetching: false
      });

      case REQUEST_CASTING:
      return Object.assign({}, state, {
        isFilterValueSingle: action.isFilterValueSingle,
        isFetching: true,
        currentFilter: action.currentFilter
      });
      case REQUEST_KITS:
        return Object.assign({}, state, {isFetching: true})
      case RECEIVE_KITS_RESULT: {
        let partNumber = action.partNumber;
          return Object.assign({}, state, {
            partNumber: partNumber,
            isFetching: false
          });
      }
    case REQUEST_BEARING_KITS:
      return Object.assign({}, state, {isFetchingBearing: true, FilterIdDetail: []})
    case RECEIVE_BEARING_KITS_RESULT: {
      let FilterIdDetail = action.FilterIdDetail;
      return Object.assign({}, state, {
        FilterIdDetail: FilterIdDetail,
        isFetchingBearing: false
      });
    }



    case INVALIDATE_HUBS:
    case INVALIDATE_ASSEMBLIES:
      return Object.assign({}, state, RESULTS)
    default:
      return state;
  }
}
