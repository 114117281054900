import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';
import { TRUCK } from '../../config/constants';

let FILTERIDX;

class Result extends Component {
  render() {
    var { result, active, onClick } = this.props;
    return (
      <div className="grid-content small-6">
        <a href="#" className={active()} onClick={onClick}>
          {result}
        </a>
      </div>
    );
  }
}

class SmallBackface extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    const { tcomp, tmake } = app.filterState;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'icfsb') !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'icfsb')
        : 10;
    const otherNotListedPath = tcomp === TRUCK && tmake === null;

    if (!otherNotListedPath) {
      dispatch(push('/hub/brake-rotor-type'));
    } else {
      dispatch(fetchFilters(FILTERIDX, app));
    }
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    return (
      <div className="grid-container main-content">
        <h2>Cut for Small Backface?</h2>
        <div className="grid-block">
          {app.filterResults.map((result, index) => {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { icfsb: result },
              app
            );
            var boundActive = setActive.bind(
              this,
              FILTERIDX,
              result,
              'general-button truck-make'
            );
            return (
              <Result
                key={index}
                app={app}
                result={result}
                active={boundActive}
                onClick={boundClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SmallBackface);
