import React, {Component} from 'react';
import {connect} from 'react-redux';

class Meta extends Component {
  render() {
    const {result} = this.props;
    return (
      <div className="meta">
        {
            result ? result.Images.map((image, index) => {
            if (index === 0) {
              return (
                <img
                  key={index}
                  className="product-image"
                  // src={IMAGE_CDN + image.ImageGuid + '.png'}
                  src={ image.ImageUrl + '.png'}
                  alt={result.PartNumber}
                  width="100"
                  height="100"
                />
              )
            }

            return null;
          }) : ''
        }
        {result ? result.Description: ''}<br/>
        <em>{result ? result.PartNumber: ''}</em>
      </div>
    )
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Meta);
