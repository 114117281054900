import React from 'react';
import {connect} from 'react-redux';
import Waiting from '../global/waiting';
import {fetchRotorByCastingResult} from '../../actions';
import NoResults from '../global/no-result';
import RotorDiscontinued from '../global/rotor-discontinued';
import RotorResult from './rotorResult';

class ReplacementRotorCastingResult extends React.Component {
  componentWillMount() {
    const {dispatch, match} = this.props;
    if (match.params.casting) {
      dispatch(fetchRotorByCastingResult(match.params.casting));
    }
  }

  render() {
    const {results} = this.props;
    const {selectedRotorNumber, rotorResult} = results;
    if (results.isFetching) {
      return (<Waiting/>)
    }

    if (rotorResult.length === 0 && results.isZeroResults === true && selectedRotorNumber) {
      return (<RotorDiscontinued selectedRotorNumber={selectedRotorNumber}/>)
    }

    if (rotorResult.length === 0 && results.isZeroResults === true) {
      return (<NoResults/>)
    }

    return (
      <RotorResult rotorResult={rotorResult}/>
    )
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(ReplacementRotorCastingResult);
