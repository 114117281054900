import React, { Component } from 'react';
import Spinner from '../../components/global/spinner';
import { setActiveFilterValue, fetchFilters } from '../../actions/filters';
import { connect } from 'react-redux';
import _ from 'lodash';

class Result extends Component {
  render() {
    var { result } = this.props;
    return (
      //onClick={this.setHub.bind(this, 'truckCompartmentIds', HEAVY_DUTY_TRUCK)}
      <div className="grid-content small-6">
        <div className="conmet-button">
          <button className="yes-no-button" onClick={this.props.onClick}>
            <strong>{result.Name}</strong>
          </button>
        </div>
      </div>
    );
  }
}

class Finder extends Component {
  componentDidMount() {
    const { app, dispatch } = this.props;
    dispatch(fetchFilters(app.currentIndex, app));
  }

  setFilterValue(value) {
    const { app, dispatch } = this.props;
    const lastId = _.clone(app.filterId);
    dispatch(setActiveFilterValue(app.filterId, value));
  }

  render() {
    const { app } = this.props;
    return (
      <div className="grid-container main-content">
        <h2>Are you looking for a hub for your</h2>
        <Spinner isFetching={app.isFetching} />
        <div className="grid-block">
          {app.filterResults.map((result, index) => {
            var boundClick = this.setFilterValue.bind(this, result.Id);
            return <Result key={index} result={result} onClick={boundClick} />;
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Finder);
