import React, { Component } from 'react';
import { TRUCK, TRAILER, DISC, DRUM, FILTERIDX, SCHOOL_BUS } from '../../config/constants';
import { connect } from 'react-redux';
import { resetAppState } from '../../actions/filters';

class TruckType extends Component {
    componentDidMount() {
        const {dispatch } = this.props;
        dispatch(resetAppState());
    }

    render() {
        const { app, setFilter, setActive } = this.props;
        return (
            <div className="grid-container main-content">
                <h2>Are you looking for a hub for your</h2>
                <div className="grid-block">
                    <div className="grid-content small-6">
                        <div className={setActive(FILTERIDX, TRUCK)}>
                            <button className="yes-no-button" onClick={setFilter.bind(this, FILTERIDX, {tcomp: TRUCK, brkty: DRUM}, app)}>
                                <strong>Truck with Drum Brakes</strong>
                            </button>
                        </div>
                        <div className={setActive(FILTERIDX, TRUCK)}>
                            <button className="yes-no-button" onClick={setFilter.bind(this, FILTERIDX, {tcomp: TRUCK, brkty: DISC}, app)}>
                                <strong>Truck with Disc Brakes</strong>
                            </button>
                        </div>
                        <div className={setActive(FILTERIDX, SCHOOL_BUS)}>
                            <button className="yes-no-button" onClick={setFilter.bind(this, FILTERIDX, {tcomp: SCHOOL_BUS, brkty: DRUM}, app)}>
                                <strong>School Bus with Drum Brakes</strong>
                            </button>
                        </div>
                    </div>
                    <div className="grid-content small-6">
                        <div className={setActive(FILTERIDX, TRAILER)}>
                            <button className="yes-no-button" onClick={setFilter.bind(this, FILTERIDX, {tcomp: TRAILER, brkty: DRUM}, app)}>
                                <strong>Trailer with Drum Brakes</strong>
                            </button>
                        </div>
                        <div className={setActive(FILTERIDX, TRAILER)}>
                            <button className="yes-no-button" onClick={setFilter.bind(this, FILTERIDX, {tcomp: TRAILER, brkty: DISC}, app)}>
                                <strong>Trailer with Disc Brakes</strong>
                            </button>
                        </div>
                        <div className={setActive(FILTERIDX, SCHOOL_BUS)}>
                            <button className="yes-no-button" onClick={setFilter.bind(this, FILTERIDX, {tcomp: SCHOOL_BUS, brkty: DISC}, app)}>
                                <strong>School Bus with Disc Brakes</strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(TruckType);
