import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";

class Help extends Component {
  handleClick(path) {
    const { dispatch } = this.props;
    dispatch(push(path));
  }

  handleLink(url) {
    window.location = url;
  }

  render() {
    return (
      <div className="grid-container main-content">
        <h2>Do you know your hub assembly number?</h2>

        <iframe
          width="100%"
          height="300px"
          src="https://www.youtube.com/embed/9yVp9V2JuEs"
          frameborder="0"
          allowfullscreen
        ></iframe>

        <div className="conmet-button">
          <button
            onClick={this.handleClick.bind(this, "/hub/find-assembly-detail")}
            className="yes-no-button"
          >
            <em>Read</em> More about finding your assembly number.
          </button>
        </div>

        <div className="conmet-button">
          <button
            onClick={this.handleLink.bind(
              this,
              "https://conmet.com/contact/"
            )}
            className="yes-no-button"
          >
            <em>Contact</em>Customer service for support.
          </button>
        </div>
      </div>
    );
  }
}

export default connect()(Help);
