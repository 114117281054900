import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {competitorSearch, fetchSpindleNutKitResult} from '../../actions';
import NoResults from '../global/no-result';

class SpindleNutKitDetail extends React.Component {
    details = false;
    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber) {
            dispatch(fetchSpindleNutKitResult(match.params.partNumber));
        }
    }

    componentWillReceiveProps(newProps) {
        const {dispatch, match, results} = newProps;
        const snkDetail = results.partNumber;

        if (results && !results.isFetching && snkDetail && snkDetail.length === 0 && !this.details) {
            dispatch(competitorSearch(match.params.partNumber)).then(() => {
                this.details = true;
                const {results} = this.props;
                const snkDetail = results.partNumber;
                const partNum = snkDetail && snkDetail[0] && snkDetail[0].ConMetPartNumber;
                dispatch(fetchSpindleNutKitResult(partNum));
            });
        }
    }


    render() {
        const { results } = this.props;
        const snkDetail = results.partNumber;

        if (results.isFetching) {
            return <Waiting />;
        }

        if (snkDetail && snkDetail.length === 0 || snkDetail === undefined) {
            if (!this.details) {
                return <Waiting />;
            }else{
                return <NoResults />;
            }
        }

        return (
            <div className="grid-container main-content result">
                <h2>Product Details</h2>
                <div className="meta">
                    <span>
                        <img
                            className="product-image"
                            src={snkDetail && snkDetail[0] && snkDetail[0].ImageURL ? snkDetail && snkDetail[0] && snkDetail[0].ImageURL + '.png' : 'https://cdn.intelligencebank.com/us/share/ONnl/Aa8M/73rBb/original/Image_Not_Available'}
                            alt="SpindleNutKit"
                            width="100"
                            height="100"
                        />
                    </span>
                    {snkDetail && snkDetail[0] && snkDetail[0].ProductType} {snkDetail && snkDetail[0] && snkDetail[0].Axle} {snkDetail && snkDetail[0] && snkDetail[0].AxlePostion}
                    {snkDetail && snkDetail[0] && snkDetail[0].PartNumber === '10036548' ? (
                        ' - D Flat type'
                    ) : snkDetail && snkDetail[0] && snkDetail[0].PartNumber === '10036549' ? (
                        ' - Keyway type'
                    ) : ''}
                    <br/>
                    <em>{snkDetail && snkDetail[0] && snkDetail[0].PartNumber}</em>
                </div>
                <div>
                    <div className="specs mb-3">
                        <h2>Product Specs</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Axle Position</td>
                                <td>{snkDetail && snkDetail[0] && snkDetail[0].AxlePostion ? snkDetail && snkDetail[0] && snkDetail[0].AxlePostion : '-'}</td>
                            </tr>
                            <tr>
                                <td>Axle</td>
                                <td>{snkDetail && snkDetail[0] && snkDetail[0].Axle ? snkDetail && snkDetail[0] && snkDetail[0].Axle : '-'}</td>
                            </tr>
                            <tr>
                                <td>Product Type</td>
                                <td>{snkDetail && snkDetail[0] && snkDetail[0].ProductType ? snkDetail && snkDetail[0] && snkDetail[0].ProductType : '-'}</td>
                            </tr>
                            <tr>
                                <td>Six-point socket size (IN.)</td>
                                <td>{snkDetail && snkDetail[0] && snkDetail[0].SixPointSocketSize ? snkDetail && snkDetail[0] && snkDetail[0].SixPointSocketSize : '-'}</td>
                            </tr>
                            <tr>
                                <td>Torque Spec (ft-lbs)</td>
                                <td>{snkDetail && snkDetail[0] && snkDetail[0].TorqueSpec ? snkDetail[0].TorqueSpec + ', DO NOT BACK OFF SPINDLE NUT' : '-'}</td>
                            </tr>
                            {
                                (snkDetail && snkDetail[0] && snkDetail[0].Axle) === 'FF' && (snkDetail && snkDetail[0] && snkDetail[0].AxlePostion) === 'Front' ?
                                    (
                                        <tr>
                                            <td>Note:</td>
                                            <td>{snkDetail && snkDetail[0] && snkDetail[0].Note ? snkDetail && snkDetail[0] && snkDetail[0].Note : '-'}</td>
                                        </tr>
                                    ) : ''
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SpindleNutKitDetail);
