import React, { Component } from 'react';
import classNames from "classnames";

export default class extends Component {
  constructor() {
    super();

    this.state = {
      rebuildKitActive: false,
      dropDownInboardActive: false,
      dropDownOutboardActive: false,
    };

    this.renderRebuildKit = this.renderRebuildKit.bind(this);
    this.renderDropDown = this.renderDropDown.bind(this);
  }

  renderRebuildKit() {
    const { parts: { rebuildKitDetails }, rebuildKitNumber } = this.props;
    const presetRebuild = [];

    if (rebuildKitDetails && rebuildKitDetails.length) {
      presetRebuild.push(
        <tr style={{ border: 'none' }}>
          <td>
          <span
            className={classNames('rebuild-kit-toggle', { show: this.state.rebuildKitActive })}
            onClick={() => this.setState({ rebuildKitActive: !this.state.rebuildKitActive })}
          >
            PreSet / PreSet Plus Hub Rebuild Kit </span>
          </td>
          <td>
            {rebuildKitNumber}
          </td>
        </tr>
      );

      presetRebuild.push(
        <tr style={{ paddingTop: 0 }}>
          <td colSpan={2} style={{ padding: !this.state.rebuildKitActive ? 0 : '' }}>
            {
              rebuildKitDetails.map(item => (
                  <table className={classNames('rebuild-kit-info', { 'show m-0 show1': this.state.rebuildKitActive })} key={item.PreSetPreSetPlusHubRebuildKitNumber}>
                    <tr>
                      <td width="70%">Inner Bearing</td>
                      <td>{item.BearingSetNumberInboard}</td>
                    </tr>
                    <tr>
                      <td width="70%">Outer Bearing</td>
                      <td>{item.BearingSetNumberOutboard}</td>
                    </tr>
                    <tr>
                      <td width="70%">Seal & Spacer</td>
                      <td>{item.SealAndSpacerKitNumber}</td>
                    </tr>
                  </table>
              ))
            }
          </td>
        </tr>
      )
    }

    return presetRebuild;
  }

  renderDropDown(type) {
    const { result } = this.props;
    const dropDown = [];
    const bearingNumberInboard = result.BearingNumberInboard.indexOf('SET :—') !== -1 ? result.BearingNumberInboard.replace('SET :—','—') : result.BearingNumberInboard ;
    const bearingNumberOutboard = result.BearingNumberOutboard.indexOf('SET :—') !== -1 ? result.BearingNumberOutboard.replace('SET :—','—') : result.BearingNumberOutboard ;

    dropDown.push(
        <tr style={{ border: 'none' }}>
          <td>
          <span
              className={classNames('rebuild-kit-toggle', { show: type === 'Inboard' ? this.state.dropDownInboardActive : this.state.dropDownOutboardActive })}
              onClick={() => type === 'Inboard' ? this.setState({ dropDownInboardActive: !this.state.dropDownInboardActive }) : this.setState({ dropDownOutboardActive: !this.state.dropDownOutboardActive })}
          >
            Bearing {result.BearingNumbersType} ({type}) </span>
          </td>
          <td>
            {type === 'Inboard' ? bearingNumberInboard.split(['CUP :'])[0] : bearingNumberOutboard.split(['CUP :'])[0]}
          </td>
        </tr>
    );

    dropDown.push(
        <tr>
          <td colSpan={2} style={{ padding: type === 'Inboard' ? (!this.state.dropDownInboardActive ? 0 : '') : (!this.state.dropDownOutboardActive ? 0 : '') }}>
              <table  className={classNames('rebuild-kit-info', { 'show m-0 show1': type === 'Inboard' ? this.state.dropDownInboardActive : this.state.dropDownOutboardActive })}>
                <tr>
                  <td width="70%">CUP</td>
                  <td>{type === 'Inboard' ? bearingNumberInboard.split(['CUP :'])[1] : bearingNumberOutboard.split(['CUP :'])[1] }</td>
                </tr>
                <tr>
                  <td width="70%">Cone</td>
                  <td>{type === 'Inboard' ? result.BearingConeNumberInboard : result.BearingConeNumberOutboard}</td>
                </tr>
              </table>
          </td>
        </tr>
    )
    return dropDown;
  }

  render() {
    const { result, parts: { AftermarketParts } } = this.props;
    let replacementRotorKit = null;

    if (AftermarketParts && AftermarketParts.length) {
      replacementRotorKit = AftermarketParts.filter(part => part.TypeId === 203);
    }

    return (
      <div className="specs">
        <h2>Product Specs</h2>
        <table>
          <tbody>
          <tr>
            <td>Material</td>
            <td>{result.Material}</td>
          </tr>
          <tr>
            <td>Axle Position</td>
            <td>{result.AxlePosition}</td>
          </tr>
          <tr>
            <td>Axle</td>
            <td>{result.Axle}</td>
          </tr>
          <tr>
            <td>Assembly Type</td>
            <td>{result.HubAssemblyType}</td>
          </tr>
          {result.AxlePosition === 'Trailer' ? (
              <tr>
                <td>Casting</td>
                <td>{result.HubCastingNumber}</td>
              </tr>
          ) : null}
          {this.renderDropDown('Inboard')}
          {this.renderDropDown('Outboard')}
          <tr>
            <td>Compatible Brake Type</td>
            <td>{result.BrakeType}</td>
          </tr>
          <tr>
            <td>Rotor Type</td>
            <td>{result.BrakeRotorType}</td>
          </tr>
          {
            replacementRotorKit && replacementRotorKit.length ?
              <tr><td>Replacement Rotor Kit</td><td>{replacementRotorKit[0]["PartNumber"]}</td></tr> : null
          }
          <tr>
            <td>Hub Mounting System</td>
            <td>{result.HubMountingSystem}</td>
          </tr>
          <tr>
            <td>Wheel Stud Standout (in.)</td>
            <td>{result.WheelStudStandoutInch}</td>
          </tr>
          {
            result.WheelMaterial && result.WheelMaterial !== '—' ?
              <tr><td>Wheel Material</td><td>{result.WheelMaterial}</td></tr> : null
          }
          {
            result.AxleStudThread !== '—' ?
              <tr><td>Axle Stud Thread</td><td>{result.AxleStudThread}</td></tr> : null
          }
          <tr>
            <td width="70%">Flange Offset (in.)</td>
            <td>{result.FlangeOffsetInch}</td>
          </tr>
          <tr>
            <td>Brake Drum Pilot (in.)</td>
            <td>{result.BrakeDrumPilotDiameter}</td>
          </tr>
          <tr>
            <td>Barrel Length</td>
            <td>{result.BarrelLength}</td>
          </tr>
          <tr>
            <td>ABS TONE RING INCLUDED</td>
            <td>{(result.ABSRingIncluded?"YES":"NO")}</td>
          </tr>

          <tr>
            <td>Weight (lbs.)</td>
            <td>{result.WeightPound}</td>
          </tr>
          {
            result.ConventionalHubNumber && result.ConventionalHubNumber !== '—' ?
              <tr><td>Conventional Hub Option</td><td>{result.ConventionalHubNumber}</td></tr> : null
          }
          {
          result.HubAssemblyType !== 'Conventional'?
          this.renderRebuildKit(): null
          }
          </tbody>
        </table>
      </div>
    )
  }
};
