import React, { Component } from 'react';
import { updateFilters } from '../../actions';
import { connect } from 'react-redux';
import {  push } from 'react-router-redux'

class ChoosePath extends Component {
  updateState(obj) {
    const { store } = this.context;
    store.dispatch(updateFilters(obj));
  }

  handleClick(path) {
    const { dispatch } = this.props;
    dispatch(push(path));
  }

  render() {
    return (
      <div className="grid-container main-content">
        <h2>Do you know your hub assembly number?</h2>
        <div className="conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/hub/search')}
            className="yes-no-button"
          >
            <em>Yes</em>I know the hub assembly number.
          </button>
        </div>
        <div className="conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/hub/find-assembly')}
            className="yes-no-button"
          >
            <em>No</em>Help me find the assembly number.
          </button>
        </div>
        <div className="conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/hub/truck-type')}
            className="yes-no-button"
          >
            <em>No</em>Proceed without the number.
          </button>
        </div>
        <div className="conmet-button">
          <button
            onClick={this.handleClick.bind(this, '/hub/compare-hub')}
            className="yes-no-button"
          >
            <em>Yes</em>I want to compare two assemblies.
          </button>
        </div>
      </div>
    );
  }
}

export default connect()(ChoosePath);
