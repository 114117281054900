import React, { Component } from 'react';
export default class NotificationDetail extends Component {
    componentDidMount(){
        let _iOSDevice = !!navigator.platform.match(/iPhone|iPod|iPad|Mac/);
        let _ios = document.getElementsByClassName('osIOS')[0];
        let _android = document.getElementsByClassName('osAndroid')[0];
        if(_ios){_ios.style.display = _iOSDevice ? 'block' : 'none';}
        if(_android){_android.style.display = _iOSDevice ? 'none' : 'block';}
    }
    render() {
        const { params} = this.props.match;
        let notifications = JSON.parse(localStorage.getItem('notifications'));
        let notification = notifications.find(n => n.Id === parseInt(params.id,10));
        return (
            <div className="note-detail">
                <h2>{notification.Title}</h2>
                {notification.IsUpdateButton ?
                    (
                        <div className="text-center">
                            <div className="btn-no-description conmet-button" style={{display:'inline-block'}}>
                                <button onClick={() => window.location.href = notification.UpdateLink ?? ''}>
                                    <h4 style={{color:'#ffffff',padding:0}}>UPDATE NOW</h4>
                                </button>
                            </div>
                        </div>
                    ) : null
                }
                <h4 dangerouslySetInnerHTML={{__html: notification.Message}} />
                <ul>
                    {Array.isArray(notification.Link) ? notification.Link.map((l,i)=>{
                        return(<li key={i}><h4><a href={l}>{l}</a></h4></li>)

                    }) : (<li><h4 style={{paddingTop:0}}><a href={notification.Link}>{notification.Link}</a></h4></li>)}
                </ul>
            </div>
        )
    }
};
