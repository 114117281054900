import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history';
import * as constants from "./config/constants";

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory({ basename: '' });
const ai = new ApplicationInsights({
    config: {
        instrumentationKey: constants.APP_INSIGHT_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
})
ai.loadAppInsights()

export default (Component) => withAITracking(reactPlugin, Component)
export const appInsights = ai.appInsights