import React, { Component } from 'react';
import Specs from '../../components/hub/details/specs';
import Meta from '../../components/hub/details/meta';
import Spinner from '../../components/global/spinner';
import { fetchAssemblyDetails } from '../../actions/assembly';
import { fetchParts, fetchRebuildKitDetails } from '../../actions/parts';
import { connect } from 'react-redux';

class Details extends Component {
  constructor() {
    super();

    this.state = {
      rebuildKitNumber: null,
    };
    this.fetchRebuildKit = this.fetchRebuildKit.bind(this);
  }
  componentDidMount() {
    const { dispatch, match, images } = this.props;
    dispatch(fetchParts(match.params.id));
    dispatch(fetchAssemblyDetails(match.params.id, images));
    this.fetchRebuildKit(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.fetchRebuildKit(newProps);
  }

  fetchRebuildKit(props) {
    const { parts, dispatch } = props;
    const { AftermarketParts } = parts;

    if (AftermarketParts && AftermarketParts.length) {
      const rebuildKit = AftermarketParts.filter((t) => t.TypeId === 219);
      if (rebuildKit && rebuildKit.length) {
        const partNumber = rebuildKit[0].PartNumber;
        this.setState({ rebuildKitNumber: partNumber });
        if (parts.rebuildKitNumber !== partNumber) {
          dispatch(fetchRebuildKitDetails(partNumber));
        }
      }
    }
  }

  render() {
    const { assembly, images, parts, history } = this.props;

    if (parts.isFetching) {
      return <Spinner isFetching />;
    }
    let trailer = false;

    return (
      <div className="grid-container main-content">
        <h2 className="product-details">Product Details</h2>
        {assembly.result.map((r, index) => {
          trailer = r.Description.toLowerCase().includes('trailer') ?? false;
          return (
            <div key={index}>
              <Meta result={r} images={images} />
              <Specs result={r} parts={parts} {...this.state} />
              {r.Images.map((image, index) => {
                if (index === 1) {
                  return (
                    <img
                      key={index}
                      className="product-image danger"
                      // src={IMAGE_CDN + image.ImageGuid + '.png'}
                      src={image.ImageUrl + '.png'}
                      alt={r.PartNumber}
                      width="100"
                      height="100"
                    />
                  );
                }

                return null;
              })}
            </div>
          );
        })}
        {trailer ? (<div className="disclaimer text-center" style={{fontSize: '22px'}}><a href="https://cdn.intelligencebank.com/us/share/ONnl/E2gl/ZBpL8/original/BLTN_Technology_SemiFluidGreaseSuggestedFillVolumes_10012569" target='_blank'>Suggested Fill Volumes for Grade 00 Semi-Fluid Grease</a></div>) : ''}
        <a
          href="#"
          onClick={(e) => {e.preventDefault();history.goBack()}}
          className="general-button"
        >
          Return to results
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Details);
