import * as constants from '../config/constants'
import { push } from 'react-router-redux';
import _ from 'lodash'
import fetch from 'isomorphic-fetch'
import {invalidateAssembly} from './assembly';

const nav = { //from: to
    replace: {
        'register': 'choose-calculator',
        'choose-calculator': 'rebuild-costs',
        'rebuild-costs': 'replacement-costs',
        'replacement-costs': 'labor-costs',
        'labor-costs': 'downtime-costs',
        'downtime-costs': 'results',
        'results': 'email'
    }
}

const navigate = (type: string, current: string) => {
    let navString = _.get(nav, `${type}.${current}`, '404')
    if (navString !== '404') {
        navString = `${type}/${navString}`;
    }
    //console.log('path', navString);
    return push(`/${navString}`);
}

export const updateFilters = (obj) => {
  return Object.assign({type: constants.UPDATE_FILTER }, {update: obj});
}

export const resetFilters = (obj) => {
  return {
      type: constants.INVALIDATE_FILTERS
    }
}

export const resetState = (obj) => {
  return dispatch => {
    dispatch(invalidateHubs())
    dispatch(invalidateAssembly())
    dispatch(resetFilters())
  }

}

export const materialFilter = (filter) => {
  return {
    type: constants.SET_MATERIAL_FILTER,
    filter: filter
  }
}

export const updateStep = (step) => {
  return {
    type: constants.UPDATE_STEP,
    step: step
  }
}

export const incrementStep = () => {
  return {
    type: constants.INCREMENT_STEP
  }
}

export const decrementStep = () => {
  return {
    type: constants.DECREMENT_STEP
  }
}

export const updateLastPage = (path) => {
  return {
    type: constants.UPDATE_LAST_PAGE,
    lastPath: path
  }
}

export const setSelectedTruckMake = (id) => {
  return {
    type: constants.SET_TRUCK_MAKE,
    id: id
  }
}

export const showNextResult = () => {
  return {
    type: constants.SHOW_NEXT_RESULT
  }
}
export const showPreviousResult = () => {
  return {
    type: constants.SHOW_PREVIOUS_RESULT
  }
}

export const showResultAtIndex = (idx) => {
  return {
    type: constants.SHOW_RESULT_AT_IDX,
    idx: idx
  }
}

export const requestHubs = (partNumber) => {
  return {
    type: constants.REQUEST_HUBS,
    partNumber: partNumber
  }
}

export const requestDrums = (filtername, isFilterValueSingle) => {
    return {
        type: constants.REQUEST_DRUMS,
        isFitlerValueSingle: isFilterValueSingle,
        currentFilter: filtername
    }
}

export const requestCastings = (filtername, isFilterValueSingle) => {
    return {
        type: constants.REQUEST_CASTING,
        isFitlerValueSingle: isFilterValueSingle,
        currentFilter: filtername
    }
}


export const resetDrumFilter = () => {
    return {
        type: constants.RESET_DRUM_FILTER
    }
}

export const resetCastingFilter = () => {
    return {
        type: constants.RESET_CASTING_FILTER
    }
}


export const receiveHubs = (partNumber, json) => {
  // for now we need to usher the json into the following format
  // we need a part number and ID
  let hubs = []
  if (json.Status !== constants.ZERO_RESULTS) {
    // hubs = json.Results;
    const newFormat = json.Results.map( result => {
    return result.AftermarketParts.map(detail => {
          let mainImage = _.find(detail.Images, {ImageTypeId: 1})
          if (mainImage) {
            hubs.push(Object.assign(detail, {mainImageId: mainImage.ImageId}));
          } else {
            hubs.push(Object.assign(detail, {mainImageId: null}));
          }

          return Object.assign(detail, {PartNumber: detail.PartNumber})
        })
      })

  }

  return {
    type: constants.RECEIVE_HUBS,
    partNumber: partNumber,
    hubs: hubs,
    status: json.Status
  }


}

export const requestKits = () => {
    return {
        type: constants.REQUEST_KITS
    }
}

export const requestBearingKits = () => {
    return {
        type: constants.REQUEST_BEARING_KITS
    }
}

export const fetchHubs = (partNumber) => {
  const afterMarketHubPartType = 115
  return dispatch => {
    dispatch(requestHubs(partNumber))
    ///1/'+partNumber
    //https://apis.conmetwheelends.com/aftermarket/v1/summarydetails/~/10031065
    //https://apis.conmetwheelends.com/parts/api/v2/details/~/10031065

    return fetch(constants.API+'/aftermarketparts?hanum='+partNumber+'&party='+afterMarketHubPartType, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': constants.V2KEY
      }
    })
    .then(
      response => response.json(),
      err => {
        // console.log('API Error', err);
    })
    .then(json => dispatch(receiveHubs(partNumber, json)))
  }
}

export const fetchHubsSpindleNut = (partNumber) => {
    const afterMarketHubPartType = '226,227';
    return dispatch => {
        dispatch({ type: constants.REQUEST_OPTIONAL_SPINDLE_NUT });
        return fetch(constants.API+'/aftermarketparts?hanum='+partNumber+'&party='+afterMarketHubPartType, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        })
            .then(
                response => response.json(),
                err => {
                    // console.log('API Error', err);
                })
            .then(json => dispatch({
                type: constants.OPTIONAL_SPINDLE_NUT,
                assemblyNumber: json,
            }))
    }
}



export const fetchDrumFilterValues = (filtername, filtervalues, isFilterValueSingle = true) => {
    return dispatch => {
        dispatch(requestDrums(filtername, isFilterValueSingle));
        return fetch(constants.API+'/brakedrum/filtervalues/'+filtername+filtervalues, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.DRUM_FILTER_VALUES,
                    data: json,
                })
            })
    }
};

export const saveBrakeRotorNumber = (rotorNumber) => {
    return {
        type: constants.SELECTED_ROTOR_NUMBER,
        rotorNumber: rotorNumber
    }
};

export const fetchRotorFilterValues = (filtername, filtervalues, isFilterValueSingle = true) => {
    return dispatch => {
        dispatch(requestDrums(filtername, isFilterValueSingle));
        return fetch(constants.API+'/brakerotor/filtervalues/'+filtername+filtervalues, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_FILTER_VALUES,
                    data: json,
                })
            })
    }
};

export const fetchRotorCastingFilterValues = (filtername, filtervalues, isFilterValueSingle = true) => {
    return dispatch => {
        dispatch(requestDrums(filtername, isFilterValueSingle));
        return fetch(constants.API+'/rotorcasting/filtervalues/'+filtername+filtervalues, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_CASTING_FILTER_VALUES,
                    data: json,
                })
            })
    }
};

export const fetchDrumCastingFilterValues = (filtername, filtervalues, isFilterValueSingle = true) => {
    return dispatch => {
        dispatch(requestDrums(filtername, isFilterValueSingle));
        return fetch(constants.API+'/brakedrumcasting/filtervalues/'+filtername+filtervalues, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.DRUM_CASTING_FILTER_VALUES,
                    data: json,
                })
            })
    }
};

export const fetchCastingFilterValues = (filtername, filtervalues, isFilterValueSingle = true) => {
    return dispatch => {
        dispatch(requestCastings(filtername, isFilterValueSingle));
        return fetch(constants.APIV1+'/casting/filtervalues/'+filtername+(filtervalues ? '?'+filtervalues : ''), {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.CASTING_FILTER_VALUES,
                    data: json,
                })
            })
    }
};


export const fetchStudFilterValues = (filtername, filters) => {
  return dispatch => {
    dispatch(requestHubs('123'));
    return fetch(constants.API+'/stud/filtervalues/'+filtername+(filters ? '?'+filters : ''), {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': constants.V2KEY
      }
    }).then(
      response => response.json(),
      err => {
        // console.log('API Error', err);
      })
      .then(json => {
        dispatch({
          type: constants.STUD_FILTER_VALUES,
          data: json,
        })
      }).catch((err) => console.error('API Error', err))
  }
};

export const fetchStudSummaries = (stdid) => {
    return dispatch => {

          return fetch(constants.API+'/stud/filtervalues/sdnum?inclv=1&sdnum='+stdid, {
            method: 'get',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
          response => response.json(),
          err => {
            // console.log('API Error', err);
          })
          .then(json => {
            dispatch({
              type: constants.STUD_SUMMARIES,
              data: json,
            })
          })
      }
}

export const fetchDrumFilterCategories = () => {
    return dispatch => {
        dispatch(requestHubs('123'));
        return fetch(constants.API+'/brakedrum/filtercategories/', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.DRUM_FILTER_CATEGORIES,
                    data: json,
                })
            })
    }
};

export const fetchRotorFilterCategories = () => {
    return dispatch => {
        dispatch(requestHubs('123'));
        return fetch(constants.API+'/brakerotor/filtercategories/', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_FILTER_CATEGORIES,
                    data: json,
                })
            })
    }
};


export const fetchCastingFilterCategories = () => {
    return dispatch => {
        return fetch(constants.APIV1+'/casting/filtercategories/', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.CASTING_FILTER_CATEGORIES,
                    data: json,
                })
            })
    }
};

export const fetchRotorCastingFilterCategories = () => {
    return dispatch => {
        return fetch(constants.API+'/rotorcasting/filtercategories/', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_CASTING_FILTER_CATEGORIES,
                    data: json,
                })
            })
    }
};


export const fetchDrumCastingFilterCategories = () => {
    return dispatch => {
        return fetch(constants.API+'/brakedrumcasting/filtercategories/', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.DRUM_CASTING_FILTER_CATEGORIES,
                    data: json,
                })
            })
    }
};



export const fetchDrumNumber = (term) => {
    return dispatch => {
        return fetch(constants.API+'/brakedrum/interchanges/'+term, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.DRUM_NUMBER_DATA,
                    drumNumber: json,
                })
            })
    }
};

export const fetchRotorNumber = (term) => {
    return dispatch => {
        return fetch(constants.API+'/brakerotor/interchanges/'+term, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_NUMBER_DATA,
                    rotorNumber: json,
                })
            })
    }
};

export const fetchDrumResult = (drumNumberId) => {
    return dispatch => {
        dispatch(requestHubs(drumNumberId));
        return fetch(constants.API+'/brakedrum/summaries?cbdid='+drumNumberId, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.DRUM_RESULT,
                    data: json,
                })
            })
    }
};

export const fetchRotorResult = (rotorId) => {
    return dispatch => {
        dispatch(requestHubs(rotorId));
        return fetch(constants.API+'/brakerotor/summaries?cbrid='+rotorId, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_RESULT,
                    data: json,
                })
            })
    }
};


export const fetchRotorByCastingResult = (casting) => {
    return dispatch => {
        return fetch(constants.API+'/brakerotor/summaries/'+casting, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                 console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_RESULT,
                    data: json,
                })
            })
    }
};


export const fetchDrumDetail = (id) => {
    return dispatch => {
        dispatch(requestHubs(id));
        return fetch(constants.API+'/brakedrum/details/'+id, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.DRUM_DETAILS,
                    data: json,
                })
            })
    }
};

export const fetchRotorDetail = (id) => {
    return dispatch => {
        dispatch(requestHubs(id));
        return fetch(constants.API+'/brakerotor/details/'+id, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ROTOR_DETAILS,
                    data: json,
                })
            })
    }
};

export const fetchHubAssemblyNumber = (term, withAftermarketParts = false) => {
    return dispatch => {
        return fetch(`${constants.API}/hubassemblynumbers/${withAftermarketParts ? 'withaftermarketparts' : 'withaftermarkethubassemblies'}/${term}`, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.ASSEMBLY_NUMBER_DATA,
                    assemblyNumber: json,
                })
            })
    }
};

export const unifiedSearch = (term) => {
    return dispatch => {
        return fetch(constants.API+'/partnumbers/withaftermarketnumbers/'+term, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.PART_NUMBER_DATA,
                    partNumber: json,
                })
            })
    }
};

export const competitorSearch = (term) => {
    return dispatch => {
        return fetch(constants.API+'/kits/CompetitorPartNumberDetails/'+term, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.PART_NUMBER_DATA,
                    partNumber: json,
                })
            })
    }
};


export const fetchHubAssemblyFiltersWithNoResults = (id) =>{
    return dispatch => {
        return fetch(constants.API+'/hubassembliessummaries?chaid='+id, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.HUB_ASSEMBLY_FILTERS,
                    filters: json,
                })
            })
    }
};

export const fetchHubAssemblyFilters = (id) =>{
    return dispatch => {
        return fetch(constants.API+'/aftermarkethubassemblyfilters?chaid='+id, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.HUB_ASSEMBLY_FILTERS,
                    filters: json,
                })
            })
    }
};

export const fetchFilterValues = (filtername,filters) =>{
    return dispatch => {
        return fetch(constants.APIV14+'/hubassembly/filtervalues/'+filtername+'?'+filters, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.HUB_ASSEMBLY_FILTER_VALUE,
                    filters: json,
                })
            })
    }
};

export const fetchNotificationResult = () => {
    return dispatch => {
        dispatch(requestHubs('123'));
        return fetch(constants.API+'/aftermarketnotification', {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.NOTIFICATIONS_RESULT,
                    data: json,
                })
            })
    }
};

export function invalidateHubStuds() {
  return dispatch => dispatch({ type: constants.INVALIDATE_HUB_STUDS });
}

export const fetchHubsCrossApi = (filters, isShortStud = false) =>{
    return dispatch => {
        return fetch(constants.APIV14+'/hubassembly/filtervalues/hanum?'+filters, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
              if (isShortStud) {
                dispatch({ type: constants.FETCH_HUB_STUDS, result: json });
              } else {
                dispatch({ type: constants.RECEIVE_HUBS_CROSS_API, filters: json });
              }
            })
    }
};

export const fetchHubRebuildKitsResult = (partNumber) => {
    return dispatch => {
        dispatch(requestKits());
        return fetch(constants.API+'/kits/preSetPlusRebuildKit/'+partNumber, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_KITS_RESULT,
                    partNumber: json,
                })
            })
    }
};

export const fetchAbsServiceKitResult = (partNumber) => {
    return dispatch => {
        dispatch(requestKits());
        return fetch(constants.API+'/kits/absKit/'+partNumber, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_KITS_RESULT,
                    partNumber: json,
                })
            })
    }
};

export const fetchSealsResult = (partNumber) => {
    return dispatch => {
        dispatch(requestKits());
        return fetch(constants.API+'/kits/seal/'+partNumber, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_KITS_RESULT,
                    partNumber: json,
                })
            })
    }
};

export const fetchAbsToneRingResult = (partNumber) => {
    return dispatch => {
        dispatch(requestKits());
        return fetch(constants.API+'/kits/absToneRingKit/'+partNumber, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_KITS_RESULT,
                    partNumber: json,
                })
            })
    }
};

export const fetchSealSpacerKitResult = (partNumber) => {
    return dispatch => {
        dispatch(requestKits());
        return fetch(constants.API+'/kits/sealSpacerKit/'+partNumber, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_KITS_RESULT,
                    partNumber: json,
                })
            })
    }
};

export const fetchBearingResult = (partNumber) => {
    return dispatch => {
        dispatch(requestBearingKits());
        return fetch(constants.API+'/kits/bearing/'+partNumber, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_BEARING_KITS_RESULT,
                    FilterIdDetail: json,
                })
            })
    }
};

export const fetchBearingWithFilterResult = (partNumber, filterId) => {
    return dispatch => {
        dispatch(requestBearingKits());
        return fetch(constants.API+'/kits/bearing/'+partNumber+'?axleIds='+filterId, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_BEARING_KITS_RESULT,
                    FilterIdDetail: json,
                })
            })
    }
};

export const fetchSpindleNutKitResult = (partNumber) => {
    return dispatch => {
        dispatch(requestKits());
        return fetch(constants.API+'/kits/spindleKit/'+partNumber, {
            method: 'get',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                // console.log('API Error', err);
            })
            .then(json => {
                dispatch({
                    type: constants.RECEIVE_KITS_RESULT,
                    partNumber: json,
                })
            })
    }
};

/*export const setCookie = (cName, value, exdays) => {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var cValue = escape(value) + ((exdays == null) ? '' : '; expires=' + exdate.toUTCString());
    document.cookie = cName + '=' + cValue;
}

export const getCookie = (cName) => {
    var i, x, y, ARRcookies = document.cookie.split(';');
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf('='));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf('=') + 1);
        x = x.replace(/^\s+|\s+$/g, '');
        if (x == cName) {
            return unescape(y);
        }
    }
    return false;
}*/

export const expireSession = (sessionId: string) => ({
    type: constants.EXPIRE_SESSION,
    sessionId
});

export const registerSession = (email: string, type: string) => {
    return dispatch => {
        return fetch(constants.API+'/SessionCreate', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            }
        }).then(
            response => response.json(),
            err => {
                console.warn('err', err);
            }).then(json => {
                //console.log('old', json);
                dispatch({
                    type: constants.START_SESSION,
                    ...json
                })
                dispatch(navigate(type, 'register'))
            })
    }
};

export const updateSession = (sessionId: number, state: Object, current: string, calculator: string) => {
    if (_.isUndefined(sessionId) || sessionId < 1)  {
        return (dispatch) => {
            dispatch(navigate(calculator, 'register'))
        }
    }
    return dispatch => {
        return fetch(constants.API+'/SessionUpdate?id='+sessionId, {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            },
            body: JSON.stringify(_.mapKeys(state, (value, key) => _.snakeCase(key)))
        })
            .then(
                (response) => response.json(),
                (err) => {
                    console.warn('err', err);
                }
            )
            .then(json => {
                dispatch({
                    type: constants.UPDATE_SESSION,
                    state: _.mapKeys(state, (value, key) => _.camelCase(key))
                })
                dispatch(navigate(calculator, current));
            })
    }
};

export const startEmail  = (data) => {
    return (dispatch) => {
        dispatch({
            type: constants.EMAIL_START,
            sendingEmail: true,
            emailError: false
        });
        return fetch(constants.API+'/SendEmail', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': constants.V2KEY
            },
            body: JSON.stringify(_.mapKeys(data, (value, key) => _.camelCase(key)))
        }).then(
                (response) => response.json(),
                (err) => {
                    return {
                        type: constants.EMAIL_FAIL,
                        sendingEmail: false,
                        emailError: true,
                    }
                }
            )
            .then((json) => {
                dispatch({type: constants.EMAIL_SUCCESS,
                    sendingEmail: false,
                    emailError: false
                })
                dispatch(push(`/${data.calculator}/confirmation`))
            })
    }
}

export const invalidateHubs = () => {
  return {
    type: constants.INVALIDATE_HUBS,
  }
}

export const fetchDetails = (assemblyNumber) => {

}

export const requestDetails = (assemblyNumber) => {

}

export const receiveDetails = (assemblyNumber, json) => {

}

// Selectors
export const actionCreators = {
    registerSession,
    expireSession,
    updateSession,
    startEmail,
};