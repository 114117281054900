import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  fetchDrumCastingFilterValues,
  resetDrumFilter,
  fetchDrumCastingFilterCategories,
} from '../../actions';
import Waiting from '../global/waiting';
import DrumResult from '../drum/drumResult';


class DrumCastingFilter extends Component {
  constructor(props) {
    super(props);
    this.getFilterValue = this.getFilterValue.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);

    this.state = {
      filters: [],
      urlParams: '',
      isResult: false,
      filterClicked: false,
    };
  }

  componentWillMount() {
    const { dispatch, results } = this.props;
    const { drumCastingFilters } = results;
    if (!drumCastingFilters.length) {
      dispatch(fetchDrumCastingFilterCategories());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetDrumFilter());
  }

  componentDidMount() {
    this.getFilterValue();
  }

  componentDidUpdate() {
    this.getFilterValue();
  }

  isNil(value) {
    return value !== null && value !== undefined;
  }

  getValue(item) {
    if (this.isNil(item.Id)) {
      return item.Id;
    }
     else if (this.isNil(item.Inch)) {
      return item.Inch;
    } else if (this.isNil(item.Inches)) {
      return item.Inches;
    }
    if (item !== undefined) {
      return item;
    }
  }



  handleFilterClick(item) {
    const id = this.getValue(item);
    const { dispatch, results } = this.props;
    const { currentFilter } = results;
    const { filters, urlParams, skipFilter } = this.state;
    const index =
      currentFilter === 'aaxna' ? 1 : filters.indexOf(currentFilter);
    this.state.filterClicked = true;
    this.state.urlParams +=
      urlParams === ''
        ? currentFilter + '=' + id
        : '&' + currentFilter + '=' + id;

    let url = this.state.urlParams;


    var parturl = (filters.length === index + 1 ? filters[0] : filters[index + 1]);
    dispatch(
      push(
        '/hub/drum-casting/filter/' + parturl
        +
        '/' +
        url
      )
    );
  }

  getFilterValue() {
    const { results, match, dispatch } = this.props;
    const { drumCastingFilters, drumCastingFilterValue } = results;

    const isResult =
      drumCastingFilterValue &&
      drumCastingFilterValue[0] &&
      drumCastingFilterValue[0].BrakeDrumNumber;

    const { filters } = this.state;

    this.state.urlParams = match.params.filters ? match.params.filters : '';

    if (drumCastingFilters.length) {
      this.state.filters = drumCastingFilters.map(
        (item) => item.QueryParameterName
      );
    }

    let filterParams = match.params.filters
      ? '?inclv=2&' + match.params.filters
      : '?inclv=2';
    this.state.url = filterParams;

    if (match.params.currentFilter !== this.state.currentFilter) {      
      dispatch(fetchDrumCastingFilterValues(match.params.currentFilter, filterParams));
      this.state.currentFilter = match.params.currentFilter;
    }
 
    else if (
      drumCastingFilters.length &&
      drumCastingFilterValue &&
      drumCastingFilterValue.length === 1
      && !isResult
      &&
      results.isFilterValueSingle
    ) {

      let index = filters.indexOf(results.currentFilter);

      const id =
        drumCastingFilterValue[0].Id !== undefined
          ? drumCastingFilterValue[0].Id
          : drumCastingFilterValue[0];

      if (this.state.url === '') {
        this.state.url = filterParams;
      }
      else {
        this.state.url += '&' + filters[index] + '=' + this.getValue(id);

        dispatch(
          fetchDrumCastingFilterValues(filters[0], this.state.url)
        );

      }
    }
  }


  render() {
    const { results } = this.props;
    const { drumCastingFilterValue, currentFilter } = results;


    if (results.isFetching) {
      return <Waiting />;
    }

    if (
      currentFilter === 'bcdia' ||
      currentFilter === 'bhdia' ||
      currentFilter === 'pidia' ||
      currentFilter === 'ovdpt' 
      && drumCastingFilterValue !== undefined)
     {
      let message = '';

      if (currentFilter === 'pidia') {
        message = 'Choose the pilot diameter';
      } else if (currentFilter === 'bcdia') {
        message = 'Choose the bolt circle diameter';
      } else if (currentFilter === 'bhdia') {
        message = 'Choose the bolt hole diameter';
      } else if (currentFilter === 'ovdpt') {
        message = 'Choose the brake diameter';
      }

      return (
        <div className="grid-container main-content replacement-drum">
          <h2>{message}</h2>
          {currentFilter === 'pidia' ? (
            <img
              className="product-image"
              src={require('../../images/pidia.png')}
              alt="Pilot Diameter"
              width="300"
              height="300 "
            />
          ) : null}
          <div className="grid-content">
            {drumCastingFilterValue.map((item) => {
              return (
                <div className="small-12" key={item}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item)}
                    >
                      ⌀ {item} inch{' '}
                      {currentFilter === 'pidia' && item === 8.78
                        ? '- Most common'
                        : ''}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (currentFilter === 'holes') {
      return (
        <div className="grid-container main-content replacement-drum">
          <h2>Choose the Stud Hole count</h2>
          <div className="grid-content">
            {drumCastingFilterValue.map((item) => {
              return (
                <div className="small-12" key={item}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item)}
                    >
                      {item}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (currentFilter === 'brnum') {
      return <DrumResult drumResult={drumCastingFilterValue} /> 
    }
 
    return <div />;
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DrumCastingFilter);
