import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  render() {
    const { studFilterValues, studNumbers } = this.props;

    return (
      <div className="grid-container main-content">
        <div className="stud-chart">
          <h2 className="">Available Lengths:</h2>
          <table>
            <tbody>
              <th>Stud Number</th>

              {/* <th>
                                Chart Description
                            </th> */}

              <th>Dimension A/B/C/D</th>
              {/* 
                            
                            <th>
                                Dimension B
                            </th>
                            
                            <th>
                                Dimension C
                            </th>
                            
                            <th>
                                Dimension D
                            </th> */}
              {studFilterValues.map((result, i) => {
                if (result.StudNumber === studNumbers[0].StudNumber) {
                  return (
                    <tr className="selected">
                      <td>
                        <Link to={"/hub/stud/summaries/" + result.StudNumber}>
                          {result.StudNumber}
                        </Link>
                      </td>
                      {/* 
                                            <td>
                                                {result.ChartDescription}
                                            </td> */}
                      <td>
                        {result.DimensionA} / {result.DimensionB} /{" "}
                        {result.DimensionC} / {result.DimensionD}
                      </td>
                      {/* 
                                            <td>
                                                {result.DimensionB}
                                            </td>
                                            <td>
                                                {result.DimensionC}
                                            </td>
                                            <td>
                                                {result.DimensionD}
                                            </td> */}
                    </tr>
                  );
                }
                return (
                  <tr>
                    <td>
                      <Link to={"/hub/stud/summaries/" + result.StudNumber}>
                        {result.StudNumber}
                      </Link>
                    </td>
                    {/*                                     <td>
                                        {result.ChartDescription}
                                    </td> */}
                    <td>
                      {result.DimensionA} / {result.DimensionB} /{" "}
                      {result.DimensionC} / {result.DimensionD}
                    </td>
                    {/* 
                                    <td>
                                        {result.DimensionB}
                                    </td>
                                    <td>
                                        {result.DimensionC}
                                    </td>
                                    <td>
                                        {result.DimensionD}
                                    </td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
