import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {competitorSearch, fetchSealSpacerKitResult} from '../../actions';
import NoResults from '../global/no-result';
import classNames from 'classnames';

class SealSpacerKitDetail extends React.Component {
    constructor() {
        super();

        this.state = {
            rebuildKitActive: false,
        };
    }
    details = false;

    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber) {
            dispatch(fetchSealSpacerKitResult(match.params.partNumber));
        }
    }

    componentWillReceiveProps(newProps) {
        const {dispatch, match, results} = newProps;
        const sskDetail = results.partNumber;

        if (results && !results.isFetching && sskDetail && sskDetail.length === 0 && !this.details) {
            dispatch(competitorSearch(match.params.partNumber)).then(() => {
                this.details = true;
                const {results} = this.props;
                const sskDetail = results.partNumber;
                const partNum = sskDetail && sskDetail[0] && sskDetail[0].ConMetPartNumber;
                dispatch(fetchSealSpacerKitResult(partNum));
            });
        }
    }

    render() {
        const { results } = this.props;
        const sskDetail = results.partNumber;

        if (results.isFetching) {
            return <Waiting />;
        }

        if (sskDetail && sskDetail.length === 0 || sskDetail === undefined) {
            if (!this.details) {
                return <Waiting />;
            }else{
                return <NoResults />;
            }
        }

        return (
            <div className="grid-container main-content result">
                <h2>Product Details</h2>
                <div className="meta">
                    <span>
                        <img
                            className="product-image"
                            src={sskDetail && sskDetail[0] && sskDetail[0].ImageId ? sskDetail && sskDetail[0] && sskDetail[0].ImageId + '.png' : 'https://cdn.intelligencebank.com/us/share/ONnl/Aa8M/73rBb/original/Image_Not_Available'}
                            alt="SealSpacerKit"
                            width="100"
                            height="100"
                        />
                    </span>
                    {sskDetail && sskDetail[0] && sskDetail[0].ImageURL} {sskDetail && sskDetail[0] && sskDetail[0].Axle} {sskDetail && sskDetail[0] && sskDetail[0].AxlePostion}<br/>
                    <em>{sskDetail && sskDetail[0] && sskDetail[0].PartNumber}</em>
                </div>
                <div>
                    <div className="specs mb-3">
                        <h2>Product Specs</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td width="70%">Axle Position</td>
                                <td>{sskDetail && sskDetail[0] && sskDetail[0].AxlePostion ? sskDetail && sskDetail[0] && sskDetail[0].AxlePostion : '-'}</td>
                            </tr>
                            <tr>
                                <td>Axle</td>
                                <td>{sskDetail && sskDetail[0] && sskDetail[0].Axle ? sskDetail && sskDetail[0] && sskDetail[0].Axle : '-'}</td>
                            </tr>
                            <tr style={{ border: 'none' }}>
                                <td>
                                  <span
                                      className={classNames('rebuild-kit-toggle', { show: this.state.rebuildKitActive })}
                                      onClick={() => this.setState({ rebuildKitActive: !this.state.rebuildKitActive })}
                                  >
                                      {sskDetail && sskDetail[0] && sskDetail[0].ImageURL}</span>
                                </td>
                                <td>
                                    {sskDetail && sskDetail[0] && sskDetail[0].PartNumber}
                                </td>
                            </tr>
                            <tr style={{ paddingTop: 0 }}>
                                <td colSpan={2} style={{ padding: !this.state.rebuildKitActive ? 0 : '' }}>
                                    <table
                                        className={classNames('rebuild-kit-info', { 'show m-0 show1': this.state.rebuildKitActive })}
                                        key=""
                                    >
                                        <tr>
                                            <td width="70%">Seal</td>
                                            <td>{sskDetail && sskDetail[0] && sskDetail[0].Type ? sskDetail && sskDetail[0] && sskDetail[0].Type : '-'}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SealSpacerKitDetail);
