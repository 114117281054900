import React, { Component } from 'react';
import { connect } from 'react-redux';
import Step from '../hub/step';
import {
  setActiveFilterValue,
  previousFilter,
} from '../../actions/filters';
import { fetchAssembly } from '../../actions/assembly';
import {
  fetchHubs,
} from '../../actions/index';
import {Switch, Route} from 'react-router-dom';

import HubSelectionChoosePath from './choose-path'
import ReplacementRotor from '../rotor/replacement-rotor';
import ReplacementRotorFilter from '../rotor/replacement-rotor-filter';
import Casting from '../casting/casting';
import CastingFilter from '../casting/casting-filter';

import RotorCasting from '../rotorCasting/rotorcasting';
import RotorCastingFilter from '../rotorCasting/rotorcasting-filter';

import DrumCasting from '../drumCasting/drumCasting';
import DrumCastingFilter from '../drumCasting/drumCasting-filter';


import StudSummary from '../stud/stud-summary';
import StudFilter from '../stud/stud-filter';
import StudFilters from '../stud/stud-filters';
import ReplacementRotorSearch from '../rotor/replacement-rotor-search';
import ReplacementRotorResult from '../rotor/replacement-rotor-result';
import ReplacementRotorCastingResult from '../rotor/replacement-rotor-casting-result';

import ReplacementRotorDetail from '../rotor/replacement-rotor-detail';
import ReplacementDrum from '../drum/replacement-drum';
import ReplacementDrumFilter from '../drum/replacement-drum-filter';
import ReplacementDrumSearch from '../drum/replacement-drum-search';
import ReplacementDrumResult from '../drum/replacement-drum-result';
import ReplacementDrumDetail from '../drum/replacement-drum-detail';
import HubSelectionSearch from './search';
import Filters from './filters';
import NotAvailableHub from './not-available-hub';
import CompareAssembly from './compare-assembly';
import HubSelectionResults from './results';
import HubSelectionDetails from './details';
import HubSelectionEmail from './email';
import HubFinder from './finder';
import HubSelectionTruckType from './truck-type';
import HubSelectionTruckMake from './truck-make';
import HubSelectionAxelType from './axel-type';
import HubSelectionMountingSystem from './hub-mounting-system';
import HubSelectionWheelPilotSystem from './wheel-pilot-system';
import HubSelectionHubPilotType from './hub-pilot-type';
import HubSelectionStudPilotType from './stud-pilot-type';
import HubSelectionWheelStudStandoutInches from './wheel-stud-standout-inches';
import HubSelectionThreadOrientation from './thread-orientation';
import HubSelectionGAWR from './gawr';
import HubSelectionAxleStud from './axle-stud';
import HubSelectionWheelType from './wheel-type';
import HubSelectionAssemblyType from './hub-assembly-type';
import HubSelectionSpindleNut from './spindle-nut';
import HubSelectionMaterial from './material';
import HubSelectionType from './hub-type';
import BrakeRotorFlange from './brake-rotor-flange';
import BrakeRotorType from './brake-rotor-type';
import HubSelectionOverallRotorLength from './overall-rotor-length';
import HubSelectionABSOffsetInches from './abs-offset-inches';
import HubSelectionHubCapBoltCircleDiameterInches from './hub-cap-bolt-circle-diameter-inches';
import HubSelectionHubOffsetInches from './hub-offset-inches';
import HubSelectionDrumPilotDiameterInches from './drum-pilot-diameter-inches';
import HubSelectionBarrelLength from './barrel-length';
import HubSelectionSmallBackface from './small-backface';
import HubSelectionFindAssembly from './find-assembly';
import HubSelectionFindAssemblyDetail from './find-assembly-detail';
import CompareHub from './compare-hub';
import NoResults from '../global/no-result';
import HubSelectionMeritorExclusive from './meritor-exclusive';
import VolvoExclusive from './volvo-exclusive';
import NotificationDetail from '../general/notification-detail';
import HubRebuildKitsDetail from '../preSetPlusRebuildKit/hub-rebuild-kits-detail';
import AbsServiceKitDetail from '../absServiceKit/abs-service-kit-detail';
import SealsDetail from '../seals/seals-detail';
import AbsToneRingDetail from '../absToneRing/abs-tone-ring-detail';
import SpindleNutKitDetail from '../spindleNutKit/spindle-nut-kit-detail';
import SealSpacerKitDetail from '../sealSpacerKit/seal-spacer-kit-detail';
import BearingDetail from '../bearing/bearing-detail';
import BearingFilterDetail from '../bearing/bearing-filter-detail';

class HubSelector extends Component {
  render() {
    const {
      dispatch,
      assembly,
      history,
      results,
      app,
      params,
      images,
      parts,
    } = this.props;
    const childProps = {
      params,
      parts,
      app,
      images,
      assembly,
      results,
      setFilter: (filterId, id, app) => {
        dispatch(setActiveFilterValue(filterId, id, app));
      },
      setActive: (filterId, selected, baseClass = 'conmet-button') => {
        if (app.filterState[filterId] === selected) {
          return baseClass + ' active';
        }
        return baseClass;
      },
      goBack: (app) => {
        dispatch(previousFilter(app));
      },
      searchForAssembly: (partNumber) => {
        if (partNumber) {
          dispatch(fetchHubs(partNumber));
        } else {
          dispatch(fetchAssembly());
        }
      },
    };

    const backClick = childProps.goBack.bind(this, app);
    return (
      <div className="grid-block vertical align-center">
        <Step
          history={history}
          dispatch={dispatch}
          app={app}
          onClick={backClick}
          />
        <div className="grid-content">
          <div className="grid-container main-content">
            <Switch>
              <Route exact path="/hub/casting" component={Casting} />
              <Route exact path="/hub/casting/filter" component={CastingFilter} />
              <Route exact path="/hub/casting/filter/:currentFilter" component={CastingFilter} />
              <Route path="/hub/casting/filter/:currentFilter/:filters" component={CastingFilter}/>

              <Route exact path="/hub/rotor-casting" component={RotorCasting} />
              <Route exact path="/hub/rotor-casting/filter" component={RotorCastingFilter} />
              <Route exact path="/hub/rotor-casting/filter/:currentFilter" component={RotorCastingFilter} />
              <Route path="/hub/rotor-casting/filter/:currentFilter/:filters" component={RotorCastingFilter}/>

              <Route exact path="/hub/drum-casting" component={DrumCasting} />
              <Route exact path="/hub/drum-casting/filter" component={DrumCastingFilter} />
              <Route exact path="/hub/drum-casting/filter/:currentFilter" component={DrumCastingFilter} />
              <Route path="/hub/drum-casting/filter/:currentFilter/:filters" component={DrumCastingFilter}/>

              <Route exact path="/hub/stud/summaries" component={StudSummary} />
              <Route path="/hub/stud/summaries/:id" component={StudSummary} />
              <Route exact path="/hub/stud/filter" component={StudFilter} />
              <Route exact path="/hub/stud/filter/:currentFilter" component={StudFilter} />
              <Route path="/hub/stud/filters/:currentFilter" component={StudFilters} />
              <Route path="/hub/stud/filter/:currentFilter/:filters" component={StudFilter} />
              <Route exact path="/hub/replacement-rotor" component={ReplacementRotor} />
              <Route exact path="/hub/replacement-rotor/filter" component={ReplacementRotorFilter} />
              <Route path="/hub/replacement-rotor/filter/:currentFilter/:filters" component={ReplacementRotorFilter} />
              <Route path="/hub/replacement-rotor/filter/:currentFilter" component={ReplacementRotorFilter} />
              <Route path="/hub/replacement-rotor/search" component={ReplacementRotorSearch} />
              <Route path="/hub/replacement-rotor/recommended-rotors/:rotorId" component={ReplacementRotorResult} />
              <Route path="/hub/replacement-rotor/rotors/:casting" component={ReplacementRotorCastingResult} />
              <Route path="/hub/replacement-rotor/rotordetails/:id" component={ReplacementRotorDetail} />
              <Route path="/hub/replacement-rotor/rotordetails/:id/:kitId" component={ReplacementRotorDetail} />
              <Route exact path="/hub/replacement-drum" component={ReplacementDrum} />
              <Route exact path="/hub/replacement-drum/filter" component={ReplacementDrumFilter} />
              <Route path="/hub/replacement-drum/filter/:currentFilter/:filters" component={ReplacementDrumFilter} />
              <Route path="/hub/replacement-drum/filter/:currentFilter" component={ReplacementDrumFilter} />
              <Route path="/hub/replacement-drum/search" component={ReplacementDrumSearch} />
              <Route path="/hub/replacement-drum/recommended-drums/:drumId" component={ReplacementDrumResult} />
              <Route path="/hub/replacement-drum/drumdetails/:id" component={ReplacementDrumDetail} />
              <Route path="/hub/choose-path" component={HubSelectionChoosePath} />
              <Route path="/hub/search" component={HubSelectionSearch} />
              <Route path="/hub/filters/:filters" component={Filters} />
              <Route path="/hub/not-available/:id" component={NotAvailableHub} />
              <Route path="/hub/compare/:assembly/:compareAssembly" component={CompareAssembly} />
              <Route path="/hub/results" component={HubSelectionResults} />
              <Route path="/hub/details/:id" component={HubSelectionDetails} />
              <Route path="/hub/email" component={HubSelectionEmail} />
              <Route path="/hub/finder" component={HubFinder} />
              <Route path="/hub/truck-type" render={() => (<HubSelectionTruckType {...childProps}  />)} />
              <Route path="/hub/truck-make" render={() => (<HubSelectionTruckMake {...childProps}  />)} />
              <Route path="/hub/axle-type" render={() => (<HubSelectionAxelType {...childProps}  />)} />
              <Route path="/hub/hub-mounting-system" render={() => (<HubSelectionMountingSystem {...childProps}  />)} />
              <Route path="/hub/wheel-pilot-system" render={() => (<HubSelectionWheelPilotSystem {...childProps}  />)} />
              <Route path="/hub/hub-pilot-type" render={() => (<HubSelectionHubPilotType {...childProps}  />)} />
              <Route path="/hub/stud-pilot-type" render={() => (<HubSelectionStudPilotType {...childProps}  />)} />
              <Route path="/hub/wheel-stud-standout" render={() => (<HubSelectionWheelStudStandoutInches {...childProps}  />)} />
              <Route path="/hub/thread-orientation" render={() => (<HubSelectionThreadOrientation {...childProps}  />)} />
              <Route path="/hub/gawr" render={() => (<HubSelectionGAWR {...childProps}  />)} />
              <Route path="/hub/axle-stud" render={() => (<HubSelectionAxleStud {...childProps}  />)} />
              <Route path="/hub/wheel-type" render={() => (<HubSelectionWheelType {...childProps}  />)} />
              <Route path="/hub/spindle-nut" render={() => (<HubSelectionSpindleNut {...childProps}  />)} />
              <Route path="/hub/material" render={() => (<HubSelectionMaterial {...childProps}  />)} />
              <Route path="/hub/hub-type" render={() => (<HubSelectionType {...childProps}  />)} />
              <Route path="/hub/brake-rotor-flange" render={() => (<BrakeRotorFlange {...childProps}  />)} />
              <Route path="/hub/brake-rotor-type" render={() => (<BrakeRotorType {...childProps}  />)} />
              <Route path="/hub/overall-rotor-length" render={() => (<HubSelectionOverallRotorLength {...childProps}  />)} />
              <Route path="/hub/abs-offset-inches" render={() => (<HubSelectionABSOffsetInches {...childProps}  />)} />
              <Route path="/hub/hub-cap-bolt-circle-diameter-inches" render={() => (<HubSelectionHubCapBoltCircleDiameterInches {...childProps}  />)} />
              <Route path="/hub/hub-offset-inches" render={() => (<HubSelectionHubOffsetInches {...childProps}  />)} />
              <Route path="/hub/drum-pilot-diameter-inches" render={() => (<HubSelectionDrumPilotDiameterInches {...childProps}  />)} />
              <Route path="/hub/barrel-length" render={() => (<HubSelectionBarrelLength {...childProps}  />)} />
              <Route path="/hub/small-backface" render={() => (<HubSelectionSmallBackface {...childProps}  />)} />
              <Route path="/hub/overall-rotor-length" render={() => (<HubSelectionOverallRotorLength {...childProps}  />)} />
              <Route path="/hub/find-assembly" component={HubSelectionFindAssembly} />
              <Route path="/hub/find-assembly-detail" component={HubSelectionFindAssemblyDetail} />
              <Route path="/hub/compare-hub" component={CompareHub} />
              <Route path="/hub/no-results" component={NoResults} />
              <Route path="/hub/meritor-exclusive" component={HubSelectionMeritorExclusive} />
              <Route path="/hub/volvo-exclusive" component={VolvoExclusive} />
              <Route path="/hub/notification-detail/:id" component={NotificationDetail} />
              <Route path="/hub/hub-rebuild-kits-detail/:partNumber" component={HubRebuildKitsDetail} />
              <Route path="/hub/abs-service-kit-detail/:partNumber" component={AbsServiceKitDetail} />
              <Route path="/hub/seals-detail/:partNumber" component={SealsDetail} />
              <Route path="/hub/abs-tone-ring-detail/:partNumber" component={AbsToneRingDetail} />
              <Route path="/hub/spindle-nut-kit-detail/:partNumber" component={SpindleNutKitDetail} />
              <Route path="/hub/seal-spacer-kit-detail/:partNumber" component={SealSpacerKitDetail} />
              <Route path="/hub/bearing-detail/:partNumber" component={BearingDetail} />
              <Route path="/hub/bearing-filter-detail/:partNumber/:filterId" component={BearingFilterDetail} />
              <Route path="/hub/hub-assembly-type" render={() => (<HubSelectionAssemblyType {...childProps}  />)} />
          </Switch>
          </div>
        </div>
      </div>
    );
  }
}

// Which props do we want to inject, given the global state?
// Note: use https://github.com/faassen/reselect for better performance.
function select(state) {
  return {
    app: state.app,
    parts: state.parts,
    images: state.images,
    assembly: state.assembly,
    truckMakes: state.truckMakes,
    results: state.results,
    materialFilter: state.materialFilter,
  };
}
export default connect(select)(HubSelector);
