import React from 'react';
import {connect} from 'react-redux';
import Waiting from '../../components/global/waiting';
import {fetchRotorResult} from '../../actions';
import NoResults from '../global/no-result';
import RotorDiscontinued from '../global/rotor-discontinued';
import RotorResult from './rotorResult';

class ReplacementRotorResult extends React.Component {
  componentWillMount() {
    const {dispatch, match} = this.props;
    if (match.params.rotorId) {
      dispatch(fetchRotorResult(match.params.rotorId));
    }
  }

  render() {
    const {results} = this.props;
    const {selectedRotorNumber, rotorResult} = results;
    
    if (results.isFetching) {
      return (<Waiting/>)
    }

    if (selectedRotorNumber === '10009970'  ) {
      return (<RotorDiscontinued selectedRotorNumber={selectedRotorNumber}/>)
    }

    if (rotorResult.length === 0 && results.isZeroResults === true) {
      return (<NoResults/>)
    }

    return (
      <RotorResult rotorResult={rotorResult}/>
    )
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(ReplacementRotorResult);
