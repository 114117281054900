import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../../components/global/spinner";
import { fetchFilters } from "../../actions/filters";

let FILTERIDX;
const NEXT_FILTER_PATH = "/hub/wheel-type";

class Result extends Component {
  render() {
    var { result, active, onClick } = this.props;
    return (
      <div className="grid-content small-6">
        <div className={active()}>
          <button className="yes-no-button" onClick={onClick}>
            <strong>{result.NominalDiameter + '"'}</strong>
          </button>
        </div>
      </div>
    );
  }
}

class AxelStud extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === "axthd") !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === "axthd")
        : 12;
    dispatch(fetchFilters(FILTERIDX, app));
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    return (
      <div className="grid-container main-content">
        <h2>Choose the Axle Stud Diameter</h2>

        <div className="grid-block">
          {app.filterResults.map((result) => {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { axthd: result.Id },
              app
            );
            var boundActive = setActive.bind(this, FILTERIDX, result.Id);
            return (
              <Result
                key={result.Id}
                app={app}
                result={result}
                active={boundActive}
                onClick={boundClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AxelStud);
