import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';

let FILTERIDX;

class Result extends Component {
    render() {
        const { active, onClick, result } = this.props;
        return (
            <div className="grid-content small-6 btn-yes-no">
                <div className={active()}>
                    <button className="yes-no-button" onClick={onClick}>
                        <strong>{result === 'true' ? 'No' : 'Yes'}</strong>
                    </button>
                </div>
            </div>
        );
    }
}

class SpindleNut extends Component {
    componentDidMount() {
        const { dispatch, app } = this.props;
        FILTERIDX =
            app.categories.findIndex((a) => a.QueryParameterName === 'spints') !==
            undefined
                ? app.categories.findIndex((a) => a.QueryParameterName === 'spints')
                : 24;
        dispatch(fetchFilters(FILTERIDX, app));

    }

    render() {
        const { app, setFilter, setActive } = this.props;
        if (app.isFetching || app.filterResults.length < 1) {
            return <Spinner isFetching={app.isFetching} />;
        }
        let src = 'https://cdn.intelligencebank.com/us/share/ONnl/YgLq/b4rN/original/PreSetPlus_SpindleNut';
        return (
            <div className="grid-container main-content" id="#spindleNutResult">
                <div className="grid-content">
                    <div className="small-12">
                        <h2 className="include-text">
                            Upgrade to industry standard PreSet Plus<span>&#174;</span> hubs with 5 year/500,000 mile warranty?
                        </h2>
                        <img
                            className="brake-rotor-flange"
                            src={src}
                            alt="ConMet"
                            width="300"
                            height="300"
                        />
                        <div className="grid-block">
                            {app.filterResults.map((result, index) => {
                                var boundClick = setFilter.bind(
                                    this,
                                    FILTERIDX,
                                    { spints: result === false ? 'true' : 'false' },
                                    app
                                );
                                var boundActive = setActive.bind(this, FILTERIDX, result);
                                return (
                                    <Result
                                        key={index}
                                        result={ result === false ? 'true' : 'false' }
                                        onClick={boundClick}
                                        active={boundActive}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SpindleNut);
