import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {competitorSearch, fetchAbsServiceKitResult} from '../../actions';
import NoResults from '../global/no-result';

class AbsServiceKitDetail extends React.Component {
    details = false;
    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber) {
            dispatch(fetchAbsServiceKitResult(match.params.partNumber));
        }
    }

    componentWillReceiveProps(newProps) {
        const {dispatch, match, results} = newProps;
        const askDetail = results.partNumber;

        if (results && !results.isFetching && askDetail && askDetail.length === 0 && !this.details) {
            dispatch(competitorSearch(match.params.partNumber)).then(() => {
                this.details = true;
                const {results} = this.props;
                const askDetail = results.partNumber;
                const partNum = askDetail && askDetail[0] && askDetail[0].ConMetPartNumber;
                dispatch(fetchAbsServiceKitResult(partNum));
            });
        }
    }

    render() {
        const { results } = this.props;
        const askDetail = results.partNumber;

        if (results.isFetching) {
            return <Waiting />;
        }

        if (askDetail && askDetail.length === 0 || askDetail === undefined) {
            if (!this.details) {
                return <Waiting />;
            }else{
                return <NoResults />;
            }
        }

        return (
            <div className="grid-container main-content result">
                <h2>Success! The following abs ring is recommended</h2>
                <div>
                    <img
                        className="product-image"
                        src={askDetail && askDetail[0] && askDetail[0].ImageURL ? askDetail && askDetail[0] && askDetail[0].ImageURL + '.png' : 'https://cdn.intelligencebank.com/us/share/ONnl/Aa8M/73rBb/original/Image_Not_Available'}
                        alt="AbsServiceKit"
                        width="250"
                        height="250"
                    />
                </div>
                <h2>{askDetail && askDetail[0] && askDetail[0].ProductType}:</h2>
                <h2>
                    {askDetail && askDetail[0] && askDetail[0].ABSServiceKitNumber}
                </h2>
                <p className="text-kit">
                    <b>Includes ABS Ring {askDetail && askDetail[0] && askDetail[0].ABSToneRingNumber}</b>
                    <br />
                    {askDetail && askDetail[0] && askDetail[0].WhatKitContains ? 'Kit contains' : ''} {askDetail && askDetail[0] && askDetail[0].WhatKitContains ? askDetail && askDetail[0] && askDetail[0].WhatKitContains : ''}
                </p>

                <div>
                    <div className="specs mb-3">
                        <h2>Product Specs</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Product Type</td>
                                    <td>{askDetail && askDetail[0] && askDetail[0].ProductType ? askDetail && askDetail[0] && askDetail[0].ProductType : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Abs Type</td>
                                    <td>{askDetail && askDetail[0] && askDetail[0].ABSType ? askDetail && askDetail[0] && askDetail[0].ABSType : '-'}</td>
                                </tr>
                                <tr>
                                    <td>Abs Tone Ring</td>
                                    { askDetail && askDetail[0] && askDetail[0].ABSToneRingNumber === '10083900' ? (
                                            <a href="/hub/abs-tone-ring-detail/10083900"><td>{askDetail && askDetail[0] && askDetail[0].ABSToneRingNumber ? askDetail && askDetail[0] && askDetail[0].ABSToneRingNumber : '-'}</td> </a>) :
                                        (
                                            <td>{askDetail && askDetail[0] && askDetail[0].ABSToneRingNumber ? askDetail && askDetail[0] && askDetail[0].ABSToneRingNumber : '-'}</td>
                                        )}
                                </tr>
                                <tr>
                                    {askDetail && askDetail[0] && askDetail[0].ABSServiceKitNumber === '10085622' ||
                                    askDetail && askDetail[0] && askDetail[0].ABSServiceKitNumber === '10086542' ? (
                                        <td>Snap ring</td>
                                    ) : (
                                        <td>Screw</td>
                                    )}
                                    <td>{askDetail && askDetail[0] && askDetail[0].ScrewNumber ? askDetail && askDetail[0] && askDetail[0].ScrewNumber : '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AbsServiceKitDetail);
