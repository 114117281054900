import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';
import { TRUCK, DRUM } from '../../config/constants';

let FILTERIDX;
const NEXT_PATH = '/hub/hub-mounting-system';
//const NEXT_PATH = '/hub/wheel-stud-standout';

const NEXT_GAWR_FILTER_PATH = '/hub/gawr';
const NEXT_RESULT_PATH = '/hub/results';

class Result extends Component {
  render() {
    const { result, active, onClick } = this.props;
    const unitized = result.AftermarketAxle === 'Unitized';
    return (
      <div className={active()}>
        <button className="yes-no-button" onClick={onClick}>
          <strong>{result.AftermarketAxle}</strong> {result.AxleDescription.replace(result.AftermarketAxle, ' ')}
          <br />
          <span>
            {unitized ? '' : 'Inner Bearing -'}{' '}
            {result.BearingDescriptionInboard}
            <br />
            {unitized ? '' : 'Outer Bearing -'}{' '}
            {result.BearingDescriptionOutboard}
          </span>
        </button>
      </div>
    );
  }
}

class AxelType extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'aaxna') !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'aaxna')
        : 4;
    dispatch(fetchFilters(FILTERIDX, app));
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    const { brkty, tcomp, tmake } = app.filterState;
    const otherNotListedPath = tcomp === TRUCK && tmake === null;

    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }

    return (
      <div className="grid-container main-content">
        <h2>Choose the Hub by Axle Type or Bearing Number:</h2>
        {app.filterResults.map((result, index) => {
          if (
            (result.AftermarketAxleId === 10 ||
              result.AftermarketAxleId === 11) &&
            brkty === DRUM
          ) {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { aaxna: result.Id || result.AftermarketAxleId },
              app
            );
          }
          //Unitized
          else if (result.AftermarketAxleId === 82) {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { aaxna: result.Id || result.AftermarketAxleId },
              (app.NEXT_FILTER_PATH = NEXT_RESULT_PATH)
            );
          } //Navistar --> F08 Front -->Hub-mounting
          else if (result.AftermarketAxleId === 79) {           
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { aaxna: result.Id || result.AftermarketAxleId },
              (app.NEXT_FILTER_PATH = NEXT_PATH)
            );
          } //190 Drive -->Hub-mounting
          else if (result.AftermarketAxleId === 40) {
            var boundClick = setFilter.bind(
                this,
                FILTERIDX,
                { aaxna: result.Id || result.AftermarketAxleId },
                (app.NEXT_FILTER_PATH = NEXT_PATH)
            );
          } //L Drive -->Hub-mounting
          else if (result.AftermarketAxleId === 19) {
            var boundClick = setFilter.bind(
                this,
                FILTERIDX,
                { aaxna: result.Id || result.AftermarketAxleId },
                (app.NEXT_FILTER_PATH = NEXT_PATH)
            );
          } else if (otherNotListedPath) {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { aaxna: result.Id || result.AftermarketAxleId },
              (app.NEXT_FILTER_PATH = NEXT_PATH)
            );
          }else if (tcomp === 3) {           
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { aaxna: result.Id || result.AftermarketAxleId },
              (app.NEXT_FILTER_PATH = NEXT_PATH)
            );
          }                    
          else {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { aaxna: result.Id || result.AftermarketAxleId },
              (app.NEXT_FILTER_PATH = NEXT_GAWR_FILTER_PATH)
            );
          }
          var boundActive = setActive.bind(
            this,
            FILTERIDX,
            result.Id || result.AftermarketAxleId
          );
          return (
            <Result
              key={index}
              result={result}
              onClick={boundClick}
              active={boundActive}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AxelType);
