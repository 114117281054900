import React, { Component } from "react";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import HelpDetail from "../global/help-detail";

class MobileHelpDetail extends Component {
  handleClick(path) {
    const { dispatch } = this.props;
    dispatch(push(path));
  }

  render() {
    return (
      <div className="grid-container main-content">
        <HelpDetail></HelpDetail>
      </div>
    );
  }
}

export default connect()(MobileHelpDetail);
