import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';

let FILTERIDX;
//const NEXT_PATH = '/hub/wheel-stud-standout';
const NEXT_PATH = '/hub/barrel-length';


class Result extends Component {
  render() {
    var { result, active, onClick } = this.props;
    var className = 'general-button truck-make';
    return (
      <div className="grid-content small-6">
        <a href="#" className={active()} onClick={onClick}>
          {result.Name}
        </a>
      </div>
    );
  }
}

class HubMountingSystem extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'hamnt') !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'hamnt')
        : 10;

    dispatch(fetchFilters(FILTERIDX, app));
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    return (
      <div className="grid-container main-content">
        <h2>Hub Mounting System</h2>
        {/*<h2>Wheel Mounting System</h2>*/}
        <div className="grid-block">
          {app.filterResults.map((result, index) => {
            
            if ( result.Id === 4 ||result.Id === 5
            ){            
              var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { hamnt: result.Id   },
              (app.NEXT_FILTER_PATH = NEXT_PATH)
            );
            }
            else{
              var boundClick = setFilter.bind(
                this,
                FILTERIDX,
                { hamnt: result.Id },
                app
              );
            }
            var boundActive = setActive.bind(
              this,
              FILTERIDX,
              result.Id,
              'general-button truck-make'
            );
            return (
              <Result
                key={index}
                app={app}
                result={result}
                active={boundActive}
                onClick={boundClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(HubMountingSystem);

