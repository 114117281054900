import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {fetchHubRebuildKitsResult} from '../../actions';
import NoResults from '../global/no-result';
import {fetchRebuildKitDetails} from '../../actions/parts';
import classNames from 'classnames';
import {Link} from "react-router-dom";

class HubRebuildKitsDetail extends React.Component {
    constructor() {
        super();

        this.state = {
            rebuildKitActive: false,
        };
    }

    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber) {
            dispatch(fetchHubRebuildKitsResult(match.params.partNumber));
            dispatch(fetchRebuildKitDetails(match.params.partNumber));
        }
    }

    renderRebuildKit() {
        const { parts, results } = this.props;
        const presetRebuild = [];
        const hubRebuildDetail = results.partNumber;

            presetRebuild.push(
                <tr style={{ border: 'none' }}>
                    <td>
          <span
              className={classNames('rebuild-kit-toggle', { show: this.state.rebuildKitActive })}
              onClick={() => this.setState({ rebuildKitActive: !this.state.rebuildKitActive })}
          >
              {hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].Type}</span>
                    </td>
                    <td>
                        {hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].PartNumber}
                    </td>
                </tr>
            );

            presetRebuild.push(
                <tr style={{ paddingTop: 0 }}>
                    <td colSpan={2} style={{ padding: !this.state.rebuildKitActive ? 0 : '' }}>
                        {
                            parts.rebuildKitDetails && parts.rebuildKitDetails.length ? parts.rebuildKitDetails.map((item, index) => (
                                <table
                                    key={index}
                                    className={classNames('rebuild-kit-info', { 'show m-0 show1': this.state.rebuildKitActive })}
                                >
                                    <tr>
                                        <td width="70%">Inner Bearing</td>
                                        <td>{item.BearingSetNumberInboard}</td>
                                    </tr>
                                    <tr>
                                        <td width="70%">Outer Bearing</td>
                                        <td>{item.BearingSetNumberOutboard}</td>
                                    </tr>
                                    <tr>
                                        <td width="70%">Seal & Spacer</td>
                                        <td>{item.SealAndSpacerKitNumber}</td>
                                    </tr>
                                </table>
                            )) : (
                                <table
                                    className={classNames('rebuild-kit-info', { 'show m-0 show1': this.state.rebuildKitActive })}
                                >
                                    <tr>
                                        <td width="70%">Inner Bearing</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td width="70%">Outer Bearing</td>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <td width="70%">Seal & Spacer</td>
                                        <td>-</td>
                                    </tr>
                                </table>
                            )
                        }
                    </td>
                </tr>
            );

        return presetRebuild;
    }

    render() {
        const { results } = this.props;

        const hubRebuildDetail = results.partNumber;

        if (results.isFetching) {
            return <Waiting />;
        }

        if (hubRebuildDetail && hubRebuildDetail.length === 0) {
            return <NoResults />;
        }

        return (
            <div className="grid-container main-content result">
                <h2>Product Details</h2>
                <div className="meta">
                    <span>
                        <img
                            className="product-image"
                            src={hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].ImageURL ? hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].ImageURL + '.png' : 'https://cdn.intelligencebank.com/us/share/ONnl/Aa8M/73rBb/original/Image_Not_Available'}
                            alt="HubRebuildKitsDetail"
                            width="100"
                            height="100"
                        />
                    </span>
                    {hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].Type} {hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].Axle} {hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].AxlePostion}<br/>
                    <em>{hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].PartNumber}</em>
                </div>
                <div>
                    <div className="specs mb-3">
                        <h2>Product Specs</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Axle Position</td>
                                <td>{hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].AxlePostion ? hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].AxlePostion : '-'}</td>
                            </tr>
                            <tr>
                                <td width="70%">Axle</td>
                                <td>{hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].Axle ? hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].Axle : '-'}</td>
                            </tr>
                            {
                                (hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].PartNumber != 10037697) && (hubRebuildDetail && hubRebuildDetail[0] &&
                                hubRebuildDetail[0].PartNumber != 10037961) && (hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].PartNumber != 10081076) ?
                                this.renderRebuildKit() : ''
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(HubRebuildKitsDetail);
