import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import { fetchDrumResult } from '../../actions';
import NoResults from '../global/no-result';
import DrumResult from './drumResult';

		

class ReplacementDrumResult extends React.Component {
	componentWillMount() {
		const {dispatch, match} = this.props;
		if (match.params.drumId) {
			dispatch(fetchDrumResult(match.params.drumId));
		}

	}

	render() {
		const { results } = this.props;
		const drumResult = results.drumResult;

	
		if (results.isFetching) {
			return (<Waiting />)
		}

		if (drumResult.length === 0 && results.isZeroResults === true) {
			return (<NoResults />)
		}

        return (
            <DrumResult drumResult={drumResult} />
		)
	}
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(ReplacementDrumResult);
