import React, { Component } from "react";
import {connect} from "react-redux";
import {registerSession, updateSession} from "../../actions";
import {push} from "react-router-redux";
import SelectButton from "./SelectButton/index";
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class DowntimeCosts extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);
        const {session: {downtimeCosts}} = props;
        this.state = {
            downtimeCosts: downtimeCosts || ''
        };

    }

    handleChange(name, e){
        let change: Object = {};
        change[name] =  e.target.value;
        this.setState(change);
    }

    render() {
        const STATE_KEY = 'downtime-costs';
        const LOCATION = 'downtime-costs';
        const { actions: {updateSession}, session: {id, calculator} } = this.props;
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>Down Time Cost / Hour</h1>
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="example">
                        <tbody>
                        <tr>
                            <td colSpan="2" className="table-center">Example Costs per Hour:</td>
                        </tr>
                        <tr>
                            <td width="65%">Regional Haul (Dry Goods) </td>
                            <td width="35%">$75-$85 / hour </td>
                        </tr>
                        <tr>
                            <td width="65%">Long Haul (Dry Goods) </td>
                            <td width="35%">$100-$120 / hour </td>
                        </tr>
                        <tr>
                            <td width="65%">Long Haul (Refrigerated Goods) </td>
                            <td width="35%">$120-$180 / hour </td>
                        </tr>
                        <tr>
                            <td width="65%">Specialty Haul – Chemicals </td>
                            <td width="35%">$270+ / hour </td>
                        </tr>
                        <tr>
                            <td width="65%">Refuse Vehicle </td>
                            <td width="35%">$225+ per hour</td>
                        </tr>
                        </tbody>
                    </table>


                    <input
                        pattern="\d\.*"
                        type="number"
                        id="downTimeCosts"
                        className="form-control"
                        min={0}
                        placeholder="0"
                        onChange={this.handleChange.bind(this, 'downtimeCosts')}
                        value={this.state.downtimeCosts}/>

                    <SelectButton
                        handleClick={updateSession}
                        label="Calculate"
                        stateKey={STATE_KEY}
                        calculator={calculator}
                        location={LOCATION}
                        stateVals={this.state}
                        sessionId={id}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DowntimeCosts);

