import React, { Component } from 'react';
import {connect} from 'react-redux';
import {registerSession, updateSession} from '../../actions';
import {push} from 'react-router-redux';
import SelectButton from './SelectButton/index';
import Total from './total';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class ReplacementCosts extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);
        const {session: {replacementHubCostV, spindleNutCost}} = props;
        this.state = {
            replacementHubCostV: replacementHubCostV || '',
            spindleNutCost: spindleNutCost || ''
        };

    }

    handleChange(name, e){
        let change: Object = {};
        change[name] =  e.target.value;
        this.setState(change);
    }

    render() {
        const STATE_KEY = 'replacementCosts';
        const LOCATION = 'replacement-costs';
        const { actions: {updateSession}, session: {id, calculator, chooseCalculator} } = this.props;
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>{chooseCalculator ? 'PreSet' : 'Replacement Costs'}</h1>
                    <h2>{chooseCalculator ? 'Hub Assembly Cost' : 'Hub Cost'}</h2>
                    <input
                        name="replacementHubCostV"
                        ref="replacementHubCostV"
                        placeholder="0.00"
                        pattern="\d\.*"
                        type="number"
                        required value={this.state.replacementHubCostV}
                        onChange={this.handleChange.bind(this, 'replacementHubCostV')}/>

                    <h2 style={{marginBottom: 0}}>ConMet preset plus spindle nut cost</h2>
                    <p className="ffc">*Only applicable if replacing with valid ConMet AM PreSet assemblies</p>
                    <input
                        name="spindleNutCost"
                        ref="spindleNutCost"
                        placeholder="0.00"
                        pattern="\d\.*"
                        type="number"
                        required value={this.state.spindleNutCost}
                        onChange={this.handleChange.bind(this, 'spindleNutCost')}/>


                    <h2>Total Cost</h2>
                    <Total sum={[this.state.replacementHubCostV, this.state.spindleNutCost]} multiplier={1} />

                    <SelectButton
                        handleClick={updateSession}
                        label="Next"
                        calculator={calculator}
                        stateKey={STATE_KEY}
                        location={LOCATION}
                        sessionId={id}
                        stateVals={this.state} />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementCosts);

