import React from 'react';
import {connect} from 'react-redux';

class VolvoExclusive extends React.Component {
    render() {
        return (
            <div className="grid-container main-content">
            <h2>The entered hub is exclusive to Volvo.</h2>
            <div className="not-found">
                <h2 style={{fontSize: '24px',textTransform: 'none'}}>
                    Please contact Volvo for service enquiries.</h2>
            </div>
        </div>
        )
    }
}

export default connect()(VolvoExclusive);