import React, { Component } from 'react';
import {fetchCategories} from '../actions/categories';
import {
	fetchCastingFilterCategories,fetchRotorCastingFilterCategories
  } from "../actions";
import {connect} from 'react-redux'
import HubSelectionWrapper from '../components/hub'
import HubSelectionStart from '../components/hub/start'
import Navigation from '../components/general/navigation'
import Footer from '../components/general/footer'
import {Route} from 'react-router-dom';
import PartsWrapper from '../components/part';
import About from '../components/general/about';
import Contact from '../components/general/contact';
import Disclaimer from '../components/general/disclaimer';
import Notification from '../components/general/notification';
import MobileHelpDetail from '../components/general/mobile-help.js';
import ReplaceWrapper from '../components/replace';

class App extends Component {
	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(fetchCategories())
		dispatch(fetchCastingFilterCategories())
		dispatch(fetchRotorCastingFilterCategories());
		
	}
	render() {
		return (
			<div className="grid-frame vertical">
			<Navigation />
				<div className="grid-content">
					<Route path="/" component={HubSelectionWrapper} />
					<Route exact path="/hub" component={HubSelectionStart}/>
					<Route path="/parts" component={PartsWrapper} />
					<Route path="/about" component={About} />
					<Route path="/contact" component={Contact} />
					<Route path="/disclaimer" component={Disclaimer} />
					<Route path="/notification" component={Notification} />
					<Route path="/mobile-help" component={MobileHelpDetail} />
					<Route path="/replace" component={ReplaceWrapper} />
				<Footer />
				</div>
			</div>
			)
	}
};


export default connect()(App)
