import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {fetchAbsToneRingResult} from '../../actions';
import NoResults from '../global/no-result';
import {Link} from "react-router-dom";

class AbsToneRingDetail extends React.Component {
    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber) {
            dispatch(fetchAbsToneRingResult(match.params.partNumber));
        }
    }

    render() {
        const { results } = this.props;
        const atrDetail = results.partNumber;

        if (results.isFetching) {
            return <Waiting />;
        }

        if (atrDetail && atrDetail.length === 0) {
            return <NoResults />;
        }

        return (
            <div className="grid-container main-content result">
                <h2>Product Details</h2>
                <div className="meta">
                    <span>
                        <img
                            className="product-image"
                            src={atrDetail && atrDetail[0] && atrDetail[0].ImageURL ? atrDetail && atrDetail[0] && atrDetail[0].ImageURL + '.png' : 'https://cdn.intelligencebank.com/us/share/ONnl/Aa8M/73rBb/original/Image_Not_Available'}
                            alt="AbsToneRing"
                            width="100"
                            height="100"
                        />
                    </span>
                    {atrDetail && atrDetail[0] && atrDetail[0].Type}<br/>
                    <em>{atrDetail && atrDetail[0] && atrDetail[0].ABSToneRingKitNumber}</em>
                </div>
                <div>
                    <div className="specs mb-3">
                        <h2>Product Specs</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Product Type</td>
                                <td>{atrDetail && atrDetail[0] && atrDetail[0].Type ? atrDetail && atrDetail[0] && atrDetail[0].Type : '-'}</td>
                            </tr>
                            <tr>
                                <td>Abs Type</td>
                                <td>{atrDetail && atrDetail[0] && atrDetail[0].AbsType ? atrDetail && atrDetail[0] && atrDetail[0].AbsType : '-'}</td>
                            </tr>
                            <tr>
                                <td>Reference Dimensions</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Id(IN.)</td>
                                <td>Ø {atrDetail && atrDetail[0] && atrDetail[0].InnerDiameterInch ? atrDetail && atrDetail[0] && atrDetail[0].InnerDiameterInch.toFixed(2) : '-'}</td>
                            </tr>
                            <tr>
                                <td>Od(IN.)</td>
                                <td>Ø {atrDetail && atrDetail[0] && atrDetail[0].OuterDiameterInch ? atrDetail && atrDetail[0] && atrDetail[0].OuterDiameterInch.toFixed(2) : '-'}</td>
                            </tr>
                            <tr>
                                <td>Width(IN.)</td>
                                <td>{atrDetail && atrDetail[0] && atrDetail[0].ThicknessInch ? atrDetail && atrDetail[0] && atrDetail[0].ThicknessInch.toFixed(2) : '-'}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AbsToneRingDetail);
