import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Route} from 'react-router-dom';
import ChooseCalculator from './choose-calculator';
import RebuildCosts from './rebuild-costs';
import ReplacementCosts from './replacement-costs';
import LaborCosts from './labor-costs';
import CostComparison from './cost-comparison';
import MarginParts from './margin-parts';
import DownTimeCost from './downtime-costs';
import Results from './results';
import Email from './email';
import Confirmation from './confirmation';
import NotFound from './not-found';

class Replace extends Component {
    render() {
        return (
            <div className="grid-block vertical align-center">
                <div className="grid-content">
                    <div className="grid-container main-content">
                        <Route path="/replace/choose-calculator" component={ChooseCalculator} />
                        <Route path="/replace/rebuild-costs" component={RebuildCosts} />
                        <Route path="/replace/replacement-costs" component={ReplacementCosts} />
                        <Route path="/replace/labor-costs" component={LaborCosts} />
                        <Route path="/replace/cost-comparison" component={CostComparison} />
                        <Route path="/replace/margin-parts" component={MarginParts} />
                        <Route path="/replace/downtime-costs" component={DownTimeCost} />
                        <Route path="/replace/results" component={Results} />
                        <Route path="/replace/email" component={Email} />
                        <Route path="/replace/confirmation" component={Confirmation} />
                        <Route path="/404" component={NotFound} />
                    </div>
                </div>
            </div>
        );
    }
}
export default connect()(Replace);
export {
    actionCreators
} from '../../actions';
