import React, { Component } from "react";
import {connect} from "react-redux";
import {registerSession, updateSession} from "../../actions";
import {push} from "react-router-redux";
import SelectButton from "./SelectButton/index";
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class LaborCosts extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);
        const { session: {
            calculator,
            billableLaborRate,
            laborRate,
            rebuildHours,
            chooseCalculator,
            replaceHours
        }} = props;
        this.state = {
            billableLaborRate: billableLaborRate || '',
            laborRate: laborRate || '',
            rebuildHours: rebuildHours || (chooseCalculator ? 2 : 3),
            replaceHours: replaceHours || 1
        };
        if (calculator === 'replace') {
            this.state.billableLaborRate = 0;
        }
    }

    handleChange(name, e) {
        let change: Object = {};
        change[name] =  e.target.value;
        this.setState(change);
    }

    render() {
        const STATE_KEY = 'laborCosts';
        const LOCATION = 'labor-costs'; //'margin-parts';
        const { actions: {updateSession}, session: {id, calculator, chooseCalculator} } = this.props;
        let billableFields = '';
        if (calculator !== 'replace') {
            billableFields = (
                <div>
                    <h2>Billable Labor Rate / Hour</h2>
                    <input
                        pattern="\d\.*"
                        type="number"
                        name="billableLaborRate"
                        ref="billableLaborRate"
                        placeholder="0.00"
                        required value={this.state.billableLaborRate}
                        onChange={this.handleChange.bind(this, 'billableLaborRate')}/>
                </div>
            )
        }
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>Labor Costs</h1>
                    <h2>Labor Costs / Hour</h2>
                    <input
                        pattern="\d\.*"
                        type="number"
                        name="laborRate"
                        ref="laborRate"
                        placeholder="0.00"
                        required value={this.state.laborRate}
                        onChange={this.handleChange.bind(this, 'laborRate')}
                    />
                    {billableFields}
                    <h2 className="withSubhead">{chooseCalculator ? 'Number of hours to assemble and install Conventional Hub' : 'Number of Hours to Rebuild'}</h2>
                    <p className="note"><small>{chooseCalculator ? 'Average time is 2 hours' : 'Average time to rebuild is 3 hours'}</small></p>
                    <input
                        pattern="\d\.*"
                        type="number"
                        step="any"
                        className="form-control"
                        id="rebuildHours"
                        placeholder="Enter time to rebuild (hrs.)"
                        onChange={this.handleChange.bind(this, 'rebuildHours')}
                        value={this.state.rebuildHours}
                    />
                    <h2 className="withSubhead">{chooseCalculator ? 'Number of Hours to install PreSet Hub' : 'Number of Hours to Replace'}</h2>
                    <p className="note"><small>{chooseCalculator ? 'Average time is 1 hour' : 'Average time to replace is 1 hour'}</small></p>
                    <input
                        pattern="\d\.*"
                        type="number"
                        step="any"
                        id="replaceHours"
                        className="form-control"
                        placeholder="Enter time to replace (hrs.)"
                        onChange={this.handleChange.bind(this, 'replaceHours')}
                        value={this.state.replaceHours}
                    />
                    <SelectButton
                        handleClick={updateSession}
                        label="Next"
                        calculator={calculator}
                        stateKey={STATE_KEY}
                        location={LOCATION}
                        stateVals={this.state}
                        sessionId={id}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaborCosts);

