import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';
import _ from 'lodash';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class CostComparison extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);
    }

    calculateReplace() {
        const {session: {replacementHubCostV, spindleNutCost, laborRate, replaceHours}} = this.props;
        let cost =  _.toNumber(replacementHubCostV + spindleNutCost);
        cost = cost + (_.toNumber(laborRate) * _.toNumber(replaceHours));
        return '$'+cost.toFixed(2);
    }
    calculateRebuild() {
        const {session: {bearingSetCost, wheelSealCost, manualSpindleNutCost, laborRate, rebuildHours}}= this.props;
        let cost = _.toNumber(bearingSetCost + wheelSealCost + manualSpindleNutCost);
        cost = cost + (_.toNumber(laborRate) * _.toNumber(rebuildHours));
        return '$'+cost.toFixed(2);
    }
    multiply(x: number = 0, y: number = 0){
        return (x * y).toFixed(2);
    }
    handleSubmit() {
        const { actions: {updateSession}, session: {id, calculator} } = this.props;
        // store all calculated values here.
        //
        const {session: {
            partsMargin,
            billableLaborRate,
            laborRate,
            replaceHours,
            rebuildHours,
            replacementHubCostV,
            spindleNutCost,
            bearingSetCost,
            manualSpindleNutCost,
            wheelSealCost
        }
        } = this.props;
        let replacePartsProfit = (_.toNumber(partsMargin)/100) * _.toNumber(replacementHubCostV + spindleNutCost);
        let replaceLaborCost = this.multiply(_.toNumber(laborRate), _.toNumber(replaceHours));
        let rebuildBillCost = this.multiply(_.toNumber(billableLaborRate), _.toNumber(rebuildHours));
        let replaceLaborMargin = (rebuildBillCost - replaceLaborCost).toFixed(2);
        const replacementMarginValue = (replacePartsProfit + _.toNumber(replaceLaborMargin));

        let rebuildLaborCost = this.multiply(_.toNumber(laborRate), _.toNumber(rebuildHours));
        let rebuildPartsMargin = (rebuildBillCost - rebuildLaborCost).toFixed(2);
        let rebuildPartsCost = _.toNumber(bearingSetCost) + _.toNumber(wheelSealCost) + _.toNumber(manualSpindleNutCost);
        let rebuildPartsProfit = (_.toNumber(rebuildPartsMargin)/100) * rebuildPartsCost;
        let rebuildLaborProfit = (_.toNumber(billableLaborRate) - _.toNumber(laborRate)) * _.toNumber(rebuildHours);
        const rebuildMarginValue = ( rebuildPartsProfit + rebuildLaborProfit);
        updateSession(id, {lastStep: 'costComparison', replacementMarginValue, rebuildMarginValue}, 'cost-comparison', calculator)
    }

    render() {
        const {session: {calculator}} = this.props;
        const buttonText = (calculator !== 'replace') ? 'Calculate the Margins' : 'View Benefits';
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>Total Costs Compared</h1>
                    <div className="highlight">
                        <h3>Total Cost to REPLACE</h3>
                        <h3>{this.calculateReplace()}</h3>
                    </div>
                    <div className="highlight">
                        <h3>Total Cost to REBUILD</h3>
                        <h3>{this.calculateRebuild()}</h3>
                    </div>


                    <a className="general-button"
                       onClick={this.handleSubmit.bind(this)}>
                        {buttonText}</a>

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CostComparison);
