import React, { Component } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';
import _ from 'lodash';
import 'react-super-responsive-table/src/SuperResponsiveTableStyle.css'

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class Results extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            replacementMarginValue: 0,
            rebuildMarginValue: 0
        }
    }

    multiply(x: number = 0, y: number = 0){
        return (x * y).toFixed(2);
    }
    handleSubmit(rebuildMarginValue, replacementMarginValue, customerSavings) {
        const { actions: {updateSession}, session: {id, calculator} } = this.props;
        updateSession(id, {lastStep: 'results', replacementMarginValue, rebuildMarginValue, customerSavings}, 'results', calculator)
    }

    calculateSavings(rebuild, replace) {
        const savings = _.toNumber(rebuild) - _.toNumber(replace);
        return (savings > 0) ? savings : 0;
    }


    render() {
        const {session: {
            calculator,
            partsMargin,
            billableLaborRate,
            laborRate,
            replaceHours,
            rebuildHours,
            replacementHubCostV,
            spindleNutCost,
            bearingSetCost,
            manualSpindleNutCost,
            wheelSealCost,
            hubCost,
            downtimeCosts,
            chooseCalculator,
        }
        } = this.props;
        let replacePartsProfit = (_.toNumber(partsMargin)/100) * (_.toNumber(replacementHubCostV) + _.toNumber(spindleNutCost));
        let replaceLaborCost = this.multiply(_.toNumber(laborRate), _.toNumber(replaceHours));
        let replaceBillCost = this.multiply(_.toNumber(billableLaborRate), _.toNumber(replaceHours));
        let replaceLaborMargin = (_.toNumber(replaceBillCost) - _.toNumber(replaceLaborCost)).toFixed(2);
        const replacementMarginValue = (replacePartsProfit + _.toNumber(replaceLaborMargin));
        let replaceLabor = (replaceBillCost > 0) ? replaceBillCost: replaceLaborCost;
        const replaceCost = _.toNumber(replaceLabor) + (_.toNumber(replacementHubCostV) + _.toNumber(spindleNutCost)) + _.toNumber(chooseCalculator ? 0 : replacePartsProfit);
        const replaceDowntimeCost  = this.multiply(_.toNumber(downtimeCosts), _.toNumber(replaceHours));
        const replaceTotal = _.toNumber(replaceCost) + _.toNumber(replaceDowntimeCost);

        let rebuildLaborCost = this.multiply(_.toNumber(laborRate), _.toNumber(rebuildHours));
        let rebuildPartsCost = _.toNumber(chooseCalculator ? hubCost : 0) + _.toNumber(bearingSetCost)+_.toNumber(wheelSealCost)+_.toNumber(manualSpindleNutCost);
        let rebuildBillCost = this.multiply(_.toNumber(billableLaborRate), _.toNumber(rebuildHours));
        let rebuildPartsProfit = (_.toNumber(partsMargin)/100) * rebuildPartsCost;
        let rebuildLaborProfit = (_.toNumber(rebuildBillCost) - _.toNumber(rebuildLaborCost));

        let rebuildMarginValue = ( _.toNumber(rebuildPartsProfit) + _.toNumber(rebuildLaborProfit));
        let laborMarkupPercent = _.toNumber((laborRate/billableLaborRate)*100).toFixed(2);
        let rebuildLabor = (rebuildBillCost > 0) ? rebuildBillCost: rebuildLaborCost;

        const rebuildCost = _.toNumber(rebuildLabor) + _.toNumber(rebuildPartsCost) + _.toNumber(chooseCalculator ? 0 : rebuildPartsProfit);
        const rebuildDowntimeCost  = this.multiply(_.toNumber(downtimeCosts), _.toNumber(rebuildHours));
        const rebuildTotal = _.toNumber(rebuildCost) + _.toNumber(rebuildDowntimeCost);
        const savings = this.calculateSavings(rebuildTotal,replaceTotal).toFixed(2);

        let partsMarginDisplay, laborMarginDisplay = null;
        if (calculator !== 'replace') {
            partsMarginDisplay = (
                <tr className="detail">
                    <td><strong>Parts Margin ({partsMargin}%)</strong></td>
                    <td>${rebuildPartsProfit.toFixed(2)}</td>
                    <td>${replacePartsProfit.toFixed(2)}</td>
                </tr>
            )
            laborMarginDisplay = (
                <tr className="detail">
                    <td><strong>Labor Margin</strong></td>
                    <td>${rebuildLaborProfit} ({laborMarkupPercent}%)</td>
                    <td>${replaceLaborMargin} ({laborMarkupPercent}%)</td>
                </tr>
            )
        }
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>Results</h1>
                    <div className="grid-block" id="results">
                        <div className="margin-holder grid-content">
                            <table>
                                <thead>
                                <tr>
                                    <th/>
                                    <th style={{ width: '10px' }}>{chooseCalculator ? 'Conventional' : 'Rebuild'}</th>
                                    <th style={{ width: '10px' }}>{chooseCalculator ? 'PreSet' : 'Replace'}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr className="large">
                                    <td>Total</td>
                                    <td>${rebuildTotal.toFixed(2)}</td>
                                    <td>${replaceTotal.toFixed(2)}</td>
                                </tr>
                                <tr className="detail">
                                    <td><strong>Parts</strong></td>
                                    <td>${rebuildPartsCost.toFixed(2)}</td>
                                    <td>${(_.toNumber(replacementHubCostV) + _.toNumber(spindleNutCost)).toFixed(2)}</td>
                                </tr>
                                <tr className="detail">
                                    <td><strong>Labor</strong></td>
                                    <td>${rebuildLaborCost} ({rebuildHours} hrs)</td>
                                    <td>${replaceLaborCost} ({replaceHours} hrs)</td>
                                </tr>
                                <tr className="detail">
                                    <td><strong>Downtime</strong></td>
                                    <td>${rebuildDowntimeCost} ({rebuildHours} hrs)</td>
                                    <td>${replaceDowntimeCost} ({replaceHours} hrs)</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="grid-block">
                        <div className="margin-holder grid-content">
                            <h2>${savings} Savings {chooseCalculator ? 'with PreSet' : 'to Replace'}</h2>
                        </div>
                        <div className="margin-holder grid-content">
                            <h1>Benefits of {chooseCalculator ? 'PreSet' : 'Replacing'}</h1>
                            <div className="benefits">
                                Maximize Uptime<br/>
                                <span className="plus">+</span><br />
                                Extended Warranty<br />
                                <span className="plus">+</span><br />
                                Simplified Process<br />
                                {/*<span className="plus">+</span><br />*/}
                                {/*Liability Protection<br />*/}
                            </div>
                        </div>
                    </div>
                    <a className="general-button"
                       onClick={this.handleSubmit.bind(this, rebuildMarginValue, replacementMarginValue, savings)}>
                        Next</a>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);

