import React from "react";
import { Link } from "react-router-dom";

class RotorCasting extends React.Component {
  render() {
    return (
      <div className="grid-container main-content replacement-drum">
        
        <div className="conmet-button">
          <Link to="/hub/casting/filter" className="yes-no-button">
            <em>No</em>Proceed without the number
          </Link>
        </div>
      </div>
    );
  }
}

export default RotorCasting;
