import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import { persistState } from 'redux-devtools';
import rootReducer from '../reducers';
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory()

let middleware = [ thunk ,routerMiddleware(history)]

const finalCreateStore = compose(
    // Middleware you want to use in development:
    applyMiddleware(...middleware),
    // Required! Enable Redux DevTools with the monitors you chose
    // DevTools.instrument(),
    typeof window === 'object' && typeof window.devToolsExtension !== 'undefined' ? window.devToolsExtension() : f => f,
    // Optional. Lets you write ?debug_session=<key> in address bar to persist debug sessions
    persistState(getDebugSessionKey())
)(createStore);

function getDebugSessionKey() {
    // You can write custom logic here!
    // By default we try to read the key from ?debug_session=<key> in the address bar
    const matches = window.location.href.match(/[?&]debug_session=([^&]+)\b/);
    return (matches && matches.length > 0)? matches[1] : null;
}

export default function configureStore(initialState) {
    const store = finalCreateStore(rootReducer(history), initialState);

    // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
    if (module.hot) {
        module.hot.accept('../reducers', () =>
            store.replaceReducer(require('../reducers')/*.default if you use Babel 6+ */)
        );
    }

    return store;
}

