import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { IMAGE_CDN } from "../../config/constants";
import { appInsights } from '../../AppInsights';
import RotorDiscontinued from '../global/rotor-discontinued';

class RotorResult extends React.Component {
  constructor(props) {
    super(props);
    this.prevData = this.prevData.bind(this);
    this.nextData = this.nextData.bind(this);

    this.state = {
      selectedIndex: 0,
      resultsLength: 0,
    };
  }

  nextData() {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex <= this.state.resultsLength) {
      this.setState({ selectedIndex: selectedIndex + 1 });
    }
  }

  prevData() {
    const selectedIndex = this.state.selectedIndex;
    if (selectedIndex > 0) {
      this.setState({ selectedIndex: selectedIndex - 1 });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { rotorResult } = nextProps;
    this.setState({ resultsLength: rotorResult ? rotorResult.length : 0 });
  }

  render() {
    const { rotorResult } = this.props;
    const FF = [10083923, 10041216, 10041215, 10086915, 10087663];
    const R = [10083924, 10041218, 10041217, 10086914, 10087664];
    appInsights.trackEvent({ name: 'Brake Rotor Number:', properties: { rotorResult } })

    let note1 = false;
    if (rotorResult.length > 0 && rotorResult.length === 2) {
      if (rotorResult[0].BrakeRotorNumber !== '10009970') {
        return (
          <div className="grid-container main-content" id="hubAssemblyResult">
            <h2>Success! The following rotor is recommended</h2>
            <div className="grid-content flex-row">
              {rotorResult.map((item, index) => {
                return (
                  <div className="small-6" key={index}>
                    <p className="brake-type">{item.BrakeRotorType}</p>
                    <div className="number">{item.BrakeRotorNumber}</div>
                    <img
                      className="product-image"
                      src={require("../../images/blank1x1.png")}
                      style={{
                        background:
                          "url("+ item.Images[0].ImageUrl +") center center no-repeat",
                      }}
                      alt={item.HubAssemblyNumber}
                    />
                    {FF.includes(parseInt(item.BrakeRotorKitNumber)) ? (
                      <div className="number">For FF Steer Axle</div>
                    ) : null}
                    {R.includes(parseInt(item.BrakeRotorKitNumber)) ? (
                      <div className="number">For R Drive Axle</div>
                    ) : null}
                    <div className="weight">
                      Brake Rotor Kit Number: {item.BrakeRotorKitNumber}
                    </div>
                    <p>{item.BrakeRotorComponents}</p>
                    {item.BrakeRotorType &&
                      item.BrakeRotorType.toLowerCase() === "u-section" &&
                      item.BrakeRotorNumber !== "10020109" && (
                        <div className="optional-spindle note">
                          Note: This rotor is not compatible with aluminum hubs.
                        </div>
                      )}
                    {note1 = item.BrakeRotorNumber === "10087485" || item.BrakeRotorNumber === "10087486" ?  true : false}
                    <Link
                      to={
                        "/hub/replacement-rotor/rotordetails/" + item.BrakeRotorNumber + "/" + item.BrakeRotorKitNumber
                      }
                      className="general-button"
                    >
                      See Details
                  </Link>
                  </div>
                );
              })}
              {note1 === true ? (
                  <div className="optional-spindle-note note-center">
                    Note: If replacing a rotor with a circular flange OD, remove existing rotor studs and replace with the studs included in this kit (10086580).
                  </div>
              ) : ''}
              <div className="clearfix" />
            </div>
            <div className="disclaimer">
              <Link to="/disclaimer">ConMet Wheel End Disclaimer</Link>
            </div>
          </div>
        );
      } else {
        return (<RotorDiscontinued selectedRotorNumber={'10009970'} />)
      }

    } else if (rotorResult.length > 0) {
      const data = rotorResult[this.state.selectedIndex];
      if (rotorResult[0].BrakeRotorNumber !== '10009970') {
        return (
          <div>
            <h2>Success! The following rotor is recommended</h2>
            <div className="result">
              <div
                className={classNames("prev-button", {
                  "hide-button":
                    rotorResult.length <= 1 || this.state.selectedIndex === 0,
                })}
                onClick={this.prevData}
              >
                <i className="icon-angle-left" />
              </div>
              {
                <div className="details">
                  {data.Images.map((image, index) => {
                    return (
                      <img
                        className="product-image"
                        // src={IMAGE_CDN + image.ImageGuid + '.png'}
                        src={image.ImageUrl + ".png"}
                        key={index}
                        alt={data.BrakeRotorNumber}
                        width="200"
                        height="200"
                      />
                    );
                  })}
                  <h2>
                    <div>Rotor Replacement Kit:</div>
                    <div>{data.BrakeRotorKitNumber}</div>
                  </h2>
                  <div className="optional-spindle">
                    Includes Rotor {data.BrakeRotorNumber} {data.BrakeRotorNumber == '10119183' ? '(Coated version of rotor 10083733)' : (data.BrakeRotorNumber == '10119309') ? '(Coated version of rotor 10084701)' : ''}
                  </div>
                  <p>{data.BrakeRotorComponents}</p>
                  {data.BrakeRotorType &&
                    data.BrakeRotorType.toLowerCase() === "u-section" &&
                    data.BrakeRotorNumber !== "10020109" && (
                      <div className="optional-spindle note">
                        Note: This rotor is not compatible with aluminum hubs.
                      </div>
                    )}
                  {data.BrakeRotorKitNumber === "10087663" && (
                      <div className="optional-spindle note">
                        Note: Replaces kit 10041216 (rotor 10034621)
                      </div>
                  )}
                  {data.BrakeRotorKitNumber === "10087664" && (
                      <div className="optional-spindle note">
                        Note: Replaces kit 10041218 (rotor 10034621)
                      </div>
                  )}
                  <Link
                    to={
                      "/hub/replacement-rotor/rotordetails/" + data.BrakeRotorNumber + "/" + data.BrakeRotorKitNumber
                    }
                    className="general-button"
                  >
                    See Details
                </Link>
                  <div className="text-center disclaimer">
                    <Link to="/disclaimer">ConMet Wheel End Disclaimer</Link>
                  </div>
                </div>
              }
              <div
                className={classNames("next-button", {
                  "hide-button":
                    rotorResult.length <= 1 ||
                    rotorResult.length - 1 === this.state.selectedIndex,
                })}
                onClick={this.nextData}
              >
                <i className="icon-angle-right" />
              </div>
            </div>
          </div>
        );
      }
      else {
        return (<RotorDiscontinued selectedRotorNumber={'10009970'} />)
      }
    } else {
      return <div />;
    }
  }
}
export default connect()(RotorResult);
