import React, { Component } from "react";
import {connect} from "react-redux";
import {registerSession, updateSession} from "../../actions";
import {push} from "react-router-redux";
import SelectButton from "./SelectButton/index";
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class MarginParts extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);
        const {session: {partsMargin}} = props;
        this.state = {
            partsMargin: partsMargin || ''
        };

    }
    handleChange(name, e){
        let change: Object = {};
        change[name] =  e.target.value;
        this.setState(change);
    }

    render() {
        const STATE_KEY = 'marginParts';
        const LOCATION = 'margin-parts';
        const { actions: {updateSession}, session: {id, calculator} } = this.props;
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>Parts Markup Percentage</h1>

                    <input
                        pattern="\d\.*"
                        type="number"
                        id="replaceHours"
                        className="form-control"
                        min={0}
                        placeholder="20"
                        onChange={this.handleChange.bind(this, 'partsMargin')}
                        value={this.state.partsMargin}/>

                    <SelectButton
                        handleClick={updateSession}
                        label="Calculate"
                        stateKey={STATE_KEY}
                        calculator={calculator}
                        location={LOCATION}
                        stateVals={this.state}
                        sessionId={id}/>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarginParts);
