import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class RotorDiscontinued extends Component {
  render() {
    const { selectedRotorNumber } = this.props;
    return (
      <div>
        <h2>Sorry, the {selectedRotorNumber} rotor has been discontinued. </h2>
        <div className="not-found text-center" style={{ fontSize: "1.2rem" }}>
          A new hub/rotor assembly will be required.
        </div>
        <div>
            {selectedRotorNumber === '10009970' ? (
              <a href="https://conmet.com/support/" target="_blank" className="general-button" rel="noopener noreferrer">Contact customer service</a>
            ) : (
               <Link to="/hub/truck-type" className="general-button">
                 Search for Replacement Hub/Rotor
               </Link>
            )}
        </div>
      </div>
    );
  }
}

export default connect()(RotorDiscontinued);
