import React, { Component } from 'react';
export default class Contact extends Component {
	render() {
		return (
			<h2 className="text-center">
				Contact
			</h2>
		)
	}
};
