import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class NotFound extends Component {
    render() {
        return (
            <div className="grid-block align-center not-found">

                <div className="grid-container">
                    {/*<div id="logo"><img src={require('../../images/logo.svg')} width="200px" alt="ConMet"/></div>*/}

                    <div className="grid-content title">

                        <h1 className="text-center">404<br />Page Not Found</h1>

                        <div className="disclaimer">
                            <p>We were unable to find the page you are looking for.</p>

                            {/*<Link to="/" className="general-button">Home</Link>*/}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}