import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';

let FILTERIDX;
const NEXT_FILTER_PATH = '/hub/spindle-nut';
const STEEL = 1;
const ALUMINUM = 2;

class Result extends Component {
  render() {
    const { active, onClick, result } = this.props;
    return (
      <div className={active()}>
        <button className="yes-no-button" onClick={onClick}>
          <strong>{result.WheelMaterial}</strong>
          <br />({result.StudLengthClass})
        </button>
      </div>
    );
  }
}

class WheelType extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'wmslc') !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'wmslc')
        : 13;
    dispatch(fetchFilters(FILTERIDX, app));
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    return (
      <div className="grid-container main-content">
        <h2>
          Choose the Wheel Type
          <br />
          (Determine Wheel Stud Length):
        </h2>
        {app.filterResults.map((result, index) => {
          var boundClick = setFilter.bind(
            this,
            FILTERIDX,
            { wmslc: result.Id },
              (app.NEXT_FILTER_PATH = NEXT_FILTER_PATH)
          );
          var boundActive = setActive.bind(this, FILTERIDX, result.Id);
          return (
            <Result
              key={index}
              result={result}
              onClick={boundClick}
              active={boundActive}
            />
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(WheelType);
