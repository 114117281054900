import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Route} from 'react-router-dom';
import PartsChoosePath from '../part/choose-path';
import PartsSearch from '../part/search';
import PartsResults from '../part/results';
import PartsHelp from '../part/help';
import PartsHelpDetail from '../part/help-detail';

class Parts extends Component {
  render() {
    return (
      <div className="grid-block vertical align-center">
        <div className="grid-content">
          <div className="grid-container main-content abxc">
              <Route path="/parts/choose-path" component={PartsChoosePath} />
              <Route exact path="/parts/search" component={PartsSearch} />
              <Route path="/parts/search/:id" component={PartsResults} />
              <Route path="/parts/help" component={PartsHelp} />
              <Route path="/parts/help/detail" component={PartsHelpDetail} />
          </div>
        </div>
      </div>
    );
  }
}

// Which props do we want to inject, given the global state?
// Note: use https://github.com/faassen/reselect for better performance.
function select(state) {
  return {
    app: state.app,
    parts: state.parts,
    images: state.images,
    results: state.results,
  };
}
export default connect(select)(Parts);
