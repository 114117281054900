import React, { Component } from "react";
import {connect} from "react-redux";
import SelectButton from "./SelectButton/index";
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class ChooseCalculator extends Component {
    constructor(props, context) {
        super(props, context);
    }

    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.props.actions.registerSession('guest', 'replace');
    }

    render() {
        const STATE_KEY = 'chooseCalculator';
        const LOCATION = 'choose-calculator';
        const {actions: {updateSession}, session: {id, calculator}} = this.props;
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h2>What would you like to compare?</h2>
                    <img src={require('../../images/calculator01.png')} alt="Cost to Replace vs. Rebuild hub?" />
                    <SelectButton
                        handleClick={updateSession}
                        type="submit"
                        className="button general-button"
                        calculator={calculator}
                        sessionId={id}
                        label="Replace vs. Rebuild"
                        stateVals={{ [STATE_KEY]: 0  }}
                        stateKey={STATE_KEY}
                        location={LOCATION}
                    />
                    <img src={require('../../images/calculator02.png')} alt="Cost of PreSet vs. Conventional" />
                    <SelectButton
                        type="submit"
                        className="button general-button"
                        handleClick={updateSession}
                        calculator={calculator}
                        sessionId={id}
                        label="PreSet vs. Conventional"
                        stateVals={{ [STATE_KEY]: 1  }}
                        stateKey={STATE_KEY}
                        location={LOCATION}
                    />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseCalculator);