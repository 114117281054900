import React, { Component} from 'react';
import {
    fetchHubAssemblyFiltersWithNoResults, fetchHubAssemblyNumber, fetchHubsSpindleNut,
} from '../../actions';

import {connect} from 'react-redux';
import Spinner from '../global/spinner';
import HubSingleResult from './details/result';


class NotAvailableHub extends Component {

  constructor(props) {
    super(props);
    this.state = {
      short_studs: null,
      results: [],
      isFetching: false,
      spindleNutFound: false,
      spindleNut: '',
    };
  }

    componentDidMount() {
        const { results, dispatch, match } = this.props;
        if(!results.filters.Results){
            dispatch(fetchHubAssemblyNumber(match.params.id)).then(() => {
                let chaid = this.props.results.assemblyNumber.Results[0].CompetitorHubAssemblyNumberId;
                dispatch(fetchHubAssemblyFiltersWithNoResults(chaid));
            });
        }
        if(results.filters.Results && results.filters.Results.length){
            this.setState({results: results.filters.Results});
        }
        this.setSpindleNut(results, dispatch);
    }
    componentWillReceiveProps(newProps) {
        const { results, dispatch } = newProps;
        if(results.filters.Results && results.filters.Results.length){
            this.setState({results: results.filters.Results});
        }
        this.setSpindleNut(results, dispatch);
    }

    setSpindleNut(results, dispatch){
        if(Object.keys(results.filters).length && !this.state.spindleNutFound && results.filters.Results[0].IsAftermarket){
            this.setState({spindleNutFound: true});
            dispatch(fetchHubsSpindleNut(results.filters.Results[0].HubAssemblyNumber));
        }
        if(results.spindleNut && Object.keys(results.spindleNut).length){
            this.setState({spindleNut: results.spindleNut[0].AftermarketParts[0].PartNumber});
        }
    }


  render() {
    let results = this.state.results;
    if (this.props.results.isSpindleNutFetching) {
      return (
        <Spinner isFetching={true}/>
      )
    }

   if (results.length > 0) {
      return (
        <div>
          {results.map((item, index) => {
            if (index === this.props.results.selectedIdx) {
              return (
                <HubSingleResult
                  key={index}
                  item={item}
                  spindleNut={this.state.spindleNut}
                  total={1}
                  selectedHubAssemblyNumber={this.props.results.selectedHubAssemblyNumber}
                  short_studs={this.props.results.short_studs}
                  results={this.props.results.spindleNut}
                />
              )
            }
          })}
        </div>
      )
    } else {
      return (
        <Spinner isFetching={true}/>
      )
    }
  }
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(NotAvailableHub);
