import React, { Component } from 'react';
import { resetAppState, setVal } from '../../actions/filters';
import { Link } from 'react-router-dom';
import { push } from 'react-router-redux';

export default class extends Component {
  showStepDisplay() {
    const { step, max } = this.props;

    if (step > 0) {
      return (
        <div className="step-number">
          Step <strong>{step}</strong> of <strong>{max}</strong>
        </div>
      );
    }
  }

  goHome() {
    const { dispatch } = this.props;
    dispatch(resetAppState());
    dispatch(push('/hub'));
  }
  setFilterVal(hcmty = null) {
    const { dispatch, app, onClick } = this.props;
    let FILTERIDX =
        app.categories.findIndex((a) => a.QueryParameterName === 'tmake') !==
        undefined
            ? app.categories.findIndex((a) => a.QueryParameterName === 'tmake')
            : 4;
    dispatch(setVal(FILTERIDX,{ hcmty: hcmty, gawrr: null, axthd: null, abrty: null }));
    onClick();
  }
  setMaterialFilterVal(whlps = null) {
    const { dispatch, app, onClick } = this.props;
    let FILTERIDX =
        app.categories.findIndex((a) => a.QueryParameterName === 'whlps') !==
        undefined
            ? app.categories.findIndex((a) => a.QueryParameterName === 'whlps')
            : 5;
    dispatch(setVal(FILTERIDX,{ whlps: whlps, brkty: 1, aaxna: 10, tcomp: 2, hubps: null, strdo: null, hamnt: null }));
    onClick();
  }

  render() {
    const { onClick, app, history } = this.props;
    let link = (
      <a href="#" onClick={onClick} className="back-btn">
        {' '}
        <i className="icon-angle-left"></i>Back
      </a>
    );
    const pathName = window.location.pathname;
    //
    // if (location.pathname === '/hub') {
    //   link = null;
    // }

    if (
      pathName.indexOf('/replacement-drum/filter/axpos') > -1 ||
      pathName.indexOf('/replacement-rotor/filter/axpos') > -1 ||
      pathName.indexOf('/casting/filter/aaxna') > -1 ||
      pathName.indexOf('/rotor-casting/filter/trtyp') > -1 ||
      pathName.indexOf('/drum-casting/filter/trtyp') > -1 ||
      pathName.indexOf('/stud/filter/stldp') > -1
    ) {
      link = (
        <a
          href="#"
          onClick={(e) => {e.preventDefault();history.go(-2)}}
          className="back-btn"
        >
          <i className="icon-angle-left"></i>Back
        </a>
      );
    } else if (
      pathName.indexOf('/replacement-drum') > -1 ||
      pathName.indexOf('/replacement-rotor') > -1 ||
      pathName.indexOf('/casting') > -1 ||
      pathName.indexOf('/rotor-casting') > -1 ||
      pathName.indexOf('/drum-casting') > -1 ||
      (pathName.indexOf('/stud') > -1 &&
        pathName.indexOf('hub/stud-pilot-type') < 1) ||
      pathName.indexOf('/hub/compare') > -1 ||
      pathName.indexOf('/replace/') > -1 ||
        (pathName.indexOf('/hub/bearing-filter-detail/') > -1 && pathName.indexOf('10041905') > -1) ||
        pathName.indexOf('/hub/hub-rebuild-kits-detail/') > -1 ||
        pathName.indexOf('/hub/abs-service-kit-detail/') > -1 ||
        pathName.indexOf('/hub/spindle-nut-kit-detail/') > -1 ||
        pathName.indexOf('/hub/seals-detail/') > -1 ||
        pathName.indexOf('/hub/abs-tone-ring-detail/') > -1 ||
        pathName.indexOf('/hub/seal-spacer-kit-detail/') > -1 ||
        pathName.indexOf('/hub/bearing-detail/') > -1
    ) {
      link = (
        <a
          href="#"
          onClick={(e) => {e.preventDefault();history.goBack()}}
          className="back-btn"
        >
          <i className="icon-angle-left"></i>Back
        </a>
      );
    } else if (!app || app.currentIndex === 0) {
      link = (
        <Link to="/hub" className="back-btn">
          <i className="icon-angle-left"></i>Back
        </Link>
      );
    }
    if (pathName.indexOf('/search/') > -1) {
      link = (
        <Link to="/hub/search" className="back-btn">
          <i className="icon-angle-left"></i>Back
        </Link>
      );
    }
    if (pathName.indexOf('/hub/search/') > -1) {
      link = (
        <Link to="/hub/search" className="back-btn">
          <i className="icon-angle-left"></i>Back
        </Link>
      );
    }
    if (pathName.indexOf('/parts/search/') > -1) {
      link = (
        <a
          href="#"
          onClick={(e) => {e.preventDefault();history.goBack()}}
          className="back-btn"
        >
          <i className="icon-angle-left"></i>Back
        </a>
      );
    }
    if (pathName.indexOf('/details/') > -1) {
      link = (
        <a
          href="#"
          onClick={(e) => {e.preventDefault();history.goBack()}}
          className="back-btn"
        >
          <i className="icon-angle-left"></i>Back
        </a>
      );
    }
    if (
      pathName.indexOf('/help') > -1 ||
      pathName.indexOf('/disclaimer') > -1 ||
      pathName.indexOf('/filters') > -1
    ) {
      link = (
        <a
          href="#"
          onClick={(e) => {e.preventDefault();history.goBack()}}
          className="back-btn"
        >
          <i className="icon-angle-left"></i>Back
        </a>
      );
    }
    if (pathName.indexOf('/hub/notification-detail/') > -1) {
      link = (
        <Link to="/notification" className="back-btn">
          <i className="icon-angle-left"></i>Back
        </Link>
      );
    }
    if (pathName.indexOf('/hub/wheel-type') > -1 && app.filterState.hcmty === 2) {
      link = (
          <a
              href="#"
              onClick={(e) => {e.preventDefault();this.setFilterVal(app.filterState.hcmty);}}
              className="back-btn"
          >
            <i className="icon-angle-left"></i>Back
          </a>
      );
    }
    if (pathName.indexOf('/hub/material') > -1 && app.filterState.whlps === 2 && app.filterState.brkty === 1 && app.filterState.hubps === 2 && app.filterState.strdo === 2 && app.filterState.hamnt === 1 && (app.filterState.aaxna === 10 || app.filterState.aaxna === 11) && app.filterState.tcomp === 2) {
      link = (
          <a
              href="#"
              onClick={(e) => {e.preventDefault();this.setMaterialFilterVal(app.filterState.whlps);}}
              className="back-btn"
          >
            <i className="icon-angle-left"></i>Back
          </a>
      );
    } else if (pathName.indexOf('/hub/material') > -1) {
      link = (
          <a
              href="#"
              onClick={(e) => {e.preventDefault();this.setFilterVal(app.filterState.hcmty);}}
              className="back-btn"
          >
            <i className="icon-angle-left"></i>Back
          </a>
      );
    }
    if(pathName.indexOf('/hub/bearing-filter-detail/') > -1 && pathName.indexOf('10041905') < 0)
    {
      link = (
          <Link to="/hub" className="back-btn">
            <i className="icon-angle-left"></i>Back
          </Link>
      );
    }
    if(pathName.indexOf('/hub/spindle-nut') > -1 && app.filterState.axthd === null)
    {
      link = (
          <Link to="/hub" className="back-btn">
            <i className="icon-angle-left"></i>Back
          </Link>
      );
    }

    return (
      <div className="step-bar grid-block small-12 wrap shrink ">
        <div className="grid-content no-scroll small-6">{link}</div>

        <div className="grid-content no-scroll right small-6">
          <a
            href="#"
            className="home-btn right"
            onClick={this.goHome.bind(this)}
          >
            Home
          </a>
        </div>
      </div>
    );
  }
}
