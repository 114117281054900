import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';

let FILTERIDX;
const NEXT_OTHER_NOT_LISTED_FILTER_PATH = '/hub/abs-offset-inches';

class Result extends Component {
  render() {
    var { result, active, onClick } = this.props;
    var className = 'general-button truck-make';
    return (
      <div className="grid-content small-6">
        <a href="#" className={active()} onClick={onClick}>
          {result.Name}
        </a>
      </div>
    );
  }
}

class TruckMakes extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'tmake') !==
        undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'tmake')
        : 4;

    dispatch(fetchFilters(FILTERIDX, app));
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    const {
      filterState: { tcomp, brkty },
    } = app;

    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    let ic = app.filterResults.filter( vendor => vendor['Name'] === 'I/C' )
    return (
      <div className="grid-container main-content">
        <h2>Choose the Truck Make</h2>
        <div className="grid-block">
          {app.filterResults.map((result, index) => {
           
              var boundClick = setFilter.bind(
                this,
                FILTERIDX,
                { tmake: result.Id },
                app
              );
            
            var boundActive = setActive.bind(
              this,
              FILTERIDX,
              result.Id,
              'general-button truck-make'
            );
            return (
              <Result
                key={index}
                app={app}
                result={result}
                active={boundActive}
                onClick={boundClick}
              />
            );
          
            
          })}
         {tcomp !== 3 && (
          <div className="grid-content small-6">
            <button
              className="general-button truck-make"
              onClick={setFilter.bind(this, FILTERIDX, null, app)}
            >
              Other/Not Listed
            </button>
          </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(TruckMakes);
