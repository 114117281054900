import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';
import { TRUCK } from '../../config/constants';
import { push } from 'react-router-redux';

let NEXT_FILTER = '/hub/overall-rotor-length';

let FILTERIDX;

class Result extends Component {
  render() {
    var { result, onClick } = this.props;
    var className = 'conmet-button';
    return (
      <div className="grid-content small-6">
        <div className="conmet-button">
          <button className="yes-no-button" onClick={onClick}>
            <strong>
              {result.Inch}" / {result.Millimeter}mm
            </strong>
          </button>
        </div>
      </div>
    );
  }
}

class BrakeRotorFlange extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    const { tcomp, tmake } = app.filterState;

    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'brdia') !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'brdia')
        : 19;
   
    dispatch(fetchFilters(FILTERIDX, app));
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    const { tcomp, tmake } = app.filterState;
    const otherNotListedPath = tcomp === TRUCK && tmake === null;

    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    return (
      <div className="grid-container main-content">
        <h2>Choose the Brake Rotor Outside Diameter</h2>

        <div className="grid-block">
          <div className="grid-content small-12">
            <img
              className="brake-rotor-flange"
              src={require('../../images/brake_rotor_lange.png')}
              alt="ConMet"
              width="300"
              height="300 "
            />
          </div>

          {app.filterResults.map((result, index) => {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { brdia: result.Inch },
              app
            );
            var boundActive = setActive.bind(this, FILTERIDX, result.Inches);
            return (
              <Result
                key={index}
                app={app}
                result={result}
                active={boundActive}
                onClick={boundClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(BrakeRotorFlange);
