import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import { fetchFilters } from '../../actions/filters';
import { TRUCK } from '../../config/constants';

let FILTERIDX;
const NEXT_FILTER_PATH = '/hub/hub-cap-bolt-circle-diameter-inches';

class Result extends Component {
  render() {
    var { result, active, onClick } = this.props;
    var className = 'general-button truck-make';
    return (
      <div className="grid-content small-6">
        <a href="#" className={active()} onClick={onClick}>
          {result.Inch}"
        </a>
      </div>
    );
  }
}

class HubOffsetInches extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    const { tcomp, tmake } = app.filterState;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'hofsi') !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'hofsi')
        : 20;
    const otherNotListedPath = tcomp === TRUCK && tmake === null;

    if (!otherNotListedPath) {
      dispatch(push('/hub/brake-rotor-flange'));
    } else {
      dispatch(fetchFilters(FILTERIDX, app));
    }
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    return (
      <div className="grid-container main-content">
        <h2>Choose the Hub Offset:</h2>
        <img
          className="filter-image"
          src={require('../../images/hub_offset.png')}
          alt="hub_offset"
          alt="ConMet"
          width="300"
          height="300"
        />
        <div className="grid-block">
          {app.filterResults.map((result, index) => {
            var boundClick = setFilter.bind(
              this,
              FILTERIDX,
              { hofsi: result.Inch },
              app
            );
            var boundActive = setActive.bind(
              this,
              FILTERIDX,
              result,
              'general-button truck-make'
            );
            if (result.Inch !== '0') {
              return (
                <Result
                  key={index}
                  app={app}
                  result={result}
                  active={boundActive}
                  onClick={boundClick}
                />
              );
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(HubOffsetInches);
