import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchStudSummaries, fetchStudFilterValues}
 from '../../actions';
import Waiting from '../../components/global/waiting';
import NoResults from '../global/no-result';
import classNames from 'classnames';
import StudChart from './stud-chart';

class StudSummary extends Component {
  constructor(props) {
    super(props);
    this.getSummaryValue = this.getSummaryValue.bind(this);
    this.getStudList = this.getStudList.bind(this);
    this.state = {
        studId: null,
    };

  }

  componentWillMount() {
    const {match} = this.props;
    if (match.params.id){
        this.setState({studId: match.params.id});
        this.getSummaryValue(match.params.id);
    }
  }

  componentWillReceiveProps(newProps) {
      const { results, match } = newProps;
      if(match.params.id !== this.state.studId){
        this.setState({studId: match.params.id});
        this.getSummaryValue(match.params.id);
      }
      if( results.studSummaries !== this.props.results.studSummaries) {
        this.getStudList(newProps);
      }
  }

  getSummaryValue(id) {
    const {dispatch} = this.props;

    dispatch(fetchStudSummaries(id));
  }

  getStudList(props) {
    const { dispatch, results } = props;
    const { studSummaries } = results;
    if(studSummaries.length) {
      this.setState({studSummaries: results});
        dispatch(fetchStudFilterValues('sdnum', `inclv=0&bstyl=${studSummaries[0].BaseStylesId}&dimB=${studSummaries[0].DimensionB}&dimC=${studSummaries[0].DimensionC}`));
    }
  }

  render() {
    const { results } = this.props;
    const studSummaries = results.studSummaries;
    const studFilterValues = results.studFilterValue;

    if (results.isFetching) {
      return <Waiting />;
    }

      if ((studSummaries && studSummaries.length === 0) || (studFilterValues && studFilterValues.length === 0)) {
          return <NoResults />;
      }

    if (studFilterValues) {
      return (
          <div id="studResult">
            {/* <h2>Success! The following ConMet Stud is recommended</h2> */}
            <div className="result">
              <div
                className={classNames('prev-button', {
                  'hide-button': studSummaries.length <= 1 || this.state.selectedIndex === 0,
                })}
                onClick={this.prevData}
              >
                <i className="icon-angle-left"/>
              </div>
              {studSummaries.map((data, i) => (
                <div className="specs stud" key={i}>
                  {
                    data.Images && data.Images.map((image) => (
                      // <img className="product-image" key={image.ImageGuid} src={IMAGE_CDN + image.ImageGuid + '.png'}
                      //     alt="Dimensions" height={400}
                      //     width={400}/>
                          <img className="product-image" key={image.ImageGuid} src={ image.ImageUrl + '.png'}
                          alt="Dimensions" height={400}
                          width={400}/>
                    ))
                  }
                  <table>
                    <tbody>
                    <tr>
                      <td>Stud Number</td>
                      <td>{data.StudNumber}</td>
                    </tr>
                    <tr>
                      <td>Chart Description</td>
                      <td>{data.ChartDescription}</td>
                    </tr>
                    <tr>
                      <td>Dimension A Inches</td>
                      <td>{data.DimensionA}</td>
                    </tr>
                    <tr>
                      <td>Dimension B Inches</td>
                      <td>{data.DimensionB}</td>
                    </tr>
                    <tr>
                      <td>Dimension C Inches</td>
                      <td>{data.DimensionC}</td>
                    </tr>
                    <tr>
                      <td>Dimension D Inches</td>
                      <td>{data.DimensionD}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              ))}
              <div
                className={classNames('next-button', {
                  'hide-button': studSummaries.length <= 1 || studSummaries.length - 1 === this.state.selectedIndex,
                })}
                onClick={this.nextData}
              >
                <i className="icon-angle-right"/>
              </div>
                <StudChart studFilterValues={studFilterValues} studNumbers={studSummaries}/>
            </div>
          </div>
        );
    }
    return null;
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(StudSummary);
