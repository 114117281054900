import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { appInsights } from '../../../AppInsights';

class HubResults extends React.Component {
  render() {
    let assemblyType = this.props.assemblyType;
    if (assemblyType) {
      return (
        <div className="details">
          <div>Best Value:</div>
          <ul>
            <li>Extended Warranty</li>
            <li>Less Downtime</li>
            <li>Simplified Process</li>
            {/*<li>Liability Protection</li>*/}
          </ul>
        </div>
      );
    } else {
      return (
        <div className="details">
          <div>Requires:</div>
          <ul>
            <li>Bearing Cones</li>
            <li>Seal</li>
            <li>Manual Adjustment</li>
          </ul>
        </div>
      );
    }
  }
}

class SetLink extends Component {
  render() {
    const note = this.props.note;
    let str = note.Text;
    let links = note.Links;
    if(links){
        let matches = str.match(/{{(.*?)}}/g) || [];
        matches = matches.map(function (n, idx) {
          if (links.length > 0) {
            return [
              n,
              n
                .replace(
                  "{{",
                  '<a href="https://conmetaftermarketpubliccdn.azureedge.net/documents/' +
                    links[idx] +
                    '">'
                )
                .replace("}}", "</a>"),
            ];
          }
        });
        matches.forEach((item) => {
            str = str.replace(item[0], item[1]);
        });
        return (
          <p className="note" dangerouslySetInnerHTML={{ __html: str }} />
        );
    }else{
        return <div className="note">{str}</div>;
    }
  }
}

class Result extends Component {
  render() {
    const {
      results,
      spindleNut,
      selectedHubAssemblyNumber,
      short_studs,
    } = this.props;
    const selectedNumber = selectedHubAssemblyNumber
      ? selectedHubAssemblyNumber.split(" ")[1]
      : "";
  

    return (
      <div className="grid-container main-content" id="hubAssemblyResult">
        <h2>Success!</h2>
        {selectedHubAssemblyNumber ? (
          <p className="text-center">for {selectedHubAssemblyNumber}</p>
        ) : (
          ""
        )}
        <div className="grid-content flex-row">
          {results.map((item, index) => {
            let assemblyType = item.AftermarketDescription.toLowerCase().includes(
              "preset"
            );
            return (
              <div
                className={results.length === 2 ? "small-6" : "small-12"}
                key={index}
              >
                <img
                  className="product-image"
                  src={require("../../../images/blank1x1.png")}
                  //style={{background: "url('" + IMAGE_CDN + item.Images[0].ImageGuid + '.png' + "') center center no-repeat"}}
                  style={{
                    background:
                      "url('" +
                      item.Images[0].ImageUrl +
                      ".png" +
                      "') center center no-repeat",
                  }}
                  alt={item.HubAssemblyNumber}
                />
                <div className="type">
                  {assemblyType ? "PreSet" : "Conventional"}
                </div>
                <div className="number italic">{item.HubAssemblyNumber}</div>
                {short_studs && short_studs[index] && (
                  <div
                    className="number text-center"
                    style={{ marginTop: "10px" }}
                  >
                    {`${short_studs[index].HubAssemblyNumber} (Long stud version)`}
                  </div>
                )}
                <HubResults assemblyType={assemblyType} key={index} />
              </div>
            );
          })}
          {spindleNut && (
            <div className="optional-spindle" style={{ textAlign: "center" }}>
              5 years/500,000 mile warranty when installed with PreSet Plus
              Spindle Nut: {spindleNut} ( PreSet Hubs Only)
            </div>
          )}
          {spindleNut && (
            <div className="warranty-spindle" style={{ textAlign: "center" }}>
              {" "}
              *For full warranty details click{" "}
              <a href="https://conmet.com/wp-content/uploads/2020/07/Limited-Warranty-Aftermarket-10045532-Rev-D2.pdf">
                {" "}
                here.
              </a>
            </div>
          )}

          {results.map((item, index) => {
            return (
              <div
                className={results.length === 2 ? "small-6" : "small-12"}
                key={index}
              >
                <Link
                  to={"/hub/details/" + item.HubAssemblyNumber}
                  key={index}
                  className="general-button"
                >
                  See Details
                </Link>
              </div>
            );
          })}
          <div className="clearfix" />
          {/*{!selectedNumber && (*/}
          {/*<div className="small-12">*/}
          {/*<Link*/}
          {/*to={`/hub/compare/${results[0].HubAssemblyNumber}/${results[1].HubAssemblyNumber}`}*/}
          {/*className="general-button"*/}
          {/*style={{ marginTop: 0 }}*/}
          {/*>*/}
          {/*Compare*/}
          {/*</Link>*/}
          {/*</div>*/}
          {/*)}*/}
        </div>
        <SetLink note={results[0].GawrNote} />
        {/* <div className="disclaimer"><Link to="/disclaimer">ConMet Wheel End Disclaimer</Link></div> */}
      </div>
    );
  }
}

export default connect()(Result);
