import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  push } from 'react-router-redux'
import {registerSession, resetFilters, setCookie, getCookie } from '../../actions';
import { slide } from 'react-burger-menu';

var Menu = slide;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, count: '0' };
    this.renderHeader = this.renderHeader.bind(this);
  }

  componentDidMount() {
    let count = JSON.parse(localStorage.getItem('unseenNotificationsCount'));
    this.setState({ count });
  }

  componentWillReceiveProps(newProps) {
    let count = JSON.parse(localStorage.getItem('unseenNotificationsCount'));
    this.setState({ count });
  }

  goHome() {
    const { dispatch } = this.props;
    dispatch(resetFilters());
    dispatch(push('/hub'));
  }

  goDisclaimer() {
    const { dispatch } = this.props;
    dispatch(resetFilters());
    dispatch(push('/disclaimer'));
    this.setState({
      isOpen: false,
    });
  }
  goCompareHub() {
    const { dispatch } = this.props;
    dispatch(resetFilters());
    dispatch(push('/hub/compare-hub'));
    this.setState({
      isOpen: false,
    });
  }

  goNotification() {
    const { dispatch } = this.props;
    dispatch(resetFilters());
    dispatch(push('/notification'));
    this.setState({
      isOpen: false,
    });
  }

  goChooseCalculator() {
    const { dispatch } = this.props;
    dispatch(push('/replace/choose-calculator'));
    this.setState({
      isOpen: false,
    });
  }

  handleStateChange(state) {
    let count = JSON.parse(localStorage.getItem('unseenNotificationsCount'));
    this.setState({ isOpen: state.isOpen, count });
  }

  renderHeader() {
    const { router: {location} } = this.props;
    let path = location.pathname.includes('/replace/');
    if(path){
      const { session: {chooseCalculator} }= this.props;
      if (location.pathname === '/replace/choose-calculator') {
        return 'Calculators';
      } else if (chooseCalculator) {
        return 'PreSet vs. Conventional Calculator';
      } else if (chooseCalculator === 0) {
        return 'Replace Calculator';
      }
    }
      return null;
  }

  render() {
    const { count } = this.state;
    return (
      <div className="navigation-wrapper">
        <Menu
          className="global-navigation"
          right
          isOpen={this.state.isOpen}
          onStateChange={(state) => this.handleStateChange(state)}
        >
          <p>More Information</p>
          <ul>
            <li>
              <a href="https://conmet.com/support/">Contact ConMet</a>
            </li>
            <li>
              <a href="http://www.conmet.com/">Visit ConMet.com</a>
            </li>
            <li>
              <a href="http://www.conmet.com/general/literature/">
                Service Literature
              </a>
            </li>
            <li>
              <a onClick={this.goChooseCalculator.bind(this)}>Calculators</a>
            </li>
            <li>
              <a onClick={this.goDisclaimer.bind(this)}>Disclaimer</a>
            </li>
            <li>
              <a onClick={this.goNotification.bind(this)}>
                Notifications{' '}
                {count > 0 ? <span className="note-count">{count}</span> : null}
              </a>
            </li>
            <li>
              <a href="https://training.conmet.com/">Hub Training 2.0</a>
            </li>
            <li>
              <a href="https://conmet.com/privacy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </li>
          </ul>
        </Menu>

        <div className="dark title-bar">
          <div className="left title">
            <div id="logo">
              <a href="#" onClick={this.goHome.bind(this)}>
                <img src={require('../../images/logo.svg')} alt="ConMet" />
              </a>
            </div>
          </div>

          {count > 0 ? (
            <span className="right">
              <div className="note-dot"> </div>
            </span>
          ) : null}
        </div>
        <div className="sub-title">{this.renderHeader()}</div>
      </div>
    );
  }
}
function select(state) {
  return {
    notifications: state.results.notifications,
    router: state.router,
    session: state.calculators
  };
}
export default connect(select)(Navigation);
