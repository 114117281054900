import React, { Component } from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {
    startEmail
} from '../../actions';

function mapStateToProps(state) {
    return state;
}

class Email extends Component {
    constructor(props, context) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            recipientName: '',
            recipientEmail: '',
            recipientCompany: '',
            senderName: '',
            senderEmail: '',
            senderCompany: '',
            notes: '',
        }
    }

    handleChange(e) {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();
        const {
            calculators: {
                partsMargin,
                billableLaborRate,
                laborRate,
                rebuildHours,
                replaceHours,
                bearingSetCost,
                manualSpindleNutCost,
                wheelSealCost,
                hubCost,
                replacementHubCostV,
                spindleNutCost,
                chooseCalculator,
                downtimeCosts
            },
            dispatch
        } = this.props;
        let rebuildPartsCost = _.toNumber(chooseCalculator ? hubCost : 0) + _.toNumber(wheelSealCost) + _.toNumber(bearingSetCost) + _.toNumber(manualSpindleNutCost);
        let replacementHubCost = _.toNumber(replacementHubCostV) + _.toNumber(spindleNutCost);
        let rebuildLaborCost = _.toNumber(rebuildHours) * _.toNumber(laborRate);
        let replaceLaborCost = _.toNumber(replaceHours) * _.toNumber(laborRate);
        let rebuildDowntimeCost = _.toNumber(rebuildHours) * _.toNumber(downtimeCosts);
        let replaceDowntimeCost = _.toNumber(replaceHours) * _.toNumber(downtimeCosts);
        let totalRebuildCosts = (_.toNumber(rebuildPartsCost) + _.toNumber(rebuildLaborCost) + _.toNumber(rebuildDowntimeCost));
        let totalReplaceCosts = (_.toNumber(replacementHubCostV) + _.toNumber(spindleNutCost) + _.toNumber(replaceLaborCost) + _.toNumber(replaceDowntimeCost));
        let partsProfit = (_.toNumber(partsMargin) / 100) * (_.toNumber(bearingSetCost) + _.toNumber(wheelSealCost) + _.toNumber(manualSpindleNutCost));
        let laborProfit = (_.toNumber(billableLaborRate) - _.toNumber(laborRate)) * _.toNumber(rebuildHours);
        const rebuildMarginValue = (partsProfit + laborProfit);
        const addedState = {
            totalRebuildCosts,
            totalReplaceCosts,
            rebuildPartsCost,
            replacementHubCost,
            replaceLaborCost,
            rebuildLaborCost,
            replaceDowntimeCost,
            rebuildDowntimeCost,
            rebuildMarginValue,
            ...this.props.calculators,
            ...this.state
        };
        dispatch(startEmail(addedState));
    }

    render() {
        const {calculators: {sendingEmail, emailError}} = this.props;
        const {  recipientName, recipientEmail, recipientCompany, senderName, senderEmail, senderCompany } = this.state;
        let renderEl = '';
        if (sendingEmail) {
            renderEl = (<div className="sending">
                <h2>Sending Email...</h2>
            </div>)
        } else if (emailError) {
            renderEl = (<div className="error">
                <h2>We were unable to send the email. Please try again later</h2>
            </div>)

        } else {
            renderEl = (<form className="email-form" onSubmit={this.handleSubmit} autoComplete="off">
                <h1>Email Results</h1>
                <div className="email-group">
                    <div className="title">To:</div>
                    <input
                        type="text"
                        name="recipientName"
                        autoFocus={true}
                        className="form-control"
                        placeholder="Recipient Name"
                        value={recipientName}
                        onChange={this.handleChange}
                        required
                    />
                    <input
                        type="email"
                        name="recipientEmail"
                        className="form-control"
                        placeholder="Recipient E-mail Address"
                        value={recipientEmail}
                        onChange={this.handleChange}
                        required
                    />
                    <input
                        type="text"
                        name="recipientCompany"
                        className="form-control"
                        placeholder="Recipient Company"
                        value={recipientCompany}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="email-group">
                    <div className="title">From:</div>
                    <input
                        type="text"
                        name="senderName"
                        className="form-control"
                        placeholder="Sender Name"
                        value={senderName}
                        onChange={this.handleChange}
                    />
                    <input
                        type="email"
                        name="senderEmail"
                        className="form-control"
                        placeholder="Sender E-mail Address"
                        value={senderEmail}
                        onChange={this.handleChange}
                    />
                    <input
                        type="text"
                        className="form-control"
                        name="senderCompany"
                        placeholder="Sender Company"
                        value={senderCompany}
                        onChange={this.handleChange}
                    />
                </div>
                <textarea name="notes" placeholder="Notes" rows={5} onChange={this.handleChange} />
                <button type="submit" className="general-button">Send</button>
                <a className="general-button" href="https://aftermarket.conmetwheelends.com/">Find your Parts</a>
                <a className="general-button" href="/replace/choose-calculator">Start Again</a>
            </form>)
        }
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    {renderEl}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(Email);

