import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {competitorSearch, fetchSealsResult} from '../../actions';
import NoResults from '../global/no-result';

class SealsDetail extends React.Component {
    constructor() {
        super();

        this.state = {
            rebuildKitActive: false,
        };
    }
    details = false;
    
    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber) {
            dispatch(fetchSealsResult(match.params.partNumber));
        }
    }

    componentWillReceiveProps(newProps) {
        const {dispatch, match, results} = newProps;
        const sealDetail = results.partNumber;

        if (results && !results.isFetching && sealDetail && sealDetail.length === 0 && !this.details) {
            dispatch(competitorSearch(match.params.partNumber)).then(() => {
                this.details = true;
                const {results} = this.props;
                const sealDetail = results.partNumber;
                const partNum = sealDetail && sealDetail[0] && sealDetail[0].ConMetPartNumber;
                dispatch(fetchSealsResult(partNum));
            });
        }
    }

    render() {
        const { results } = this.props;
        const sealDetail = results.partNumber;

        if (results.isFetching) {
            return <Waiting />;
        }

        if (sealDetail && sealDetail.length === 0 || sealDetail === undefined) {
            if (!this.details) {
                return <Waiting />;
            }else{
                return <NoResults />;
            }
        }

        return (
            <div className="grid-container main-content result">
                <h2>Product Details</h2>
                <div className="meta">
                    <span>
                        <img
                            className="product-image"
                            src={sealDetail && sealDetail[0] && sealDetail[0].ImageURL ? sealDetail && sealDetail[0] && sealDetail[0].ImageURL + '.png' : 'https://cdn.intelligencebank.com/us/share/ONnl/Aa8M/73rBb/original/Image_Not_Available'}
                            alt="Seals"
                            width="100"
                            height="100"
                        />
                    </span>
                    {sealDetail && sealDetail[0] && sealDetail[0].Type} {sealDetail && sealDetail[0] && sealDetail[0].Axle} {sealDetail && sealDetail[0] && sealDetail[0].AxlePostion}<br/>
                    <em>{sealDetail && sealDetail[0] && sealDetail[0].PartNumber}</em>
                </div>
                <div>
                    <div className="specs mb-3">
                        <h2>Product Specs</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Axle Position</td>
                                <td>{sealDetail && sealDetail[0] && sealDetail[0].AxlePostion ? sealDetail && sealDetail[0] && sealDetail[0].AxlePostion : '-'}</td>
                            </tr>
                            <tr>
                                <td>Axle</td>
                                <td>{sealDetail && sealDetail[0] && sealDetail[0].Axle ? sealDetail && sealDetail[0] && sealDetail[0].Axle : '-'}</td>
                            </tr>
                            <tr>
                                <td>Product Type</td>
                                <td>{sealDetail && sealDetail[0] && sealDetail[0].Type ? sealDetail && sealDetail[0] && sealDetail[0].Type : '-'}</td>
                            </tr>
                            <tr>
                                <td>Reference Dimensions</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>ID(in.)</td>
                                <td>Ø {sealDetail && sealDetail[0] && sealDetail[0].InnerDiameterInches ? sealDetail && sealDetail[0] && sealDetail[0].InnerDiameterInches.toFixed(2) : '-'}</td>
                            </tr>
                            <tr>
                                <td>OD(in.)</td>
                                <td>Ø {sealDetail && sealDetail[0] && sealDetail[0].OuterDiameterInches ? sealDetail && sealDetail[0] && sealDetail[0].OuterDiameterInches.toFixed(2) : '-'}</td>
                            </tr>
                            <tr>
                                <td>Width(in.)</td>
                                <td>{sealDetail && sealDetail[0] && sealDetail[0].Width ? sealDetail && sealDetail[0] && sealDetail[0].Width.toFixed(2) : '-'}</td>
                            </tr>
                            <tr style={{ border: 'none' }}>
                                <td>
                                    {sealDetail && sealDetail[0] && sealDetail[0].KitType}
                                </td>
                                <td>
                                    {sealDetail && sealDetail[0] && sealDetail[0].SealAndSpacerKit ? sealDetail && sealDetail[0] && sealDetail[0].SealAndSpacerKit : '-'}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(SealsDetail);
