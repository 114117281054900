import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import {fetchBearingWithFilterResult} from '../../actions';
import NoResults from '../global/no-result';
import {fetchRebuildKitDetails} from '../../actions/parts';
import classNames from 'classnames';
import {Link} from "react-router-dom";

class BearingFilterDetail extends React.Component {
    constructor() {
        super();

        this.state = {
            rebuildKitActive: false,
        };
    }

    componentWillMount() {
        const {dispatch, match} = this.props;
        if (match.params.partNumber && match.params.filterId) {
            dispatch(fetchBearingWithFilterResult(match.params.partNumber, match.params.filterId));
        }

    }

    handleFilterClick(kitNumber) {
        const { dispatch } = this.props;
        if(kitNumber){
            dispatch(fetchRebuildKitDetails(kitNumber));
        }
        this.setState({ rebuildKitActive: !this.state.rebuildKitActive });

    }

    renderRebuildKit() {
        const { parts, results } = this.props;
        const presetRebuild = [];
        const hubRebuildDetail = results.FilterIdDetail;

        presetRebuild.push(
            <tr style={{ border: 'none' }}>
                <td>
          <span
              className={classNames('rebuild-kit-toggle', { show: this.state.rebuildKitActive })}
              onClick={() => this.handleFilterClick(hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].PreSetPreSetPlusRebuildKitNumber)}
          >
              {'Preset / Preset Plus Hub Rebuild Kit'}</span>
                </td>
                <td>
                    {hubRebuildDetail && hubRebuildDetail[0] && hubRebuildDetail[0].PreSetPreSetPlusRebuildKitNumber}
                </td>
            </tr>
        );

        presetRebuild.push(
            <tr style={{ paddingTop: 0 }}>
                <td colSpan={2} style={{ padding: !this.state.rebuildKitActive ? 0 : '' }}>
                    {
                        parts.rebuildKitDetails && parts.rebuildKitDetails.length ? parts.rebuildKitDetails.map((item, index) => (
                            <table
                                key={index}
                                className={classNames('rebuild-kit-info', { 'show m-0 show1': this.state.rebuildKitActive })}
                            >
                                <tr>
                                    <td width="70%">Inner Bearing</td>
                                    <td>{item.BearingSetNumberInboard}</td>
                                </tr>
                                <tr>
                                    <td width="70%">Outer Bearing</td>
                                    <td>{item.BearingSetNumberOutboard}</td>
                                </tr>
                                <tr>
                                    <td width="70%">Seal & Spacer</td>
                                    <td>{item.SealAndSpacerKitNumber}</td>
                                </tr>
                            </table>
                        )) : (
                            <table
                                className={classNames('rebuild-kit-info', { 'show m-0 show1': this.state.rebuildKitActive })}
                            >
                                <tr>
                                    <td width="70%">Inner Bearing</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td width="70%">Outer Bearing</td>
                                    <td>-</td>
                                </tr>
                                <tr>
                                    <td width="70%">Seal & Spacer</td>
                                    <td>-</td>
                                </tr>
                            </table>
                        )
                    }
                </td>
            </tr>
        );

        return presetRebuild;
    }

    render() {
        const { results } = this.props;

        const bearingWithFilterDetail = results.FilterIdDetail;

        if (results.isFetchingBearing) {
            return <Waiting />;
        }

        if (bearingWithFilterDetail && bearingWithFilterDetail.length === 0) {
            return <NoResults />;
        }

        return (
            <div className="grid-container main-content result">
                <h2>Product Details</h2>
                <div className="meta">
                    <span>
                        <img
                            className="product-image"
                            src={bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].ImageURL ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].ImageURL + '.png' : 'https://cdn.intelligencebank.com/us/share/ONnl/Aa8M/73rBb/original/Image_Not_Available'}
                            alt="BearingWithFilterDetail"
                            width="100"
                            height="100"
                        />
                    </span>
                    {bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].BearingType} {bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].Axle} {bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].AxlePostion}<br/>
                    <em>{bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].PartNumber}</em>
                </div>
                <div>
                    <div className="specs mb-3">
                        <h2>Product Specs</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>Axle Position</td>
                                <td>{bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].AxlePostion ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].AxlePostion : '-'}</td>
                            </tr>
                            <tr>
                                <td>Axle</td>
                                <td>{bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].Axle ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].Axle : '-'}</td>
                            </tr>
                            <tr>
                                <td>Product Type</td>
                                <td>{bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].Type ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].Type : '-'}</td>
                            </tr>
                            <tr>
                                <td width="70%">Bearing Type</td>
                                <td>{bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].BearingType ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].BearingType : '-'}</td>
                            </tr>
                            <tr>
                                <td>Reference Set No.</td>
                                <td>{bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].ReferenceSetNo ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].ReferenceSetNo : '-'}</td>
                            </tr>
                            <tr>
                                <td>Reference Dimensions</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Id(IN.)</td>
                                <td>Ø {bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].BoreDiameterInch ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].BoreDiameterInch.toFixed(2) : '-'}</td>
                            </tr>
                            <tr>
                                <td>Od(IN.)</td>
                                <td>Ø {bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].OuterDiameterInch ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].OuterDiameterInch.toFixed(2) : '-'}</td>
                            </tr>
                            <tr>
                                <td>Width(IN.)</td>
                                <td>{bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].Width ? bearingWithFilterDetail && bearingWithFilterDetail[0] && bearingWithFilterDetail[0].Width.toFixed(2) : '-'}</td>
                            </tr>
                            {
                                this.renderRebuildKit()
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(BearingFilterDetail);
