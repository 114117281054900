import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SelectButton extends Component {
  static propTypes = {
		calculator: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
		label: PropTypes.string.isRequired,
		location: PropTypes.string.isRequired,
		sessionId: PropTypes.number.isRequired,
		stateKey: PropTypes.string.isRequired,
		stateVals: PropTypes.object
	};

  constructor(props, context) {
    super(props, context);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

	handleSubmit() {
		const {calculator, sessionId, stateVals, stateKey, label, location, handleClick} = this.props;
		let stateValUpdate = stateVals;
		if (!stateValUpdate) {
			stateValUpdate = {};
			stateValUpdate[stateKey] = label;
		}
		let updateObj = {...stateValUpdate, lastStep: stateKey};
		handleClick(sessionId, updateObj, location, calculator);

	}

  render() {
		const { label } = this.props;
    return (
			<a className="general-button"
				onClick={this.handleSubmit}>
				{label}</a>
    );
  }
}
