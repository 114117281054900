import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { push } from 'react-router-redux';
class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openModel: false,
      openDetailModal: false,
    };
  }

  openDetailModal(id) {
    const { dispatch } = this.props;
    dispatch(push('/hub/notification-detail/' + id));
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    const index = notifications.findIndex((a) => a.Id === id);
    if (index > -1) {
      notifications[index] = { ...notifications[index], seen: (!notifications[index].Sticky) };
    }
    localStorage.setItem('notifications', JSON.stringify(notifications));
    let unseen = notifications.filter((a) => a.seen === false || !a.seen);
    localStorage.setItem(
      'unseenNotificationsCount',
      JSON.stringify(unseen ? unseen.length : notifications.length)
    );
  }

  getOS() {
    let userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      os = 'iOS';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
    return os;
  }

  notificationList(d, i){

    let os = this.getOS();
    let version = localStorage.getItem('version');
    let osPassed = true;
    if(d.TargetOS){
      let _androidDevice = os === 'Android';
      let _iOSDevice = os === 'iOS';
      let _isWeb = !_androidDevice && !_iOSDevice;
      if(_androidDevice && !/Android/i.test(d.TargetOS)){
        osPassed = false;
      }else if(_iOSDevice && !/iOS/i.test(d.TargetOS)){
        osPassed = false;
      }else if(_isWeb &&  !/Web/i.test(d.TargetOS)){
        osPassed = false;
      }
    }
    let versionPassed = true;
    if(d.Version){
      if(d.Version.indexOf('<') !== -1) {
        versionPassed = version === null || version < d.Version.replace('<','').trim();
      } else {
        versionPassed = version === d.Version;
      }
    }
    console.log(osPassed, versionPassed)

    if(osPassed && versionPassed){
      return (
          <div
              className="grid-block small-12"
              key={i}
              onClick={() => this.openDetailModal(d.Id)}
              style={{ cursor: 'pointer' }}
          >
            <div className="note-arrow"> </div>
            <div className="note-list text-left">
              <h4
                  style={{ fontWeight: d.seen ? 'initial' : 'bold' }}
              >
                {d.Title}
              </h4>
            </div>
          </div>
      );
    }
  }

  render() {
    let notifications = JSON.parse(localStorage.getItem('notifications'));
    Modal.setAppElement('body');
    return (
      <div className="grid-frame splash notification-menu" id="notification">
        <div className="grid-block vertical">
          <div className="grid-container">
            <div className="grid-content splash-title">
              <h2>Notifications</h2>
              <div className="notification-list">
                {notifications.map((item, i) => (
                  this.notificationList(item,i)
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(Notification);
