import {
    START_SESSION, EXPIRE_SESSION, UPDATE_SESSION, EMAIL_START, EMAIL_FAIL, EMAIL_SUCCESS
} from '../config/constants'

// Define the initial state for `sessions` module
const initialState = {
    calculator: 'replace',
    email: 'guest',
    id: 0,
    chooseCalculator: 0,
    laborRate: null,
    bearingSetCost: null,
    manualSpindleNutCost: null,
    wheelSealCost: null,
    partsMargin: null,
    replacementHubCostV: null,
    spindleNutCost: null,
    billableLaborRate: null,
    rebuildHours: null,
    replaceHours: null,
    laborMargin: null,
    lastStep: ''
};

export const calculators = (state = initialState, action) => {
    switch(action.type) {
        case START_SESSION: {
            // const len = state.friends.length ? state.friends.length : 1;
            // const newId = (state.friends[len - 1] + 1) || 0;
            return Object.assign({}, state, {email: state.email, id: action.Results, calculator: state.calculator, error: null})
        }
        case EXPIRE_SESSION: {
            return Object.assign({}, initialState);
        }
        case UPDATE_SESSION: {
            return Object.assign({}, state, action.state);
        }
        case EMAIL_START: {
            return Object.assign({}, state,
                {sendingEmail: action.sendingEmail, emailError: action.emailError});
        }
        case EMAIL_FAIL: {
            return Object.assign({}, state,
                {sendingEmail: action.sendingEmail, emailError: action.emailError});
        }
        case EMAIL_SUCCESS: {
            return Object.assign({}, state,
                {sendingEmail: action.sendingEmail, emailError: action.emailError});
        }
        default:
            return state
    }
}
