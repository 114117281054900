import React from 'react';
import { connect } from 'react-redux';
import Waiting from '../../components/global/waiting';
import { fetchRotorDetail } from '../../actions';
import NoResults from '../global/no-result';

class ReplacementRotorDetail extends React.Component {
  constructor(props) {
    super(props);
    this.getData = this.getData.bind(this);

    this.state = {
      rotorId: null,
    };
  }

  componentWillMount() {
    const {match} = this.props;
    if (match.params.id) {
      this.setState({ rotorId: match.params.id });
      this.getData(match.params.id);
    }
  }

  getData(id) {
    const {dispatch} = this.props;
    dispatch(fetchRotorDetail(id));
  }

  componentWillReceiveProps(newProps) {
    const {match} = newProps;
    if (match.params.id && this.state.rotorId !== match.params.id) {
      this.setState({ rotorId: match.params.id });
      this.getData(match.params.id);
    }
  }

  render() {
    const { results,history } = this.props;
    const rotorDetail = results.rotorDetail;

    if (results.isFetching) {
      return (<Waiting />)
    }

    if (rotorDetail.length === 0) {
      return (<NoResults />)
    }

    return (
      <div className="grid-container main-content">
        <h2>Product Details</h2>
        {
          rotorDetail.map((item) => {
            return (
                <div key={item.BrakeRotorNumber}>
                  <div className="mb-3">
                    {/* { <img className="product-image"  src={IMAGE_CDN+item.Images[0].ImageGuid+'.png'} /> } */}
                    {<img className="product-image" src={item.Images[0].ImageUrl + '.png'}/>}
                  </div>
                  <div className="specs mb-3">
                    <h2>Product Specs</h2>
                    <table>
                      <tbody>
                      <tr>
                        <td>Brake Rotor Number</td>
                        <td>{item.BrakeRotorNumber}</td>
                      </tr>
                      <tr>
                        <td>Rotor Type</td>
                        <td>{item.BrakeRotorType}</td>
                      </tr>
                      <tr>
                        <td><span className="badge">A</span>Overall Length</td>
                        <td>{item.OverallLength} inch</td>
                      </tr>
                      <tr>
                        <td><span className="badge">B</span>Brake Surface Outside Diameter</td>
                        <td>Ø{item.BrakeSurfaceOutsideDiameter} inch</td>
                      </tr>
                      <tr>
                        <td><span className="badge">C</span>Thickness</td>
                        <td> {item.Thickness} inch</td>
                      </tr>
                      <tr>
                        <td><span className="badge">D</span>Pilot Diameter</td>
                        <td>Ø {item.PilotDiameter} inch</td>
                      </tr>
                      <tr>
                        <td><span className="badge">E</span>Bolt Circle</td>
                        <td>Ø{item.BoltCircle} inch</td>
                      </tr>
                      <tr>
                        <td><span className="badge">F</span>Bolt Size</td>
                        <td>{item.BoltSize}</td>
                      </tr>
                      <tr>
                        <td>Number of Bolt Holes</td>
                        <td>{item.BoltHoleNumber}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="disclaimer text-center" style={{fontSize: '22px'}}>
                    Installation Instructions - Kit Number{item.Instruction.length > 1 ? 's' : ''}
                    {item.Instruction.map((a) => {
                      if(a.BrakeRotorKitNumber && history.location.pathname.includes(a.BrakeRotorKitNumber)){
                        return (
                            <span>&nbsp;<a style={{textDecoration: 'underline', color: '#ba0c2f'}} href={a.Instruction}
                                           target="_blank">{history.location.pathname.split('/')[5]}</a>&nbsp;</span>
                        );
                      }
                    })}
                  </div>
                  <a href="#" onClick={(e) => {
                    e.preventDefault();
                    history.goBack()
                  }} className="general-button">Return to results</a>
                </div>
            )
          })
        }
      </div>
    );
  }
}

const s = (state) => state
export default connect(s)(ReplacementRotorDetail)
