import React, { Component } from 'react';
import { connect } from 'react-redux';
import Spinner from '../../components/global/spinner';
import {fetchFilters} from '../../actions/filters';

let FILTERIDX;
const BRAKE_ROTOR_TYPE = '/hub/brake-rotor-type';

class Result extends Component {
  render() {
    var { result, active, onClick, app } = this.props;
    const { brkty, aaxna } = app.filterState;
    const skipGawrFilter = brkty === 1 && aaxna === 4;
    const isAluminum = result.Name.toLowerCase() === 'aluminum';

    return (
      <div className="grid-content small-6">
        <div className={active()}>
          <button className="yes-no-button" onClick={onClick}>
            <strong>{result.Name}</strong>
            {skipGawrFilter ? (
              <div>
                <div>
                  <strong>
                    {isAluminum ? 'Up to 23,000 lbs.' : 'Up to 26,000 lbs.'}
                  </strong>
                </div>
                <div>
                  <span>{isAluminum ? '(0-10,433 kg)' : '(0-11,793 kg)'}</span>
                </div>
              </div>
            ) : null}
          </button>
        </div>
      </div>
    );
  }
}

class Material extends Component {
  componentDidMount() {
    const { dispatch, app } = this.props;
    FILTERIDX =
      app.categories.findIndex((a) => a.QueryParameterName === 'hcmty') !==
      undefined
        ? app.categories.findIndex((a) => a.QueryParameterName === 'hcmty')
        : 14;
    dispatch(fetchFilters(FILTERIDX, app));
  }

  render() {
    const { app, setFilter, setActive } = this.props;
    const { tcomp, tmake } = app.filterState;
    const otherNotListedPath = tcomp === 1 && tmake === null;
    if (app.isFetching || app.filterResults.length < 1) {
      return <Spinner isFetching={app.isFetching} />;
    }
    return (
      <div className="grid-container main-content">
        <h2>Choose the Hub Material</h2>
        <div className="grid-block">
          {app.filterResults.map((result) => {
            if (otherNotListedPath) {
              var boundClick = setFilter.bind(
                this,
                FILTERIDX,
                { hcmty: result.Id },
                app
              );
            } else {
              var boundClick = setFilter.bind(
                this,
                FILTERIDX,
                { hcmty: result.Id },
                (app.NEXT_FILTER_PATH = BRAKE_ROTOR_TYPE)
              );
            }
            var boundActive = setActive.bind(this, FILTERIDX, result.Id);
            return (
              <Result
                key={result.Id}
                app={app}
                result={result}
                active={boundActive}
                onClick={boundClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Material);
