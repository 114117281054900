import React, { Component } from 'react';
import {connect} from 'react-redux';
import SelectButton from './SelectButton/index';
import Total from './total';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {actionCreators as sessionsActions} from '../replace/index';

function mapStateToProps(state) {
    return { session: state.calculators };
}

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators(sessionsActions, dispatch) };
}

class RebuildCosts extends Component {
    static propTypes = {
        actions: PropTypes.object.isRequired,
        session: PropTypes.object.isRequired
    };

    constructor(props, context) {
        super(props, context);
        this.handleChange = this.handleChange.bind(this);
        const { session: { chooseCalculator } } = this.props;
        this.state = {
            bearingSetCost: this.props.session.bearingSetCost || '',
            hubCost: chooseCalculator ? (this.props.session.hubCost || '') : 0,
            wheelSealCost: this.props.session.wheelSealCost || '',
            manualSpindleNutCost: this.props.session.manualSpindleNutCost || 0
        };
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {
        const STATE_KEY = 'rebuildCosts';
        const LOCATION = 'rebuild-costs';
        const {actions: {updateSession}, session: {id, calculator, chooseCalculator }} = this.props;
        return (
            <div className="grid-container main-content">
                <div className="calculator">
                    <h1>{chooseCalculator ? 'Conventional Hub Costs' : 'Rebuild Costs'}</h1>
                    <div className={!chooseCalculator ? 'hide' : ''}>
                        <h2>Hub Cost(includes hub, cups, and studs)</h2>
                        <input
                            pattern="\d\.*"
                            type="number"
                            name="hubCost"
                            ref="hubCost"
                            key="hubCost"
                            placeholder="0.00"
                            required value={this.state.hubCost}
                            onChange={this.handleChange}
                        />
                    </div>
                    <h2>Wheel Seal Cost</h2>
                    <input
                        pattern="\d\.*"
                        type="number"
                        name="wheelSealCost"
                        ref="wheelSealCost"
                        key="wheelSealCost"
                        placeholder="0.00"
                        required value={this.state.wheelSealCost}
                        onChange={this.handleChange}
                    />
                    <h2>Bearing {chooseCalculator ? 'Cone' : 'Set'} Cost</h2>
                    <input
                        pattern="\d\.*"
                        type="number"
                        name="bearingSetCost"
                        ref="bearingSetCost"
                        placeholder="0.00"
                        key="bearingSetCost"
                        required value={this.state.bearingSetCost}
                        onChange={this.handleChange}
                    />
                    {chooseCalculator ?
                        <div>
                            <h2>Manual adjust spindle nut cost</h2>
                            <input
                                pattern="\d\.*"
                                type="number"
                                name="manualSpindleNutCost"
                                ref="manualSpindleNutCost"
                                placeholder="0.00"
                                key="bearingSetCost"
                                required value={this.state.manualSpindleNutCost}
                                onChange={this.handleChange}
                            />
                        </div>

                        : ''}

                    <h2>Total Cost</h2>
                    <Total sum={[this.state.bearingSetCost, this.state.wheelSealCost, this.state.hubCost, this.state.manualSpindleNutCost]} multiplier={1}/>
                    <SelectButton
                        handleClick={updateSession}
                        label="Next"
                        stateKey={STATE_KEY}
                        location={LOCATION}
                        calculator={calculator}
                        sessionId={id}
                        stateVals={this.state}
                    />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RebuildCosts);

