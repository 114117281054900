import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
  fetchCastingFilterValues,
  resetCastingFilter,
  fetchCastingFilterCategories,
} from '../../actions';
import Waiting from '../../components/global/waiting';

import CastingResult from './castingResult';

class CastingFilter extends Component {
  constructor(props) {
    super(props);
    this.getFilterValue = this.getFilterValue.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);

    this.state = {
      filters: [],
      urlParams: '',
      isResult: false,
      filterClicked: false,
    };
  }

  componentWillMount() {
    const { dispatch, results } = this.props;
    const { castingFilters } = results;
    if (!castingFilters.length) {
      dispatch(fetchCastingFilterCategories());
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetCastingFilter());
  }

  componentDidMount() {
    this.getFilterValue();
  }

  componentDidUpdate() {
    this.getFilterValue();
  }

  isNil(value) {
    return value !== null && value !== undefined;
  }

  getValue(item) {
    if (this.isNil(item.Id)) {
      return item.Id;
    }
    else if (this.isNil(item.AftermarketAxleId)) {
      return item.AftermarketAxleId;
    } else if (this.isNil(item.Inch)) {
      return item.Inch;
    } else if (this.isNil(item.Inches)) {
      return item.Inches;
    }
    if (item !== undefined) {
      return item;
    }
  }



  handleFilterClick(item) {
    const id = this.getValue(item);
    const { dispatch, results } = this.props;
    const { currentFilter } = results;
    const { filters, urlParams, skipFilter } = this.state;
    const index =
      currentFilter === 'aaxna' ? 1 : filters.indexOf(currentFilter);
    this.state.filterClicked = true;
    this.state.urlParams +=
      urlParams === ''
        ? currentFilter + '=' + id
        : '&' + currentFilter + '=' + id;

    let url = this.state.urlParams;


    var parturl = (filters.length === index + 1 ? filters[0] : filters[index + 1]);
    dispatch(
      push(
        '/hub/casting/filter/' + parturl
        +
        '/' +
        url
      )
    );
  }

  getFilterValue() {
    const { results, match, dispatch } = this.props;
    const { castingFilters, castingFilterValue } = results;
    const isResult =
      castingFilterValue &&
      castingFilterValue[0] &&
      castingFilterValue[0].HubAssemblyNumber;

    const { filters } = this.state;

    this.state.urlParams = match.params.filters ? match.params.filters : '';

    if (castingFilters.length) {
      this.state.filters = castingFilters.map(
        (item) => item.QueryParameterName
      );
    }

    let filterParams = match.params.filters
      ? '?inclv=2&' + match.params.filters
      : '?inclv=2';
    this.state.url = filterParams;

    if (match.params.currentFilter !== this.state.currentFilter) {      
      dispatch(fetchCastingFilterValues(match.params.currentFilter, filterParams));
      this.state.currentFilter = match.params.currentFilter;
    }
    else if (
      castingFilters.length &&
      castingFilterValue &&
      castingFilterValue.length === 2
      && results.currentFilter ==='brdia'
      && !isResult  
      &&
      results.isFilterValueSingle
    )
    {
      let index = filters.indexOf(results.currentFilter);
      const id =
        castingFilterValue[0].inch === 0
          ? castingFilterValue[1].inch
          : castingFilterValue[0];

      this.state.url += '&' + filters[index] + '=' + this.getValue(id);
      dispatch(
        fetchCastingFilterValues(filters[index + 1], this.state.url)
      );
    }
    else if (
      castingFilters.length &&
      castingFilterValue &&
      castingFilterValue.length === 2
      && results.currentFilter ==='ovlgt'
      && !isResult  
      &&
      results.isFilterValueSingle
    )
    {
      let index = filters.indexOf(results.currentFilter);
      const id =
        castingFilterValue[0].inch === 0
          ? castingFilterValue[1].inch
          : castingFilterValue[0];

      this.state.url += '&' + filters[index] + '=' + this.getValue(id);
      dispatch(
        fetchCastingFilterValues(filters[index + 1], this.state.url)
      );
    }

    else if (
      castingFilters.length &&
      castingFilterValue &&
      castingFilterValue.length === 1
      && !isResult
      &&
      results.isFilterValueSingle
    ) {

      let index = filters.indexOf(results.currentFilter);

      const id =
        castingFilterValue[0].Id !== undefined
          ? castingFilterValue[0].Id
          : castingFilterValue[0];

      if (this.state.url === '') {
        this.state.url = filterParams;
      }
      else {
        this.state.url += '&' + filters[index] + '=' + this.getValue(id);

        dispatch(
          fetchCastingFilterValues(filters[index + 1], this.state.url)
        );

      }
    }
  }


  render() {
    const { results } = this.props;
    const { castingFilterValue, currentFilter } = results;


    if (results.isFetching) {
      return <Waiting />;
    }


    if (currentFilter === 'abcoi'|| currentFilter === 'rtoff'
     || currentFilter === 'brdia' || currentFilter === 'hcbdi'
      || currentFilter === 'hofsi' && castingFilterValue !== undefined) {
      let message = '';
      let src = '';

      if (currentFilter === 'brdia') {
        message = 'Choose the Brake Surface Outside Diameter';
        src = require('../../images/brake_rotor_lange.png');
      } else if (currentFilter === 'abcoi') {
        message = 'Choose the ABS Offset:';
        src = require('../../images/abs_offset_2.png');
      }
      else if (currentFilter === 'hcbdi') {
        message = 'Choose the Hub Cap Bolt Circle Diameter:';
        src = require('../../images/brake_rotor_lange.png');
      } else if (currentFilter === 'hofsi') {
        message = 'Hub Off set:';
        src = require('../../images/hub_offset.png');
      }else if (currentFilter === 'rtoff') {
        message = 'Rotor Offset Inches';
        src = require('../../images/rotor_offset.png');
      }

      return (
        <div className="grid-container main-content replacement-drum">
          <h2>{message}</h2>
          <img
            className="brake-rotor-flange"
            src={src}
            alt="ConMet"
            width="300"
            height="300"
          />
          <div className="grid-content">
            {castingFilterValue.map((item) => {
              if(item.Inch !== 0){
              let value =              
                currentFilter === 'abcoi'||'rtoff' || 'brdia' || 'hcbdi' || 'hofsi'
                  ? '⌀' + item.Inch + ' inch' + ' [' + item.Millimeter + ' mm]'
                  : '⌀' + item.Inch + ' inch';

              return (
                <div className="small-12" key={currentFilter + item.Inch}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item)}
                    >
                      {value}
                    </button>
                  </div>
                </div>
              );
              }
            })}          
          </div>
        </div>
      );
    }
    if (currentFilter === 'wssdi'  || currentFilter === 'hubrt' ||
      currentFilter === 'dpidi' && castingFilterValue !== undefined) {
      let message = '';

      if (currentFilter === 'wssdi') {
        message = 'Wheel Stud Standout:';
      }
      else if (currentFilter === 'dpidi') {
        message = 'Drum Pilot Diameter Inches';
      }
      else if (currentFilter === 'hubrt') {
        message = 'Choose the Hub Rating Pounds';
      }

      return (
        <div className="grid-container main-content replacement-drum">
          <h2>{message}</h2>
          <div className="grid-content">
            {castingFilterValue.map((item) => {
              const value =
                currentFilter === 'wssdi' || 'dpidi'  || 'hubrt'
                  ? '⌀' + item.Inch + ' inch' + ' [' + item.Millimeter + ' mm]'
                  : '⌀' + item.Inch + ' inch';
              return (
                <div className="small-12" key={currentFilter + item.Inch}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item)}
                    >
                      {value}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (currentFilter === 'ovlgt' && castingFilterValue !== undefined) {
      let message = '';
      let src = '';

      if (currentFilter === 'ovlgt') {
        message = 'Choose the Overall Length';
        src = require('../../images/brake_rotor_lange.png');
      }

      return (
        <div className="grid-container main-content replacement-drum">
          <h2>{message}</h2>
          <img
            className="brake-rotor-flange"
            src={src}
            alt="ConMet"
            width="300"
            height="300"
          />
          <div className="grid-content">
            {castingFilterValue.map((item) => {
               if(item.Inch !== 0){
              const value =
                currentFilter === 'ovlgt'
                  ? '⌀' + item.Inch + ' inch' + ' [' + item.Millimeter + ' mm]'
                  : '⌀' + item.Inch + ' inch';
              return (
                <div className="small-12" key={currentFilter + item.Inch}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item)}
                    >
                      {value}
                    </button>
                  </div>
                </div>
              );
            }})}
          </div>
        </div>
      );
    }

    if (currentFilter === 'aaxna' && castingFilterValue !== undefined) {
      return (
        <div className="grid-container main-content replacement-drum">
          <h2>Choose the Hub by Axle Type or Bearing Number:</h2>
          <div className="grid-content">
            {castingFilterValue.map((item) => {
              return (
                <div className="small-12" key={item.AftermarketAxleId}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item.AftermarketAxleId)}
                    >
                      {item.AftermarketAxle}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (currentFilter === 'barlg' && castingFilterValue !== undefined) {
      let src = require('../../images/barrel_length.png');
      return (
        <div className="grid-container main-content replacement-drum">
          <h2>Choose the barrel length:</h2>
          <img
            className="brake-rotor-flange"
            src={src}
            alt="ConMet"
            width="300"
            height="300"
          />
          <div className="grid-content">
            {castingFilterValue.map((item) => {
              return (
                <div className="small-12" key={item.Id}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item.Id)}
                    >
                      {item.Name}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (currentFilter === 'axthd' || currentFilter === 'hamnt' ||
      currentFilter === 'hubps' || currentFilter === 'stdps' && castingFilterValue !== undefined) {

      let message = '';

      if (currentFilter === 'axthd') {
        message = 'Choose the Axle Stud Thread';
      } else if (currentFilter === 'whlps') {
        message = 'Choose the brake diameter';
      }
      else if (currentFilter === 'hamnt') {
        message = 'Hub Mounting System';
      }
      else if (currentFilter === 'hubps') {
        message = 'Choose the hub piloted type';
      }
      else if (currentFilter === 'stdps') {
        message = 'Choose the stud piloted type';
      }

      return (
        <div className="grid-container main-content replacement-drum">
          <h2>{message}</h2>
          <div className="grid-content">
            {castingFilterValue.map((item) => {
              return (
                <div className="small-12" key={item.Id}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item.Id)}
                    >
                      {item.Name}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (currentFilter === 'icfsb') {

     var src = require('../../images/mounting_flange_diameter.png');

      return (
        <div className="grid-container main-content replacement-drum">
          <h2>Wheel Flange Diameter</h2>
          <img
            className="brake-rotor-flange"
            src={src}
            alt="ConMet"
            width="300"
            height="300"
          />
          <div className="grid-content">
            {castingFilterValue.map((item) => {
              var value = item === true ? '12.75"-12.9" (For small diameter brake drums and wheels)' : '13.00" + (Most Common)';
              return (
                <div className="small-12" key={item}>
                  <div className="conmet-button">
                    <button
                      className="yes-no-button bold"
                      onClick={() => this.handleFilterClick(item)}
                    >
                      {value}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (currentFilter === 'hanum') {
      return <CastingResult CastingResult={castingFilterValue} results = {results} />;
    }
 
    return <div />;
  }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(CastingFilter);
