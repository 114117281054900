import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

class HubAssemblyType extends Component {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(push('/hub/spindle-nut'));
    }

    render() {
        return <></>
    }
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(HubAssemblyType);
