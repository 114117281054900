import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default class Total extends Component {
    static propTypes = {
        multiplier: PropTypes.number,
        sum: PropTypes.array.isRequired
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            multiplier: this.props.multiplier || 1
        };
    }

    render() {
        const {sum, multiplier} = this.props;
        var total: float = sum.reduce(function(prevVal, val) {
            return prevVal + _.toNumber(val);
        }, 0);
        total = total * multiplier;
        return (
            <h2><strong>${total.toFixed(2)}</strong></h2>
        );
    }
}
